import { getPositionOnRadius } from './util';

const SPHERE_RAIDUS = 90;

export function getOuterNodesTotal(node) {
  let total = 0;
  for (let child of node.children) {
    total = total + child.children.length;
  }
  return total;
}

export function plotLeveLTwo(node) {
  const { branch } = node;
  const { x, y, angle } = node.parent.position;

  switch (branch) {
    case 0: {
      node.position = {
        ...getPositionOnRadius(angle - 20, SPHERE_RAIDUS, x, y)
      };

      node.textPosition = {
        x: node.position.x - 10 - (node.name || '').length * 8,
        y: node.position.y + 14
      };

      break;
    }

    case 1: {
      node.position = {
        ...getPositionOnRadius(angle - 10, SPHERE_RAIDUS + 30, x, y)
      };

      node.textPosition = {
        x: node.position.x + 10,
        y: node.position.y - 14
      };
      break;
    }

    case 2: {
      node.position = {
        ...getPositionOnRadius(angle + 10, SPHERE_RAIDUS + 20, x, y)
      };
      node.textPosition = {
        x: node.position.x - 10 - (node.name || '').length * 8,
        y: node.position.y + 14
      };
      break;
    }
  }

  return node;
}

export default plotLeveLTwo;
