import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadPhoto from '../../components/inputs/upload.photo';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import { ADD_BRAND, LOAD_BRAND, REMOVE_BRAND, UPDATE_BRAND } from '../../utils/gql';
import { checkPermissions, SCOPES } from '../../utils/permissions';

export function AdminAddBrand(props) {
    const [global, setGlobal] = useGlobal();
    const mutation = useMutation(!props.brand ? ADD_BRAND : UPDATE_BRAND);
    const removeBrand = useMutation(REMOVE_BRAND);

  const [name, setName] = React.useState(props.brand ? props.brand.name : null);
  const [logo, setLogo] = React.useState(props.brand ? props.brand.logo : null);

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          input: {
              name,
              logo
          }
        }
      })
      if (response.data.addBrand) {
        notify(setGlobal, 'Added Brand', 'success');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function update() {
    try {
      const response: any = await mutation({
        variables: {
          input: {
              id: props.brand.id,
              name,
              logo
          }
        }
      })
      if (response.data.updateBrand) {
        notify(setGlobal, 'Saved Brand', 'success');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function remove() {
    try {
      const response: any = await removeBrand({
        variables: {
            id: props.brand.id
        }
      })
      if (response.data.removeBrand) {
        notify(setGlobal, 'Removed Brand', 'success');
      }
    } catch (e) {
      console.log(e);
    }
  }

  const buttons = !props.brand
    ? [
        { label: 'Add Brand', onClick: save }
      ]
    : !checkPermissions([SCOPES.canCRUD], props.brand.id)
      ? [
          { label: 'Save Brand', onClick: update }
        ]
      : [
          { label: 'Delete Brand', type: 'delete', onClick: remove },
          { label: 'Save Brand', onClick: update },
        ]

  return (
    <AdminScreen
    headline={`${props.brand ? "Edit" : "Add"} Brand`}
      buttons={buttons}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        label="Brand Name"
                        placeholder="Brand Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Padding>
                    <Padding all={2}>
                      <UploadPhoto
                        label="Brand Logo"
                        placeholder="Brand Name"
                        value={logo}
                        onChange={setLogo}
                      />
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function AdminAddBrandLoader(props) {
  return <AdminAddBrand history={props.history} />;
}

export function AdminEditBrandLoader(props) {
    const { data, loading, error } = useQuery(LOAD_BRAND, {
      variables: {
        id: props.match.params.brand
      }
    })
  
    if (loading || !data) return null;
    if (error) return null;
  
    return <AdminAddBrand history={props.history} brand={data.brand} />;
  }

export default AdminAddBrandLoader;
