export const NODE_SEPERATION_MIN = [0, 0, 60, 40, 20, 0, 0];
export const NODE_RADIUS = [30, 10, 8, 4, 4];
export const RADIUS = [360, 50, 100, 50, 50];
export const SPHERES = [100, 70, 100, 20, 40, 10];

export function calculateNodeMinSpace(child, depth) {
  let total = 0;
  const children = child.children || [];
  const minSeperation = NODE_SEPERATION_MIN[depth];

  for (child of children) {
    total = total + calculateNodeMinSpace(child, depth + 1);
  }

  return total + minSeperation * children.length;
}

export function toPercent(num) {
  return Math.round(num * 100);
}

export function prepareNodeData(root, depth = 0) {
  let count = 0;
  const children = root.children || [];
  const total = (root.children || []).reduce((t, child) => {
    return t + calculateNodeMinSpace(child, depth + 1) || 30;
  }, 0);

  for (let child of children) {
    child.branch =
      root.branch !== null && root.branch !== undefined ? root.branch : count;
    child.radius = NODE_RADIUS[depth];
    child.sphere = SPHERES[depth];
    child.index = count;
    child.depth = depth;
    child.parent = root;
    child.elements = [];
    child.nodeMinSpace = calculateNodeMinSpace(child, depth + 1) || 30;
    child.value = calculateNodeMinSpace(child, depth + 1);
    child.nodePercentSpace = child.nodeMinSpace / total;
    child.ancestor = children[count - 1] || null;
    child.neighbor = children[count + 1] || null;
    if (child.children) prepareNodeData(child, depth + 1);
    count++;
  }

  return root;
}

export default prepareNodeData;
