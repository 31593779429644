import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { AlignCenter } from '../../components/containers/align';
import { OrderedList, OrderedListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';
import { getBrands } from '../../data';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import {
  ButtonHollow,
  DownloadButton,
  DownloadButtonContainer,
} from '../../components/buttons';
import SVGInline from 'react-svg-inline';
import TextInput from '../../components/inputs/text';
import * as store from 'store';
import { useQuery, useMutation } from 'react-apollo-hooks';
import loader from '../../components/loader';
import notify from '../../utils/notify';
import { Brand } from '../brand/choose';
import { LOAD_ME, LOAD_ME_BRAND, UPDATE_ME } from '../../utils/gql';

export const SegmentTitle = styled(HeadlineThree)``;
export const SegmentText = styled(Paragraph)``;

export const StoryUniversePlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background: #f3f3f3;
`;

interface DashboardProps {
  brands: any;
  history?: any;
  me: any;
  brand?: any;
}

export function MyAccount(props: DashboardProps) {
  const logout = () => {
    store.remove('firstName');
    store.remove('lastName');
    store.remove('token');
    store.remove('type');
    store.remove('roles');
    props.history.push('/login');
  };

  const [email, setEmail] = React.useState(props.me.email);
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [global, setGlobal] = useGlobal();
  const mutate = useMutation(UPDATE_ME);

  async function updateEmail() {
    try {
      await mutate({ variables: { input: { email } } });
      notify(setGlobal, 'Updated Email', 'success');
    } catch (e) {
      notify(setGlobal, 'Could not update email.', 'error');
      return;
    }
  }

  async function updatePassword() {
    if (
      !new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
      ).test(password)
    ) {
      notify(
        setGlobal,
        'Password must be at least 8 characters, one lowercase letter, one uppercase letter, one number and one special character',
        'error'
      );
      return;
    }

    if (password !== confirmPassword) {
      notify(setGlobal, 'Passwords must match', 'error');
      return;
    }

    try {
      await mutate({ variables: { input: { password } } });
      notify(setGlobal, 'Updated Password', 'success');
    } catch (e) {
      notify(setGlobal, 'Could not update password', 'error');
      return;
    }
  }

  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ xs: 1, sm: 1, md: 6 / 12 }}>
          <Padding all={2}>
            <HeadlineOne>Your Account</HeadlineOne>
          </Padding>
        </Grid.Unit>
        <Grid.Unit size={{ xs: 1, sm: 1, md: 6 / 12 }}>
          <Padding all={2} style={{ height: '100%' }}>
            <DownloadButtonContainer>
              <DownloadButton style={{ marginRight: '16px' }}>
                <Link to="/">CHANGE BRAND</Link>
              </DownloadButton>
              <DownloadButton onClick={logout}>LOGOUT</DownloadButton>
            </DownloadButtonContainer>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={3}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>Update Email</HeadlineTwo>
                      <Divider />
                      <TextInput
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        label="Email"
                        type="email"
                      />
                      <ButtonHollow onClick={updateEmail}>SUBMIT</ButtonHollow>

                      <HeadlineTwo style={{ marginTop: '48px' }}>
                        Update Password
                      </HeadlineTwo>
                      <Divider />
                      <TextInput
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        label="Password"
                        type="password"
                      />
                      <TextInput
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        label="Confirm Password"
                        type="password"
                      />
                      <ButtonHollow onClick={updatePassword}>
                        SUBMIT
                      </ButtonHollow>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export function MyAccountLoader(props: any) {
  const { data, loading, error } = useQuery(
    props.match.params.brand ? LOAD_ME_BRAND : LOAD_ME,
    {
      variables: { id: props.match.params.brand },
      fetchPolicy: 'network-only',
    }
  );

  if (!data) return loader;
  if (loading) return loader;

  return (
    <MyAccount
      {...props}
      me={data.me}
      brand={props.match.params.brand}
    />
  );
}

export default MyAccountLoader;
