import * as React from 'react';
import { Link } from 'react-router-dom';
import AdminScreen from '../../components/admin/containers/screen';
import { useQuery, useMutation } from 'react-apollo-hooks';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import styled from 'styled-components';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import * as store from 'store';
import { ADD_BRAND_USER, LOAD_BRAND_USER, LOAD_ROLES, REMOVE_BRAND_USER, UPDATE_BRAND_USER } from '../../utils/gql';
import { checkPermissions, SCOPES } from '../../utils/permissions';

export const StyledInput = styled.select`
  min-width: 150px;
  padding: 12px 8px;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d7d7d8;
  font-family: proxima-nova, sans-serif;
  text-align: right;
  margin-bottom: 10px;

  :focus {
    border: 1px solid #509bb0;
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 6px 0;
  display: block;
`;

export function AdminAddBrandUser(props) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(props.user ? UPDATE_BRAND_USER : ADD_BRAND_USER);
  const removeUser = useMutation(REMOVE_BRAND_USER);

  const [email, setEmail] = React.useState(props.user ? props.user.email : "");
  const [role, setRole] = React.useState(props.user ? props.user.role : "");

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          brand: props.brand,
          email: email,
          role: role
        }
      })
      if (response.data.addBrandUser) {
        notify(setGlobal, 'Added User', 'success');
      }
    } catch (e) {
      notify(setGlobal, e.graphQLErrors[0].message, 'error');
    }
  }

  async function update() {
    try {
      const response: any = await mutation({
        variables: {
          brand: props.brand,
          user: props.user.id,
          role: role
        }
      })
      if (response.data.updateBrandUser) {
        notify(setGlobal, 'Updated User', 'success');
      }
    } catch (e) {
      notify(setGlobal, e.graphQLErrors[0].message, 'error');
    }
  }

  async function remove() {
    try {
      const response: any = await removeUser({
        variables: {
            brand: props.brand,
            user: props.user.id
        }
      })
      if (response.data.removeBrandUser) {
        notify(setGlobal, 'Removed User', 'success');
      }
    } catch (e) {
      console.log(e);
    }
  }

    const buttons = !props.user
        ? [
            { label: 'Add User', onClick: save }
          ]
        : (props.user.role !== "Owner" || (props.user.role === "Owner" && checkPermissions([SCOPES.canCRUD], props.brand)))
          ? [
              { label: 'Delete User', type: 'delete', onClick: remove },
              { label: 'Save User', onClick: update },
            ]
          : []

  return (
    <AdminScreen
      headline={`${props.user ? "Edit" : "Add"} User`}
      buttons={buttons}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                        <StyledLabel>Email</StyledLabel>
                        {props.user && props.user.email}
                        {!props.user && (
                            <TextInput
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        )}
                    </Padding>
                    <Padding all={2}>
                        <StyledLabel>Role</StyledLabel>
                        <StyledInput 
                            name="role"
                            value={role}
                            onChange={e => setRole(e.target.value)}>
                            <option value="">Select a role</option>
                            {props.roles.map(e => (
                                <option key={e} value={e}>{e}</option>
                            ))}
                        </StyledInput>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function AdminAddBrandUserLoader(props) {
    const { data, loading, error } = useQuery(LOAD_ROLES)
  
    if (loading || !data) return null;
    if (error) return null;
  
    return <AdminAddBrandUser history={props.history} roles={data.roles} brand={props.match.params.brand} />;
  }

export function AdminEditBrandUserLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND_USER, {
    variables: {
      id: props.match.params.brand,
      user: props.match.params.user
    }
  })

  if (loading || !data) return null;
  if (error) return null;

  return <AdminAddBrandUser history={props.history} roles={data.roles} brand={props.match.params.brand} user={data.brand.user} />;
}

export default AdminAddBrandUserLoader;