import React from 'react';

import Grid from 'styled-components-grid';
import { Padding } from "styled-components-spacing";

import { Container } from "../../components/containers/container";
import SideBar from '../../components/navigation/sidebar';
import { Box } from '../../components/containers/box';
import { Divider } from '../../components/decoration/divider';
import { HeadlineOne, HeadlineTwo, Paragraph } from "../../components/typography";

function GlossaryOfTerms(props: any) {
    return (
        <Container>
            <SideBar active="bem" brand={props.match.params.brand} />
            <Grid>
                <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                        <HeadlineOne>GLOSSARY OF TERMS</HeadlineOne>
                    </Padding>
                </Grid.Unit>
            </Grid>
            <Grid>
                <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                        <Box>
                            <Padding all={3}>
                                <HeadlineTwo>TERMS</HeadlineTwo>
                                <Divider />
                                <Paragraph>Brand Ecosystem Model</Paragraph>
                                <Paragraph>Blink, Test, Bond, Love</Paragraph>
                                <Paragraph>Progression of Resonance</Paragraph>
                                <Paragraph>Customer Activation Cycle</Paragraph>
                                <Paragraph>Casuals, Loyalists, Prospects, Cheerleaders</Paragraph>
                                <Paragraph>Five Bonds of Loyalty</Paragraph>
                                <Paragraph>Story Universe</Paragraph>
                                <Paragraph>Lost Souls</Paragraph>
                                <Paragraph>Rate of Migration</Paragraph>
                            </Padding>
                            <Padding all={3}>
                                <HeadlineTwo>UNDERSTANDING</HeadlineTwo>
                                <Divider />
                                <Paragraph>Understanding how resonance, the connection between us, happens is the foundation to understanding how to engender a following. It is the key to how relationships form: a relationship between two people, a company and a customer, or an organization and its constituents. Every human relationship happens in simple steps. A first impression leads to validation of that first impression, which leads to deeper understanding and an alignment of beliefs: <b>Blink, Test, Bond, Love</b>. We move from a superficial introduction to a deep state of resonance. This is how loyalty works. It’s a <b>Progression of Resonance</b>.</Paragraph>
                                <Paragraph>Figure 1. The Progression of Resonance</Paragraph>
                                <Padding all={3}>
                                    <div style={{textAlign:'center'}}>
                                        <img style={{display:'block',maxWidth:'500px',margin:'auto'}} src="https://cn-staging-compassandnail-origin.s3.amazonaws.com/static/Progression+of+Resonance.png" />
                                    </div>
                                </Padding>
                                <Paragraph>Architecting loyalty is possible in any circumstance. It requires understanding how resonance happens; understanding both the provider of a service and the user of that service. Alignment of needs becomes alignment of beliefs, a meeting of the minds, the provider not only meeting expectations but also communicating why it meets those expectations in the particular ways that it does. “Different strokes for different folks” was never more relevant than in the case of a brand providing a service or goods to a customer. Just like the resonance in relationships between people, customers move along a progression from first impression to deep understanding. Along that path the customer transcends mere loyalty and can become an advocate for the brand, its beliefs, and its services. It’s how customers say I love you, through their advocacy, and it ultimately leads to greater sales velocity and sustainable growth. The model that allows us to understand, enact, and measure a brand’s ability to create advocacy is the <b>Customer Activation Cycle</b>.</Paragraph>
                                <Paragraph>Figure 2. The Customer Activation Cycle</Paragraph>
                                <Padding all={3}>
                                    <div style={{textAlign:'center'}}>
                                        <img style={{display:'block',maxWidth:'500px',margin:'auto'}} src="https://cn-staging-compassandnail-origin.s3.amazonaws.com/static/CAC.png" />
                                    </div>
                                </Padding>
                                <Paragraph>Understanding why resonance (advocacy, love) happens and how it happens is only part of the process of architecting a following. Deep resonance happens vis-à-vis milestone experiences and stories; stories and experiences that exist as expressions of the unique reason why the organization exists, its raison d’etre. Being certain that at any moment, in any instance, the core reason for being is manifest in every single communication and experience is the only way to guarantee that deep understanding will ultimately happen. If it doesn’t happen, the relationship will falter and one party will fall away from the other. If the core value held by the organization is present in every seemingly trivial interaction with the user, if a conviction of belief is present in every nuance and moment, then the provider and user are destined to agree or disagree. Agreement means resonance. Mapping the stories and milestone experiences that lead to advocacy is the means by which any provider of a service or product can guarantee that its reason for being is manifest in every interaction with the end user. This is the <b>Story Universe</b>.</Paragraph>
                                <Paragraph>Together, these three models, or tools, form the <b>Brand Ecosystem Model</b>, and are the means to architecting meaningful relationships between providers and users. These are functional tools for migrating a potential user of any product or service to a point of advocacy. The <b>Brand Ecosystem Model</b> holistically describes how indifference morphs into enthusiasm, how apathy turns into action, how causes rally support, and how brands build rabid, long-term loyalty. The revolutionary perspective that separates this thinking from all other brand relationship management theory is the <b>Activation Cycle</b> seen as a fractal.</Paragraph>
                                <Paragraph>When considering this we can describe customers constantly being in the process of becoming a long-term sustainable customer. The customer may not be activating to the next stage, they could be moving back to a more nascent stage, which is fine (destined to remain <b>Casuals</b>, <b>Loyalists</b>, or even <b>Prospects</b> because they don’t align with the first impression or any of the values within the progression). To migrate to the next stage they simply have to reenter the process of <b>Migration</b>. If they continue to progress, that’s accounted for in the quantitative metrics of <b>Migration</b>. If they don’t, it provides the opportunity to understand why, what is missing in the experiences or communications that dissatisfy that particular individual. If there is a remedy and it can be enacted, then that customer is afforded the opportunity to reenter the fray and continue along the <b>Migration</b>. This is not a case of chasing customers that fundamentally disagree, these customers are truly destined to become <b>Lost Souls</b> and not demonstrate any loyalty now or in the future; rather, viewing the <b>Customer Activation Cycle</b> as a fractal is a means by which the organization can understand fully it’s ability to provide an aligned user experience for customers that do agree with the brand’s point of view every step along the path to advocacy.</Paragraph>
                                <Paragraph>As the value provided by a brand becomes aligned with the core needs of the customer, the greater the importance that brand plays in the individual’s life. Purpose moves from utilitarian to transcendent, and customers become advocates—because it’s good for them. Brands provide a value beyond just product quality and experience. That value might be as simple as convenience or as lofty as an ethical belief.</Paragraph>
                                <Paragraph>In the end, it is ethical purpose where true loyalty resides. Customers believe their association with ethical companies is something that makes their world a little bit better, and in so doing creates satisfaction and motivates them to take action. It turns out we customers want to be part of something bigger than ourselves. To help us achieve something that we can’t necessarily achieve on our own. When we find a company that believes in what we believe, we form a near-unbreakable bond. The <b>Five Bonds of Loyalty</b> helps visualize the intensity of bonds based on this spectrum from utility to belief.</Paragraph>
                                <Paragraph>Figure 3. The Five Bonds of Loyalty</Paragraph>
                                <Padding all={3}>
                                    <div>
                                        <img style={{display:'block',maxWidth:'500px',margin:'auto'}} src="https://cn-staging-compassandnail-origin.s3.amazonaws.com/static/FB.png" />
                                    </div>
                                </Padding>
                                <Paragraph>Taking this macro view of loyalty demonstrates how important it is for providers of goods and services to understand the <b>Progression of Resonance</b> and to craft their own <b>Brand Ecosystem Model</b> as a means to assure sustainable, long-term relationships with their constituency.</Paragraph>
                            </Padding>
                        </Box>
                    </Padding>
                </Grid.Unit>
            </Grid>
        </Container>
    )
}

export default GlossaryOfTerms;