import Dashboard from './dashboard';
import React from 'react';
import { useGlobal } from 'reactn';
import { checkPermissions, SCOPES } from '../../utils/permissions';
import ExecutiveSummary from './ExecutiveSummary';

export function Home(props) {
  const [brand, setBrand]: [any, any] = useGlobal('brand');

  if (!brand) {
    return <div></div>;
  }

  if (brand.lastDataRecordDate && checkPermissions([SCOPES.canViewExec], brand.id)) {
    return <ExecutiveSummary {...props} />;
  }

  return <Dashboard {...props} />;
}

export default Home;
