import React from 'react';
import styled from 'styled-components';
import { useGlobal } from 'reactn';
import { ISegmentData } from '../../../metrics/enum';
import { getColorForScore } from '../../../metrics/scoring';

const ringWidth = '84px';

const SegmentHealthBarContainer = styled.div`
  padding: 0px 8px;
  margin-bottom: -8px;
  position: relative;

  .bar {
    width: 100%;
    height: 10px;
    background: black;
  }

  .ring {
    border-radius: 9999%;
    width: ${ringWidth};
    height: ${ringWidth};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 40px solid ${(props: any) => props.scoreColor || '#38627c'};

    span {
      font-size: 20px;
      font-weight: bold;
      color: ${(props: any) => props.scoreColor || '#38627c'};
    }
  }

  .ring-container {
    position: absolute;
    width: ${ringWidth};
    height: ${ringWidth};
    top: calc((${ringWidth} / 2) * -1);
    left: calc(50% - ${ringWidth});
    border: 2px solid #fff;
    border-radius: 9999%;
  }

  .ring-loyalists {
    width: calc(${ringWidth} - 32px);
    height: calc(${ringWidth} - 32px);
    border: 16px solid ${(props: any) => props.scoreColor || '#38627c'};
    background: #fff;
  }

  .ring-casuals {
    width: calc(${ringWidth} - 16px);
    height: calc(${ringWidth} - 16px);
    border: 8px solid ${(props: any) => props.scoreColor || '#38627c'};
    background: #fff;
  }

  .ring-prospects {
    width: calc(${ringWidth} - 8px);
    height: calc(${ringWidth} - 8px);
    border: 4px solid ${(props: any) => props.scoreColor || '#38627c'};
    background: #fff;
  }

  .ring-lostsouls {
    border: none;
    width: ${ringWidth};
    height: ${ringWidth};
    background: black;

    span {
      color: #fff;
    }
  }

  .ring-cheerleaders {
    width: ${ringWidth};
    height: ${ringWidth};
    background: ${(props: any) => props.scoreColor || '#38627c'};
    border: none;

    span {
      color: #fff;
    }
  }
` as any;

export function SegmentHealthBar({ segment }) {
  const [quantDataLoading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (quantDataLoading) {
    return (
      <SegmentHealthBarContainer>
        <div className="bar"></div>
      </SegmentHealthBarContainer>
    );
  }

  const data: ISegmentData = quantData[segment.type];

  if (!data) {
    return null;
  }

  return (
    <SegmentHealthBarContainer scoreColor={getColorForScore(data.scores.total)}>
      <div
        style={{ backgroundColor: getColorForScore(data.scores.total) }}
        className="bar"
      ></div>
      <div className="ring-container">
        <div className={`ring ring-${data.segment.id}`}>
          <span>{data.abreviation}</span>
        </div>
      </div>
    </SegmentHealthBarContainer>
  );
}
