import * as React from 'react';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Container } from '../../components/containers/container';
import SVGInline from 'react-svg-inline';
import breakpoint from 'styled-components-breakpoint';

export const FooterContainer = styled.div`
  background: #334450;
  padding: 12px 0 24px 0;
  margin-top: 16px;
  width: 100%;

  svg {
    width: 130px;
    max-height: 130px;
    margin-top: -40px;
  }

  .SVGInline {
    max-height: 50px;
    overflow: hidden;
    display: inline-block;
  }

  p {
    color: #fff;
    font-family: proxima-nova, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
  }

  #right {
    text-align: right;
  }

  a {
    color: #fff;
    text-decoration: none;
    margin-left: 8px;
  }

  ${breakpoint('xs', 'sm')`
    text-align: center;

    #right {
      text-align: center;
    }
  `}
`;


const getYear = new Date().getFullYear()

export function Footer(props) {

  return (
    <FooterContainer>
      <Container>
        <Grid>
          <Grid.Unit size={{ sm: 4 / 12 }}>
            <Padding top={3}>
              <div style={{ width: '100%' }}>
                <p>
                  Patent Pending US App No. 17580520 <br />
                  Copyright {getYear}. <br />
                  All rights reserved. Compass and Nail, Inc.
                </p>
              </div>
            </Padding>
          </Grid.Unit>
          <Grid.Unit style={{ textAlign: 'center' }} size={{ sm: 4 / 12 }}>
            <Padding top={3}>
              <SVGInline svg={icon} />
            </Padding>
          </Grid.Unit>
          <Grid.Unit id="right" size={{ sm: 4 / 12 }}>
            <Padding top={3}>
              <p>
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>
                <a href="/terms" target="_blank">
                  Terms & Conditions
                </a>
                <br />
                <a href="mailto:hello@compassandnail.com">
                  Contact: hello@compassandnail.com
                </a>
              </p>
            </Padding>
          </Grid.Unit>
        </Grid>
      </Container>
    </FooterContainer>
  );
}

export default Footer;

export const icon = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 360 360" style="enable-background:new 0 0 360 360;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
<g>
	<path class="st0" d="M149.2,171.6l-2.4-1.3c-0.4-0.2-1-0.1-1.3,0.3c-1.2,1.8-3.4,2.9-5.6,2.9c-4.6,0-7.9-3.5-7.9-8.4
		s3.3-8.4,7.9-8.4c2.2,0,4.4,1.1,5.6,2.9c0.3,0.4,0.8,0.6,1.3,0.3l2.4-1.4c0.2-0.1,0.4-0.4,0.5-0.6c0.1-0.3,0-0.6-0.2-0.8
		c-2.3-3.3-5.6-5-9.6-5c-7.5,0-12.9,5.4-12.9,12.9s5.4,12.9,12.9,12.9c3.9,0,7.2-1.7,9.6-5c0.2-0.2,0.2-0.5,0.2-0.8
		C149.6,172,149.4,171.7,149.2,171.6z"/>
	<path class="st0" d="M173.3,152.2c-7.2,0-12.4,5.4-12.4,12.9s5.2,12.9,12.4,12.9c7.2,0,12.5-5.4,12.5-12.9S180.6,152.2,173.3,152.2
		z M173.3,173.5c-4.5,0-7.5-3.4-7.5-8.4c0-5,3-8.4,7.5-8.4c4.5,0,7.5,3.4,7.5,8.4C180.9,170.1,177.8,173.5,173.3,173.5z"/>
	<path class="st0" d="M222.3,152.6h-4.1c-0.4,0-0.8,0.2-0.9,0.6l-6.3,15.6l-6.3-15.6c-0.1-0.4-0.5-0.6-0.9-0.6h-4.1
		c-0.5,0-1,0.4-1,1v23.1c0,0.5,0.4,1,1,1h2.9c0.5,0,1-0.4,1-1v-14.4l6,14.8c0.1,0.4,0.5,0.6,0.9,0.6h1.2c0.4,0,0.8-0.2,0.9-0.6
		l5.9-14.8v14.4c0,0.5,0.4,1,1,1h2.9c0.5,0,1-0.4,1-1v-23.1C223.3,153,222.9,152.6,222.3,152.6z"/>
	<path class="st0" d="M255.6,160.5c0-4.7-3.4-7.9-8.2-7.9h-9.3c-0.5,0-1,0.4-1,1v23.1c0,0.5,0.4,1,1,1h2.9c0.5,0,1-0.4,1-1v-8.2h5.4
		C252.3,168.4,255.6,165.2,255.6,160.5z M247.1,157.1c2.2,0,3.6,1.3,3.6,3.4c0,2.1-1.4,3.4-3.6,3.4H242v-6.8H247.1z"/>
	<path class="st0" d="M277.6,153.2c-0.1-0.4-0.5-0.6-0.9-0.6h-3.6c-0.4,0-0.8,0.2-0.9,0.6l-9.3,23.1c-0.1,0.3-0.1,0.6,0.1,0.9
		c0.2,0.3,0.5,0.4,0.8,0.4h3.3c0.4,0,0.8-0.2,0.9-0.6l1.8-4.5H280l1.8,4.5c0.1,0.4,0.5,0.6,0.9,0.6h3.3c0.3,0,0.6-0.2,0.8-0.4
		s0.2-0.6,0.1-0.9L277.6,153.2z M278.4,168h-6.9l3.5-8.8L278.4,168z"/>
	<path class="st0" d="M307,162.3c-3-0.8-4.8-1.4-4.8-2.8c0-1.6,1.5-2.7,3.8-2.7c1.7,0,4.2,0.4,6,2.4c0.2,0.2,0.5,0.3,0.8,0.3
		c0.3,0,0.6-0.1,0.7-0.4l1.7-2.1c0.3-0.4,0.3-0.9,0-1.3c-2.2-2.3-5.3-3.6-8.9-3.6c-5.2,0-8.9,3.1-8.9,7.4c0,5.2,4.7,6.5,8.4,7.5
		c3.3,0.9,5.3,1.5,5.3,3.4c0,0.7-0.3,3-4.5,3c-3.5,0-5.7-2.1-6.5-3c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.7,0.4l-1.7,2.2
		c-0.3,0.4-0.3,0.9,0,1.3c2.3,2.6,5.7,4,9.6,4c7,0,9.5-4.2,9.5-7.8C315.9,164.7,310.8,163.3,307,162.3z"/>
	<path class="st0" d="M337.7,162.3c-3-0.8-4.8-1.4-4.8-2.8c0-1.6,1.5-2.7,3.8-2.7c1.7,0,4.2,0.4,6,2.4c0.2,0.2,0.5,0.3,0.8,0.3
		c0.3,0,0.6-0.1,0.7-0.4l1.7-2.1c0.3-0.4,0.3-0.9,0-1.3c-2.2-2.3-5.3-3.6-8.9-3.6c-5.2,0-8.9,3.1-8.9,7.4c0,5.2,4.7,6.5,8.4,7.5
		c3.3,0.9,5.3,1.5,5.3,3.4c0,0.7-0.3,3-4.5,3c-3.5,0-5.7-2.1-6.5-3c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.7,0.4l-1.7,2.2
		c-0.3,0.4-0.3,0.9,0,1.3c2.3,2.6,5.7,4,9.6,4c7,0,9.5-4.2,9.5-7.8C346.6,164.7,341.5,163.3,337.7,162.3z"/>
	<path class="st0" d="M142.6,201.7h-5.7v-6.2c0-0.5-0.4-1-1-1h-2c-0.5,0-1,0.4-1,1v6.2h-5.7c-0.5,0-1,0.4-1,1v1.8c0,0.5,0.4,1,1,1
		h5.7v6.4c0,0.5,0.4,1,1,1h2c0.5,0,1-0.4,1-1v-6.4h5.7c0.5,0,1-0.4,1-1v-1.8C143.5,202.1,143.1,201.7,142.6,201.7z"/>
	<path class="st0" d="M175.8,191.1h-2.9c-0.5,0-1,0.4-1,1v15.1l-11.5-15.7c-0.2-0.2-0.5-0.4-0.8-0.4h-2.9c-0.5,0-1,0.4-1,1v23.1
		c0,0.5,0.4,1,1,1h2.9c0.5,0,1-0.4,1-1v-15.4l11.7,16c0.2,0.3,0.5,0.4,0.8,0.4h2.8c0.5,0,1-0.4,1-1v-23.1
		C176.8,191.6,176.3,191.1,175.8,191.1z"/>
	<path class="st0" d="M203,191.7c-0.1-0.4-0.5-0.6-0.9-0.6h-3.6c-0.4,0-0.8,0.2-0.9,0.6l-9.3,23.1c-0.1,0.3-0.1,0.6,0.1,0.9
		c0.2,0.3,0.5,0.4,0.8,0.4h3.3c0.4,0,0.8-0.2,0.9-0.6l1.8-4.5h10.2l1.8,4.5c0.1,0.4,0.5,0.6,0.9,0.6h3.3c0.3,0,0.6-0.2,0.8-0.4
		c0.2-0.3,0.2-0.6,0.1-0.9L203,191.7z M203.8,206.6h-6.9l3.5-8.8L203.8,206.6z"/>
	<path class="st0" d="M227.7,191.1h-2.9c-0.5,0-1,0.4-1,1v23.1c0,0.5,0.4,1,1,1h2.9c0.5,0,1-0.4,1-1v-23.1
		C228.6,191.6,228.2,191.1,227.7,191.1z"/>
	<path class="st0" d="M256.7,211.7h-9.8v-19.6c0-0.5-0.4-1-1-1h-2.9c-0.5,0-1,0.4-1,1v23.1c0,0.5,0.4,1,1,1h13.6c0.5,0,1-0.4,1-1
		v-2.6C257.7,212.1,257.2,211.7,256.7,211.7z"/>
</g>
<path class="st0" d="M111.3,169.9c-3.5-10.5-10.7-19.3-20.3-24.9c-0.2-0.1-0.3-0.4-0.2-0.6l4.5-9c0.3-0.6,0.1-1.4-0.6-1.8l-2-1
	c-0.6-0.3-1.4-0.1-1.8,0.6l-4.5,9c-0.1,0.2-0.4,0.3-0.6,0.2c-10.2-4.3-21.6-4.8-32.1-1.3C30,149.2,17.2,175,25.1,198.7
	c6.2,18.6,23.5,31,43.1,31c4.9,0,9.7-0.8,14.4-2.3c11.5-3.8,20.8-11.9,26.3-22.8C114.3,193.8,115.2,181.5,111.3,169.9z M52.7,221.9
	c-0.2-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0-0.4l3.5-7c0.1-0.2,0.2-0.3,0.4-0.3c0.1,0,0.1,0,0.2,0c3.7,1.5,7.6,2.2,11.6,2.2
	c4.8,0,9.7-1.1,14-3.2c0.3-0.2,0.5-0.4,0.7-0.8c0.1-0.3,0.1-0.7-0.1-1l-1-1.9c-0.3-0.6-1.1-0.9-1.7-0.6c-3.7,1.8-7.7,2.7-11.9,2.7
	c-3.2,0-6.4-0.6-9.4-1.7c-0.1,0-0.2-0.1-0.3-0.3c0-0.1,0-0.3,0-0.4l29.7-59.5c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.4,0
	c8.4,5,14.8,12.8,17.9,22.1c3.4,10.3,2.7,21.3-2.2,31c-4.8,9.7-13.2,17-23.5,20.4C71.8,226,61.8,225.6,52.7,221.9z M41.1,182.7
	c0.4-7.3,3.8-14,9.5-18.9c5-4.3,11.3-6.6,17.7-6.6c0.8,0,1.7,0,2.5,0.1c2.3,0.2,4.6,0.7,6.7,1.6c0.1,0,0.2,0.1,0.3,0.3
	c0,0.1,0,0.3,0,0.4L54.2,207c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.3,0-0.4-0.1C45.3,201.8,40.6,192.5,41.1,182.7z M83.9,147.5
	l-3.5,6.9c-0.1,0.2-0.4,0.3-0.6,0.2c-3.7-1.5-7.6-2.2-11.6-2.2c-17.6,0-31.9,14.3-31.9,31.9c0,11.1,5.7,21.3,15.2,27.2
	c0.2,0.1,0.3,0.4,0.2,0.6l-3.5,7c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0-0.4,0c-8.4-5-14.8-12.8-17.9-22.1
	c-3.4-10.3-2.7-21.3,2.2-31c4.9-9.7,13.2-17,23.5-20.4c4.2-1.4,8.5-2.1,12.8-2.1c5.3,0,10.6,1,15.5,3.1c0.2,0.1,0.2,0.2,0.2,0.3
	C84,147.2,84,147.3,83.9,147.5z"/>
</svg>
`;
