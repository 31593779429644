import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  HeadlineFour,
  Paragraph
} from '../../../components/typography';
import { Divider } from '../../../components/decoration/divider';
import SideBar from '../../../components/navigation/sidebar';
import { Themes } from './consumer.screen';
import { getConsumerFindings, getStakeholderFindings } from '../../../data';
import {
  ButtonHollow,
  DownloadButton,
  DownloadButtonContainer
} from '../../../components/buttons';
import SVGInline from 'react-svg-inline';
import { useQuery } from 'react-apollo-hooks';
import { LOAD_BRAND_STAKEHOLDERFINDINGS_DOWNLOAD } from '../../../utils/gql';

interface StakeHolderFindingsScreenProps {
  brand: any;
  findings: any;
  downloads: any;
}

export function StakeHolderFindingsScreen(
  props: StakeHolderFindingsScreenProps
) {
  return (
    <Container>
      <SideBar active="strategic" brand={props.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
          <Padding all={2}>
            <HeadlineOne>STAKEHOLDER FINDINGS</HeadlineOne>
          </Padding>
        </Grid.Unit>
        <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
          <Padding all={2}>
            <DownloadButtonContainer>
              <DownloadButton>
                <a
                  href={`${process.env.REACT_APP_API ||
                    'http://localhost:3000'}/${
                    props.downloads.stakeholderFindings
                  }`}
                  target="_blank">
                  <SVGInline
                    svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`}
                  />
                  DOWNLOAD STAKEHOLDER FINDINGS
                </a>
              </DownloadButton>
            </DownloadButtonContainer>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>PURPOSE</HeadlineTwo>
                      <Divider />
                      <Paragraph>{props.findings.purpose}</Paragraph>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 8 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>METHODOLOGY</HeadlineTwo>
                      <Divider />
                      <Grid>
                        <Grid.Unit size={{ sm: 1, md: 8 / 12 }}>
                          <HeadlineFour>INTERVIEWEES</HeadlineFour>
                          {(props.findings.interviewees || '')
                            .split('\n')
                            .map((text, i) => {
                              return <Paragraph key={i}>{text}</Paragraph>;
                            })}
                        </Grid.Unit>
                        <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                          <HeadlineFour>DURATION</HeadlineFour>
                          {(props.findings.duration || '')
                            .split('\n')
                            .map((text, i) => {
                              return <Paragraph key={i}>{text}</Paragraph>;
                            })}
                        </Grid.Unit>
                      </Grid>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Themes
        headline={`Themes include a statement summarizing the key takeaway and representative commentary from stakeholders .`}
        findings={props.findings}
      />
    </Container>
  );
}

export function StakeHolderFindingsScreenLoader(props: any) {
  const { data, error, loading } = useQuery(LOAD_BRAND_STAKEHOLDERFINDINGS_DOWNLOAD, {
    variables: { id: props.match.params.brand }
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <StakeHolderFindingsScreen
      findings={data.brand.stakeholderFindings.contents}
      downloads={data.brand.downloads}
      brand={props.match.params.brand}
    />
  );
}

export default StakeHolderFindingsScreenLoader;
