import * as React from 'react';
import { useGlobal } from 'reactn';
import { useQuery } from 'react-apollo-hooks';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import * as store from 'store';
import moment from 'moment';

import AdminScreen from '../../components/admin/containers/screen';
import { HeadlineTwo } from '../../components/typography';
import { Box } from '../../components/containers/box';
import { Divider } from '../../components/decoration/divider';
import notify from '../../utils/notify';
import { LOAD_BRAND } from '../../utils/gql';

export const StyledInput = styled.select`
  min-width: 150px;
  padding: 12px 8px;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d7d7d8;
  font-family: proxima-nova, sans-serif;
  text-align: right;
  margin-bottom: 10px;

  :focus {
    border: 1px solid #509bb0;
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 6px 0;
  display: block;
`;

function AdminImportData(props: any) {

    // Page header.

    const buttons = [{
        label: 'SAVE',
        onClick: save,
    }]

    // Save.

    const [global, setGlobal] = useGlobal();

    async function save() {
        if (!csv.valid) return notify(setGlobal, 'Sorry, your file is invalid. Please contact us for assistance.', 'error');
        
        const form = new FormData();
        form.append('brand', props.brand.id);
        form.append('format', csv.format);
        form.append('file', csv.file);

        try {
            notify(setGlobal, 'Uploading... this may take a few minutes.', 'success');
            const importRes = await fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : 'http://localhost:3000'}/import`, {
                method: 'POST',
                headers: {'Authorization': store.get('token')},
                body: form
            });
            const importData = await importRes.json();
            console.log(importData);

            const scrubRes = await fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API: 'http://localhost:3000'}/scrub`, {
                method: 'POST',
                headers: {
                    'Authorization': store.get('token'),
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(importData)
            });
            const scrubData = await scrubRes.json();
            console.log(scrubData);

            const parseRes = await fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API: 'http://localhost:3000'}/parse`, {
                method: 'POST',
                headers: {
                    'Authorization': store.get('token'),
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(scrubData)
            });
            const parseData = await parseRes.json();
            console.log(parseData);

            notify(setGlobal, 'Upload complete.', 'success');
        } catch (error) {
            console.log(error)
            notify(setGlobal, `Upload failed - ${error.message}`, 'error')
            throw error
        }
            // fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : 'http://localhost:3000'}/import`, {
            //     method: 'POST',
            //     headers: {'Authorization': store.get('token')},
            //     body: form
            // }).then(res => res.json())
            //     .then(data => {
            //         console.log(data);
            //     })
            //     .catch(rejected => {
            //         console.log(rejected);
            //     })
            //     notify(setGlobal, 'Uploading... this may take a few minutes.', 'success');
            // } catch (error) {
            //     console.log(error)
            //     notify(setGlobal, `Upload failed - ${error.message}`, 'error')
            //     throw error
            // }
    }

    // File select and parse.

    const [csv, setCSV] = React.useState({
        format: "",
        file: null,
        requiredHeaders: {
            SHOPIFY: ['Name', 'Email', 'Created at', 'Total', 'Refunded Amount', 'Lineitem sku', 'Lineitem price'],
            TAC: ['Name', 'Email', 'Created at', 'Total', 'Lineitem sku', 'Lineitem price'],
        },
        valid: false
    })

    const onChangeFormat = (event) => {
        const {value} = event.target;
        setCSV(state => ({
            ...state,
            format: value
        }))
    }

    const onChangeFile = (event) => {
        const file = event.target.files[0];
        setCSV(state => ({
            ...state,
            file: file
        }))
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = reader.result as string;
            const rows = data.split('\n', 2);
            const headers = rows[0].split(',').map(e => e.replaceAll('\r', ''));
            const values = rows[1].split(',').map(e => e.replaceAll('\r', ''));
            if (headers.length && csv.requiredHeaders[csv.format].every(e => headers.includes(e))) {
                setCSV(state => ({
                    ...state,
                    valid: true
                }))
            } else {
                setCSV(state => ({
                    ...state,
                    valid: false
                }))
            }
        }
        reader.readAsText(file);
    }

    return (
        <AdminScreen
            headline="IMPORT DATA"
            buttons={buttons}>
            <Grid>
                <Grid.Unit size={{ sm: 1 }}>
                    <Padding all={2}>
                        <Box>
                            <Grid>
                                <Grid.Unit size={{ sm: 1, md: 1/3 }}>
                                    <Padding all={2}>
                                        <Padding all={3}>
                                            <HeadlineTwo>SELECT FORMAT</HeadlineTwo>
                                            <Divider />
                                            <div>
                                                <StyledLabel>Format</StyledLabel>
                                                <StyledInput 
                                                    name="format"
                                                    value={csv.format}
                                                    onChange={event => onChangeFormat(event)}>
                                                    <option value="">Select a format</option>
                                                    <option value="SHOPIFY">SHOPIFY</option>
                                                    <option value="TAC">TAC</option>
                                                </StyledInput>
                                            </div>
                                        </Padding>
                                        <Padding all={3}>
                                            <HeadlineTwo>SELECT FILE</HeadlineTwo>
                                            <Divider />
                                            <input 
                                                type="file" 
                                                accept=".csv" 
                                                disabled={!csv.format}
                                                onChange={event => onChangeFile(event)} />
                                        </Padding>
                                        {/* <Padding all={3}>
                                            <HeadlineTwo>MAP FIELDS</HeadlineTwo>
                                            <Divider />
                                            {csv.mappings.map(m => (
                                                <div>
                                                    <StyledLabel>{m.mapping.replaceAll('_', ' ')}</StyledLabel>
                                                    <StyledInput 
                                                        name={m.mapping}
                                                        value={m.field}
                                                        onChange={event => onChangeMapping(event)}>
                                                        <option value="">Select a column</option>
                                                        {csv.fields.map(f => (
                                                            <option value={f.header}>{f.header}</option>
                                                        ))}
                                                    </StyledInput>
                                                </div>
                                            ))}
                                        </Padding> */}
                                    </Padding>
                                </Grid.Unit>
                            </Grid>
                        </Box>
                    </Padding>
                </Grid.Unit>
            </Grid>
        </AdminScreen>
    )
}

function AdminImportDataLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND, {
    variables: {
        id: props.match.params.brand,
    }
  })

  if (loading || !data || error) return null;

  return (
    <AdminImportData
      history={props.history}
      brand={data.brand} />
  )
}

export default AdminImportDataLoader;