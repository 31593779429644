import * as React from 'react';
import loader from '../../loader';
import styled from 'styled-components';
import cc from 'classcat';
import { useGlobal } from 'reactn';

export const NotificationContext = React.createContext(null);

export const NotificationWrapper = styled.div`
  .notifications {
    position: fixed;
    right: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;

    transition: background 0.5s;
    z-index: 9999;

    &.showing {
      background: rgba(0, 0, 0, 0.3);
    }

    &.success {
      .notification {
        background: #4bb543;
        color: #fff;
      }
    }

    &.error {
      .notification {
        background: #ed4747;
        color: #fff;
      }
    }

    .notification {
      padding: 32px 32px;
      background: #fff;
      // box-shadow: 0 2px 2px 0 rgba(23, 28, 33, 0.06); // from existing admin
      border-radius: 0px;
      display: flex;

      align-items: center;
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100%;

      span {
        display: inline-block;
        font-family: proxima-nova, sans-serif;
        font-size: 24px;
        font-weight: 300;
        line-height: 29px;
        margin-left: 64px;
      }

      .loader {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
    }
  }
`;

export function notificationReducer(state, action) {
  switch (action.type) {
    case 'SHOW': {
      return { ...state, show: true, message: action.message };
    }

    case 'HIDE': {
      return { ...state, show: false, message: null };
    }
  }

  return state;
}

export function Notifications() {
  const [global, setGlobal] = useGlobal();
  const classes = cc(['notifications', { showing: global.show }, global.type]);

  return (
    <NotificationWrapper>
      <div className={classes}>
        {global.show && (
          <div data-aos="fade-down" className="notification">
            <span>{global.message}</span>
          </div>
        )}
      </div>
    </NotificationWrapper>
  );
}

export default NotificationContext;
