export function insightsCountAsPercentage(
  benchmark: number,
  value: number,
  score: number
): string | any {
  if (value > benchmark) {
    return [
      {
        text: 'Count above standard',
        short: 'Count',
        negative: false,
      },
    ];
  } else {
    return [
      {
        text: 'Count below standard',
        short: 'Count',
        negative: true,
      },
    ];
  }
}

export function insightsRevenueAsPercentage(
  benchmark: number,
  value: number,
  score: number
): string | any {
  if (value > benchmark) {
    return [
      {
        text: 'Revenue above standard',
        short: 'Revenue',
        negative: false,
      },
    ];
  } else {
    return [
      {
        text: 'Revenue below standard',
        short: 'Revenue',
        negative: true,
      },
    ];
  }
}

export function insightsMigrationForward(
  benchmark: number,
  value: number,
  score: number
): string | any {
  if (value > benchmark) {
    return [
      {
        text: 'Migration above standard',
        short: 'Migration',
        negative: false,
      },
    ];
  } else {
    return [
      {
        text: 'Migration below standard',
        short: 'Migration',
        negative: true,
      },
    ];
  }
}
