import React from 'react';
import { useMutation } from 'react-apollo-hooks';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import * as store from 'store';

import { Container } from '../../components/containers/container';
import { Box } from '../../components/containers/box';
import { HeadlineTwo } from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import TextInput from '../../components/inputs/text';
import { ButtonHollow } from '../../components/buttons';
import { LOGIN } from '../../utils/gql';

export const LoginButton = styled(ButtonHollow)`
  width: 100%;
  text-align: center;
  margin: 12px 0;
  padding: 16px;
  font-weight: 700;
`;

export const Logo = styled.div`
  text-align: center;
`;

export const FormError = styled.div`
  padding: 0.825rem;
  text-align: center;
  background: #de5f5f;
  margin: 0.5rem 0;
  border-radius: 4px;
  color: #fff;
`;

export function Login(props) {

  const mutation = useMutation(LOGIN);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  
  const [error, setError] = React.useState(false);

  const login = async () => {
    try {
      const response: any = await mutation({
        variables: {
            email,
            password,
        }
      })
      if (response.data.login.token) {
        store.set('firstName', response.data.login.firstName);
        store.set('lastName', response.data.login.lastName);
        store.set('token', response.data.login.token);
        store.set('type', response.data.login.type);
        store.set('roles', JSON.stringify(response.data.login.roles));
        return props.history.push('/', true);
      }
    } catch (e) {
      setError(true);
    }
  }

  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2} />
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 3 / 12 }} />
        <Grid.Unit size={{ sm: 6 / 12 }}>
          <Padding all={2}>
            <Padding all={3}>
              <Logo>
                <img src="/static/logo.png" style={{margin: "auto"}} />
              </Logo>
            </Padding>
            <Box>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                  <Padding all={3}>
                    <HeadlineTwo>LOGIN</HeadlineTwo>
                    <Divider />
                    {error ? (
                      <FormError>Wrong username or password.</FormError>
                    ) : null}
                    <TextInput
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="email"
                      label="Email"
                    />
                    <TextInput
                      type="password"
                      placeholder="Password"
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div style={{ textAlign: 'right', marginBottom: 12 }}>
                      <a
                        style={{ color: ' #509bb0', fontSize: 12 }}
                        href="/reset-password"
                      >
                        Forgot Password?
                      </a>
                    </div>
                    <LoginButton onClick={login}>LOGIN</LoginButton>
                  </Padding>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export default Login;