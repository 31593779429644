export interface WeightedScaleValue {
  low: number;
  high: number;
  value: number;
}

export interface WeightedValue {
  raw: number;
  weighted: number;
  benchmark: number;
  name?: string | any;
  insights?: any[];
  optimizations?: any[];
}

export function calcWeighted(
  value: number,
  benchmark: number,
  scale: WeightedScaleValue[]
): number {
  const w = value / benchmark;

  for (let grade of scale) {
    if (w >= grade.low && w <= grade.high) {
      return grade.value;
    }
  }
}
