import * as React from 'react';
import { insightsMigrationForward } from './common';

export function insightsProspectsMigrationForward(
  benchmark: number,
  value: number,
  score: number
): string | any {
  return insightsMigrationForward(benchmark, value, score);
}
