import * as React from 'react';
import { useGlobal } from 'reactn';
import { useQuery } from 'react-apollo-hooks';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import * as store from 'store';
import moment from 'moment';

import AdminScreen from '../../components/admin/containers/screen';
import { HeadlineTwo } from '../../components/typography';
import { Box } from '../../components/containers/box';
import { Divider } from '../../components/decoration/divider';
import notify from '../../utils/notify';
import { LOAD_BRAND } from '../../utils/gql';

const environment = window.location.hostname === 'theplatform.compassandnail.com'
  ? 'production'
  : 'staging';

export const StyledInput = styled.select`
  min-width: 150px;
  padding: 12px 8px;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d7d7d8;
  font-family: proxima-nova, sans-serif;
  text-align: right;
  margin-bottom: 10px;

  :focus {
    border: 1px solid #509bb0;
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 6px 0;
  display: block;
`;

function AdminMigrateData(props: any) {

    // Page header.

    const buttons = [
      {
        label: 'MIGRATE ORDER DATA',
        onClick: saveOrderData,
      },
      {
        label: 'MIGRATE GA4 DATA',
        onClick: saveGaData
      }
    ]

    // Save.

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [global, setGlobal] = useGlobal();

    let payload = {
      brandIds: [props.brand.id],
      environment: environment,
    }

    async function saveGaData() {
      try {
        notify(setGlobal, 'Migrating GA data... this may take a few minutes.', 'success');
        const res = await fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API: 'http://localhost:3000'}/migrateAnalytics`, {
          method: 'POST',
          headers: {
            'Authorization': store.get('token'),
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            brandId: props.brand.id
          })
        });
        const data = await res.json();
        console.log(data);
        if (data && data.success) {
          notify(setGlobal, 'GA Migration started. This may take a few minutes.', 'success');
        } else if (data && data.error) {
          notify(setGlobal, `GA Migration failed - ${data.error}`, 'error')
        } else {
          notify(setGlobal, `GA Migration failed - Unknown error.`, 'error')
        }
      } catch (error) {
        console.log(error)
        notify(setGlobal, `GA Migration failed - ${error.message}`, 'error')
      }
    }
    async function saveOrderData() {
        try {
            const migrateRes = await fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : 'http://localhost:3000'}/migrateOrders`, {
                method: 'POST',
                headers: {
                  'Authorization': store.get('token'),
                  'Content-Type': "application/json"
                },
                body: JSON.stringify(payload)
            });
            const migrateData = await migrateRes.json();
            console.log(migrateData);
            // await sleep(3000);
            notify(setGlobal, 'Order Migration started. This may take several minutes.', 'success');
        } catch (error) {
            console.log(error)
            notify(setGlobal, `Order Migration failed - ${error.message}`, 'error')
            throw error
        }
    }

    return (
        <AdminScreen
            headline="MIGRATE DATA"
            buttons={buttons} />
    )
}

function AdminMigrateDataLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND, {
    variables: {
        id: props.match.params.brand,
    }
  })

  if (loading || !data || error) return null;

  return (
    <AdminMigrateData
      history={props.history}
      brand={data.brand} />
  )
}

export default AdminMigrateDataLoader;