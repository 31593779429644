import * as React from 'react';
import formatters, { formatter } from '../formatters';
import {
  debugCountAsPercentage,
  debugMigrationForward,
  debugRevenueAsPercentage,
} from './common';

export function debugLoyalistsCountAsPercentage(benchmark, value, weighted) {
  return debugCountAsPercentage(benchmark, value, weighted);
}

export function debugLoyalistsRevenueAsPercentage(benchmark, value, weighted) {
  return debugRevenueAsPercentage(benchmark, value, weighted);
}

export function debugLoyalistsMigrationForward(benchmark, value, weighted) {
  return debugMigrationForward(benchmark, value, weighted);
}

export function debugLoyalistsIndValue(
  benchmark,
  value,
  weighted,
  casualIndValue?,
  loyalistIndValue?
) {
  return (
    <div>
      <p>Ind Value</p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Benchmark</td>
            <td>{benchmark}:1 of casuals value</td>
          </tr>
          <tr>
            <td>Casual Ind Value</td>
            <td>{formatters.currency.format(casualIndValue)}</td>
          </tr>
          <tr>
            <td>Loyalist Ind Value</td>
            <td>{loyalistIndValue}</td>
          </tr>
          <tr>
            <td>Value</td>
            <td>{formatters.count.format(value)}:1</td>
          </tr>
          <tr>
            <td>Score</td>
            <td>{weighted}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
