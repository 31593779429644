import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import SideBar from '../../components/navigation/sidebar';
import TextInput from '../../components/inputs/text';
import { ButtonHollow } from '../../components/buttons';
import useSession from '../../context/session';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import * as store from 'store';
import { PrivacyPolicy, TermsAndConditions } from './terms';

export function PrivacyPolicyPage(props: any) {
  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2} />
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 3 / 12 }} />
        <Grid.Unit size={{ sm: 10 / 12 }}>
          <Padding all={2}>
            <Box>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                  <Padding all={3}>
                    <PrivacyPolicy />
                  </Padding>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export function TermsPage(props: any) {
  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2} />
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 3 / 12 }} />
        <Grid.Unit size={{ sm: 10 / 12 }}>
          <Padding all={2}>
            <Box>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
                  <Padding all={3}>
                    <TermsAndConditions />
                  </Padding>
                </Grid.Unit>
              </Grid>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export default PrivacyPolicyPage;
