import * as React from 'react';
import formatters, { formatter } from '../formatters';
import {
  debugCountAsPercentage,
  debugMigrationForward,
  debugRevenueAsPercentage,
} from './common';

export function debugCasualCountAsPercentage(benchmark, value, weighted) {
  return debugCountAsPercentage(benchmark, value, weighted);
}
export function debugCasualRevenueAsPercentage(benchmark, value, weighted) {
  return debugRevenueAsPercentage(benchmark, value, weighted);
}
export function debugCasualMigrationForward(benchmark, value, weighted) {
  return debugMigrationForward(benchmark, value, weighted);
}
export function debugCasualCostToConvert() {}
export function debugCasualCostToActivate() {}
