import { useEffect, useRef, useState } from "react"
import notify from "./notify";
import { useGlobal } from "reactn";
import * as store from 'store';

const environment = window.location.hostname === 'theplatform.compassandnail.com'
  ? 'production'
  : 'staging';

const REQUEST_TABLE = `CalculationPeriod-${environment}`;
const COMPLETED_REQUEST_TABLE = `CompletedCalculationRequest-${environment}`;

type IRequestPayload = {
  brandId: string
  periods: any[]
}

export const useDataCalculation = (requestPayload: IRequestPayload = null) => {
  const [global, setGlobal] = useGlobal();
  const [data, setData] = useState<any>(null);

  const pollLimit = 12;
  const pollCountRef = useRef<number>(1);

  const getDataRecursive = async (payload: IRequestPayload) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API ? process.env.REACT_APP_API: 'http://localhost:3000'}/getBrandMetrics`, {
        method: 'POST',
        headers: {
          'Authorization': store.get('token'),
          'Content-Type': "application/json"
        },
        body: JSON.stringify(payload)
      })

      const body = await response.json()

      if (body.status === 'complete') {
        setData(body)
        return

      } else if (body.status === 'processing') {
        if (pollCountRef.current < pollLimit) {
          pollCountRef.current += 1;
          new Promise(resolve => setTimeout(resolve, 10000)).then(() => getDataRecursive(payload));
          return
        } else {
          notify(
            setGlobal,
            'Data calculation failed. Please try again or contact us for assistance.',
            'error'
          );
        }
      }

      if (body.error) {
        console.error("getBrandMetrics response returned an error:", body.error)
      }
      
      throw new Error("Something went wrong")

    } catch (error) {
      console.error("getDataRecursive error", error)

      if (pollCountRef.current < pollLimit) {
        pollCountRef.current += 1;
        new Promise(resolve => setTimeout(resolve, 10000)).then(() => getDataRecursive(payload));
      } else {
        notify(
          setGlobal, 
          'Data calculation failed. Please try again or contact us for assistance.', 
          'error'
        );
      }
    }
  }

  useEffect(() => {
    if (requestPayload) {
      console.time('useDataCalculation ' + JSON.stringify(requestPayload))
      getDataRecursive(requestPayload)
    }
  }, [requestPayload])

  return {
    data,
  }
}