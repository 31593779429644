import * as React from 'react';
import styled from 'styled-components';

export const TextInputWrapper = styled.div`
  margin: 0 0 16px 0;
`;

export const StyledInput = styled.textarea`
  width: 100%;
  padding: 12px 8px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
  appearance: none;
  border: 1px solid #d7d7d8;
  font-family: proxima-nova, sans-serif;
  max-width: 100%;

  :focus {
    appearance: none;
    border: 1px solid #509bb0;
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 6px 0;
  display: block;
`;

export default function TextAreaInput(props) {
  return (
    <TextInputWrapper>
      {props.label ? <StyledLabel>{props.label}</StyledLabel> : null}
      <StyledInput onChange={props.onChange} placeholder={props.placeholder}>
        {props.value || ''}
      </StyledInput>
    </TextInputWrapper>
  );
}
