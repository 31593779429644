import * as React from 'react';
import formatters, { formatter } from '../formatters';

export function debugCountAsPercentage(benchmark, value, weighted) {
  return (
    <div>
      <p>Count as a Percentage</p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>benchmark</td>
            <td>{formatters.percent.format(benchmark)}</td>
          </tr>

          <tr>
            <td>value</td>
            <td>{formatters.percent.format(value)}</td>
          </tr>
          <tr>
            <td>score</td>
            <td>{weighted}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export function debugRevenueAsPercentage(benchmark, value, weighted) {
  return (
    <div>
      <p>Revenue as a Percentage</p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>benchmark</td>
            <td>{formatters.percent.format(benchmark)}</td>
          </tr>

          <tr>
            <td>value</td>
            <td>{formatters.percent.format(value)}</td>
          </tr>
          <tr>
            <td>score</td>
            <td>{weighted}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export function debugMigrationForward(benchmark, value, weighted) {
  return (
    <div>
      <p>Migration</p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>benchmark</td>
            <td>{formatters.percent.format(benchmark)}</td>
          </tr>

          <tr>
            <td>value</td>
            <td>{formatters.percent.format(value)}</td>
          </tr>
          <tr>
            <td>score</td>
            <td>{weighted}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
