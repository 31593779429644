import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { Container } from '../containers/container';
import { useQuery } from 'react-apollo-hooks';
import * as store from 'store';
import { LOAD_BRAND_DATACONFIG_DOWNLOADS } from '../../utils/gql';
import { checkPermissions, SCOPES } from '../../utils/permissions';

export const NavigationContainer = styled.div`
  padding: 30px 0;

  position: absolute;
  left: -48px;
  top: 86px;
  z-index: 999;

  &.open {
    padding-right: 130px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &.open {
      svg {
        fill: #fff;
      }

      .active {
        .content {
          svg {
            fill: #fff;
          }
        }
      }
    }

    .active {
      .content {
        svg {
          fill: #334450;
        }
      }
    }

    li {
      margin-bottom: 1rem;
      color: #71b9ca;
      font-size: 1.2rem;
      // display: flex;
      // align-items: center;

      display: block;
      height: auto;

      span {
        color: #fff;
        font-family: proxima-nova, sans-serif;
        font-weight: 700;
        font-size: 14px;
        display: inline-flex;
        margin-left: 8px;

        :hover {
          // color: #509bb0;
        }
      }

      a {
        color: #71b9ca;
        text-decoration: none;
        display: flex;
        align-items: center;

        :hover {
          color: #509bb0;
        }
      }

      .content {
        display: flex;
        align-items: center;
        height: 24px;
      }

      svg {
        transition: fill 0.3s;
        fill: #71b9ca;
        stoke: #71b9ca;
        cursor: pointer;
      }

      ul {
        display: block;
        margin: 8px 0 8px 30px;

        li {
          color: #cecece;
          font-family: proxima-nova, sans-serif;
          font-weight: 700;
          font-size: 13px;
          margin-left: 8px;
          margin-bottom: 8px;

          a {
            color: #cecece;

            :hover {
              color: #509bb0;
            }
          }
        }
      }
    }
  }
`;

export const NavigationWrapper = styled(Container)`
  position: relative;
  z-index: 9999;
`;

export const NavigationBackground = styled.div`
  position: fixed;
  width: 100%;
  // height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(51, 68, 80, 0.93);
  z-index: 9998;
  opacity: ${(props: any) => (props.open ? '1' : '0')};
  transition: opacity 0.5s;
  pointer-events: none;
  margin: 0;
  padding: 0;
` as any;

export const MobileNavigation = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export function Navigation(props) {
  const [open, setOpen] = React.useState(false);
  const [segmentsOpen, setSegmentsOpen] = React.useState(true);
  const [libraryOpen, setLibraryOpen] = React.useState(true);

  if (!store.get('token')) return null;

  const closeAll = () => {
    setOpen(false);
    // setSegmentsOpen(false);
    // setLibraryOpen(false);
  };

  if (!checkPermissions([SCOPES.canViewQual], props.brand)) {
    return null;
  }

  return (
    <div>
      <NavigationBackground open={open} />
      <NavigationWrapper>
        <NavigationContainer
          className={open ? 'open' : ''}
          onMouseEnter={(e) => setOpen(true)}
          onMouseLeave={(e) => setOpen(false)}>
          <ul className={open ? 'open' : ''}>
            {props.hasDataConfig && checkPermissions([SCOPES.canViewExec], props.brand) && (
              <li className={props.active === 'home' ? 'active' : ''}>
                <div className="content">
                  <Link to={`/brands/${props.brand}`} onClick={(e) => setOpen(false)}>
                    <SVGInline svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`} />
                    {open && (
                      <span>Executive Summary</span>
                    )}
                  </Link>
                </div>
              </li>
            )}

            <li className={props.active === 'bem' ? 'active' : ''}>
              <div className="content">
                <SVGInline style={{transform: "rotate(45deg)"}} svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M17.778 8.222c-4.296-4.296-11.26-4.296-15.556 0A1 1 0 01.808 6.808c5.076-5.077 13.308-5.077 18.384 0a1 1 0 01-1.414 1.414zM14.95 11.05a7 7 0 00-9.9 0 1 1 0 01-1.414-1.414 9 9 0 0112.728 0 1 1 0 01-1.414 1.414zM12.12 13.88a3 3 0 00-4.242 0 1 1 0 01-1.415-1.415 5 5 0 017.072 0 1 1 0 01-1.415 1.415zM9 16a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" /></svg>`} />
                {open && (
                  <span>Brand Ecosystem Model</span>
                )}
              </div>
              {open && (
                <ul>
                  <li>
                    <Link to={`/brands/${props.brand}/cac`} onClick={(e) => closeAll()}>
                      Customer Activation Cycle
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/bonds`} onClick={(e) => closeAll()}>
                      Five Bonds of Loyalty
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/story-universe`} onClick={(e) => closeAll()}>
                      Story Universe
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li className={props.active === 'segments' ? 'active' : ''}>
              <div className="content">
                <SVGInline svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path></svg>`} />
                {open && (
                  <span>Customer Segments</span>
                )}
              </div>
              {open && (
                <ul>
                  <li>
                    <Link to={`/brands/${props.brand}/segment/prospect`} onClick={(e) => closeAll()}>
                      Prospects
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/segment/casual`} onClick={(e) => closeAll()}>
                      Casuals
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/segment/loyalist`} onClick={(e) => closeAll()}>
                      Loyalists
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/segment/cheerleader`} onClick={(e) => closeAll()}>
                      Cheerleaders
                    </Link>
                  </li>
                  {props.hasDataConfig && (
                    <li>
                      <Link to={`/brands/${props.brand}/segment/lostsouls`} onClick={(e) => closeAll()}>
                        Lost Souls
                      </Link>
                    </li>
                  )}
                </ul>
              )}
            </li>

            <li className={props.active === 'strategic' ? 'active' : ''}>
              <div className="content">
                <SVGInline svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"></path><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z"></path></svg>`} />
                {open && (
                  <span>Strategic Library</span>
                )}
              </div>
              {open && (
                <ul>
                  <li>
                    <Link to={`/brands/${props.brand}/findings/stakeholder`} onClick={(e) => closeAll()}>
                      Stakeholder Findings
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/findings/consumer`} onClick={(e) => closeAll()}>
                      Consumer Findings
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/ladders/affinity`} onClick={(e) => closeAll()}>
                      Affinity Ladder
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/ladders/loyalty`} onClick={(e) => closeAll()}>
                      Loyalty Ladder
                    </Link>
                  </li>
                  <li>
                    <Link to={`/brands/${props.brand}/glossary-of-terms`} onClick={(e) => closeAll()}>
                      Glossary of Terms
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li className={props.active === 'downloads' ? 'active' : ''}>
              <div className="content">
                <Link to={`/brands/${props.brand}/strategic-library`} onClick={(e) => setOpen(false)}>
                  <SVGInline svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM12 17.5L6.5 12H10v-2h4v2h3.5L12 17.5zM5.12 5l.81-1h12l.94 1H5.12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`} />
                  {open && (
                    <span>Downloads</span>
                  )}
                </Link>
              </div>
              {open && (
                <ul>
                  <li>
                    <a href={`${process.env.REACT_APP_API || 'http://localhost:3000'}/${props.downloads.stakeholderFindings}`} target="_blank">
                      Stakeholder Findings
                    </a>
                  </li>
                  <li>
                    <a href={`${process.env.REACT_APP_API || 'http://localhost:3000'}/${props.downloads.consumerFindings}`} target="_blank">
                      Consumer Findings
                    </a>
                  </li>
                  <li>
                    <a href={`${process.env.REACT_APP_API || 'http://localhost:3000'}/${props.downloads.customerActivationCycle}`} target="_blank">
                      Customer Activation Cycle
                    </a>
                  </li>
                  <li>
                    <a href={`${process.env.REACT_APP_API || 'http://localhost:3000'}/${props.downloads.loyaltyArchitecture}`} target="_blank">
                      Loyalty Architecture
                    </a>
                  </li>
                  <li>
                    <a href={`${process.env.REACT_APP_API || 'http://localhost:3000'}/${props.downloads.loyaltyBusinessPlan}`} target="_blank">
                      Curriculum Homework
                    </a>
                  </li>
                  <li>
                    <a href={`${process.env.REACT_APP_API || 'http://localhost:3000'}/${props.downloads.brandBook}`} target="_blank">
                    Brand Compass
                    </a>
                  </li>
                </ul>
              )}
            </li>
            
            {checkPermissions([SCOPES.canManage], props.brand) && (
              <li className={props.active === 'settings' ? 'active' : ''}>
                <div className="content">
                  <Link to={`/admin/brand/${props.brand}`} onClick={(e) => setOpen(false)}>
                    <SVGInline svg={`<svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 20 20" fill="currentColor"> <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" /> </svg>`} />
                    {open && (
                      <span>Admin</span>
                    )}
                  </Link>
                </div>
              </li>
            )}
          </ul>
        </NavigationContainer>
      </NavigationWrapper>
    </div>
  );
}

export function NavigationLoader(props: any) {
  const { data, error, loading } = useQuery(LOAD_BRAND_DATACONFIG_DOWNLOADS, {
    variables: { id: props.brand },
  });

  if (error) return null;
  if (loading || !data) return null;

  return (
    <Navigation
      hasDataConfig={
        data.brand.dataConfig && data.brand.dataConfig.reportingTerm
      }
      downloads={data.brand.downloads}
      {...props}
    />
  );
}

export default NavigationLoader;
