import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { setGlobal } from 'reactn';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloProviderHooks } from 'react-apollo-hooks';
import ApolloClient from 'apollo-boost';
import styled, { ThemeProvider } from 'styled-components';
import * as store from 'store';
import './App.css';

import theme from './theme';
import Navigation from './components/navigation';
import NavigationAdmin from './components/navigation/admin';
// import { useLoadQuantData } from './metrics/apiv1';
import { useLoadQuantDatav2 } from './metrics/api';
import Dashboard from './screens/brand/dashboard';
import Login from './screens/auth/login';
import Setup from './screens/auth/setup';
import Privacy, { TermsPage } from './screens/auth/privacy';
import Segment from './screens/brand/segment';
import SegmentCompare from './screens/brand/segment.compare';
import SegmentDescription from './screens/brand/segment.description';
import AffinityLadder from './screens/brand/ladders/affinity.screen';
import LoyalistLadder from './screens/brand/ladders/loyalist.screen';
import ConsumerFindings from './screens/brand/findings/consumer.screen';
import StakeholderFindings from './screens/brand/findings/stakeholder.screen';
import ChooseBrand from './screens/brand/choose';
import StoryUniverse from './screens/brand/story-universe/story.universe.screen';
import GlossaryOfTerms from './screens/brand/glossary';
import FiveBonds from './screens/brand/five-bonds';
import Account from './screens/account/account';
import AdminBrand from './screens/admin/brand';
import AdminAffinityLadder from './screens/admin/affinity.ladder';
import AdminLoyaltyLadder from './screens/admin/loyalty.ladder';
import AdminStakeholderFindings from './screens/admin/stakeholder.findings';
import AdminConsumerFindings from './screens/admin/consumer.findings';
import AdminCustomerActivationSegment from './screens/admin/customer.activation';
import AdminStoryUniverse from './screens/admin/story.universe';
import AdminImportData from './screens/admin/import-data';
import AdminDataConfig from './screens/admin/config.data';
import AdminDataMigrate from './screens/admin/migrate-data';
import AdminAddBrand, {AdminEditBrandLoader as AdminEditBrand} from './screens/admin/brand.crud';
import AdminAddBrandUser, {AdminEditBrandUserLoader as AdminEditBrandUser} from './screens/admin/brand.user';
import AdminAddUser, {AdminEditUserLoader as AdminEditUser} from './screens/admin/user.crud';
import AdminDownloads from './screens/admin/downloads';
import AdminFiveBonds from './screens/admin/five-bonds';
import StrategicLibrary from './screens/brand/strategic.landing';
import Footer from './components/navigation/footer';
import RequestPassword from './screens/auth/request.password';
import ResetPassword from './screens/auth/reset.password';
import { Notifications } from './components/admin/notifications';
import { checkPermissions, SCOPES } from './utils/permissions';
import Home from './screens/brand/home';
import AdminUsers from './screens/admin/users';
import ShopifyConfig from './screens/admin/shopifyConfig';

// Set loading to true.
setGlobal({
  show: false,
  message: null,
  showing: false,
  type: null,
  term: '3-months',
  scoring: '1.1',
  term_one_base: null, // moment().format('YYYY-MM-DD'),
  term_one_compare: null,
  term_two_base: '',
  term_two_compare: '',
  term_three_base: '',
  term_three_compare: '',
  brand: null,
  loadingBrand: true,
  quantDataLoading: true,
});

// Pass your GraphQL endpoint to uri
const client = new ApolloClient({
  uri: process.env.REACT_APP_API
    ? `${process.env.REACT_APP_API}/gql`
    : 'http://localhost:3000/gql',
  request: async (operation) => {
    if (store.get('token')) {
      operation.setContext({
        headers: {
          authorization: store.get('token'),
        },
      });
    }
  },
});

const MinHeightWrapper = styled.div`
  min-height: calc(100vh - 212px);
`;

function LoginRoute(props) {
  if (store.get('token')) {
    return <Redirect to="/" />;
  } else {
    return <Route {...props} />;
  }
}

function LoggedInRoute({ component: Component, ...rest }) {
  if (!store.get('token')) {
    return <Redirect to="/login" />;
  }
  if (rest.path === '/') {
    setGlobal(state => ({
      ...state,
      brand: null,
      term_one_base: null, // moment().format('YYYY-MM-DD'),
      term_one_compare: null,
      term_two_base: '',
      term_two_compare: '',
      term_three_base: '',
      term_three_compare: '',
    }))
  }
  return (
    <Route {...rest} render={props => (
      <>
        <NavigationAdmin history={props.history} brand={props.match.params.brand} />
        <MinHeightWrapper>
          <Component {...props} />
        </MinHeightWrapper>
        <Footer />
      </>
    )} />
  )
}

function AdminRoute({ component: Component, ...rest }) {
  if (store.get('type') !== 'admin') {
    return <Redirect to="/" />;
  }
  return (
    <Route {...rest} render={props => (
      <>
        <NavigationAdmin history={props.history} brand={props.match.params.brand} />
        <MinHeightWrapper>
          <Component {...props} />
        </MinHeightWrapper>
        <Footer />
      </>
    )} />
  )
}

function BrandRoute({ component: Component, ...rest }) {
  if (!checkPermissions([SCOPES.canView], rest.computedMatch.params.brand)) {
    return <Redirect to="/" />;
  }
  if (rest.scopes && !checkPermissions(rest.scopes, rest.computedMatch.params.brand)) {
    return <Redirect to={`/brands/${rest.computedMatch.params.brand}`} />;
  }
  const data = useLoadQuantDatav2(rest.computedMatch.params.brand);
  return (
    <Route {...rest} render={props => (
      <>
        <NavigationAdmin history={props.history} brand={props.match.params.brand} />
        <MinHeightWrapper>
          <Component {...props} quantData={data} />
        </MinHeightWrapper>
        <Footer />
      </>
    )} />
  )
}

function AppRouter() {
  return (
    <Router>
      <div>
        <Notifications />
        <Switch>
          <LoggedInRoute exact path="/" component={ChooseBrand} />
          <LoggedInRoute exact path="/account" component={Account} />
          <BrandRoute exact path="/brands/:brand" component={Home} scopes={[SCOPES.canView]} />
          <BrandRoute exact path="/brands/:brand/cac" component={Dashboard} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/segment/:segment" component={Segment} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/segment/:segment/compare" component={SegmentCompare} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/segment/:segment/description" component={SegmentDescription} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/ladders/affinity" component={AffinityLadder} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/ladders/loyalty" component={LoyalistLadder} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/findings/consumer" component={ConsumerFindings} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/findings/stakeholder" component={StakeholderFindings} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/story-universe" component={StoryUniverse} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/glossary-of-terms" component={GlossaryOfTerms} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/bonds" component={FiveBonds} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/brands/:brand/strategic-library" component={StrategicLibrary} scopes={[SCOPES.canViewQual]} />
          <BrandRoute exact path="/admin/brand/:brand" component={AdminBrand} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/edit" component={AdminEditBrand} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/affinity" component={AdminAffinityLadder} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/loyalty" component={AdminLoyaltyLadder} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/stakeholder" component={AdminStakeholderFindings} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/consumer" component={AdminConsumerFindings} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/downloads" component={AdminDownloads} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/import" component={AdminImportData} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/config" component={AdminDataConfig} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/shopify-config" component={ShopifyConfig} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/migrate" component={AdminDataMigrate} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/segment/:segment" component={AdminCustomerActivationSegment} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/su" component={AdminStoryUniverse} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/bonds" component={AdminFiveBonds} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/user" component={AdminAddBrandUser} scopes={[SCOPES.canManage]} />
          <BrandRoute exact path="/admin/brand/:brand/user/:user" component={AdminEditBrandUser} scopes={[SCOPES.canManage]} />
          <AdminRoute exact path="/admin/add-brand" component={AdminAddBrand} />
          <AdminRoute exact path="/admin/add-user" component={AdminAddUser} />
          <AdminRoute exact path="/admin/user/:user" component={AdminEditUser} />
          <AdminRoute exact path="/admin/users" component={AdminUsers} />
          <LoginRoute exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={RequestPassword} />
          <Route exact path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/setup/:user/:token" component={Setup} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={TermsPage} />
          <Route path="*"><Redirect to="/"/></Route>
        </Switch>
      </div>
    </Router>
  )
}
class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <ApolloProviderHooks client={client}>
            <AppRouter />
          </ApolloProviderHooks>
        </ApolloProvider>
      </ThemeProvider>
    );
  }
}

export default App;
