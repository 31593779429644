export function plotLevelZero(node) {
  return {
    ...node,
    position: {
      x: 0,
      y: 0,
      angle: 0
    },
    textPosition: {
      x: -60,
      y: -60,
      width: 120,
      height: 120,
      html: true
    }
  };
}

export default plotLevelZero;
