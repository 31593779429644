import * as React from 'react';
import styled from 'styled-components';
import { Padding } from 'styled-components-spacing';

const RowContainer = styled.div`
  background: #f7f7f7;
  background: ${(props: any) => props.color || '#fff'};
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;

  a {
    text-decoration: none;
    color: #509bb0;
  }
`;

export function Row(props) {
  return (
    <RowContainer>
      <Padding all={3}>{props.children}</Padding>
    </RowContainer>
  );
}

export default Row;
