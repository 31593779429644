import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../containers/box';
import { Link } from 'react-router-dom';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph
} from '../../typography';

import { Divider } from '../../decoration/divider';
import {
  ButtonHollow,
  DownloadButton,
  DownloadButtonContainer
} from '../../buttons';

export const StyledBreadcrumbs = styled.ul`
  margin: 16px 0;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 8px;
    color: #657c89;
    a {
      color: #509bb0;
    }
  }
`;

export function AdminScreen(props: any) {
  return (
    <Container>
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 7 / 12 }}>
          <Padding bottom={2} top={2}>
            <StyledBreadcrumbs>
              {(props.breadcrumbs || []).map((crumb, i) => {
                return (
                  <li key={i}>
                    {crumb.link ? (
                      <Link to={crumb.link}>{crumb.label}</Link>
                    ) : (
                      crumb.label
                    )}
                    {i !== (props.breadcrumbs || []).length - 1 ? ` / ` : ''}
                  </li>
                );
              })}
            </StyledBreadcrumbs>
            <HeadlineOne>{props.headline}</HeadlineOne>
          </Padding>
        </Grid.Unit>
        <Grid.Unit
          size={{ sm: 1, md: 5 / 12 }}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
          }}>
          <Padding top={2} bottom={2} style={{ display: 'flex' }}>
            {(props.buttons || []).map((button, key) => {
              return (
                <DownloadButtonContainer key={key}>
                  <DownloadButton
                    type={button.type}
                    onClick={button.onClick ? button.onClick : null}>
                    {button.link ? (
                      <Link to={button.link}>{button.label}</Link>
                    ) : (
                      button.label
                    )}
                  </DownloadButton>
                </DownloadButtonContainer>
              );
            })}
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding top={2} bottom={2}>
            {props.children}
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export default AdminScreen;
