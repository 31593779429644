import React, { Component } from 'react';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';
import breakpoint from 'styled-components-breakpoint';

interface LadderStepProps {
  touch: any;
  does: any;
  what: any;
}

export const ContainerBlue = styled.div`
  background: #f7fbfd;
`;

export const BlueGridUnit = styled(Grid.Unit)`
  background: #f7fbfd;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

export const GridUnit = styled(Grid.Unit)`
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

export const LadderStepGrid = styled(Grid)`
  margin-bottom: 4px;
`;

export const TouchPoint = styled.p`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 18px;

  span {
    text-align: center;
    display: inherit;
  }
`;

export const WhatItDoes = styled.p`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 18px;

  span {
    text-align: center;
    display: inherit;
  }
`;

export const WhatItIs = styled.p`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 18px;

  span {
    text-align: center;
    display: inherit;
  }
`;

export const Arrow = styled.div`
  svg {
    width: 30px;
    position: absolute;
    right: -15px;
    top: calc(50% - 12px);
    fill: #d9ebf4;
    stoke: #d9ebf4;
    z-index: 999;

    ${breakpoint('xs', 'md')`
      transform: rotate(90deg);
      top: calc(100% - 15px);
      right: calc(10% - 12px);
    `}
  }
`;

export function LadderStep(props: LadderStepProps) {
  return (
    <LadderStepGrid>
      <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
        <TouchPoint>
          {props.touch.split('\n').map(t => (
            <span>
              {t}
              <br />
            </span>
          ))}
        </TouchPoint>
        <Arrow>
          <SVGInline
            svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#71B9CA;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>keyboard-arrow-right</title><line class="a" x1="23.25" y1="12.497" x2="0.75" y2="12.497"></line><polyline class="a" points="19.5 16.247 23.25 12.497 19.5 8.747"></polyline></svg>`}
          />
        </Arrow>
      </BlueGridUnit>
      <GridUnit size={{ sm: 1, md: 6 / 12 }}>
        <Padding all={4}>
          <WhatItDoes>
            {props.does.split('\n').map(t => (
              <span>
                {t}
                <br />
              </span>
            ))}
          </WhatItDoes>
          <Arrow>
            <SVGInline
              svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#71B9CA;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>keyboard-arrow-right</title><line class="a" x1="23.25" y1="12.497" x2="0.75" y2="12.497"></line><polyline class="a" points="19.5 16.247 23.25 12.497 19.5 8.747"></polyline></svg>`}
            />
          </Arrow>
        </Padding>
      </GridUnit>
      <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
        <ContainerBlue>
          <Padding all={4}>
            <WhatItIs>
              {props.what.split('\n').map(t => (
                <span>
                  {t}
                  <br />
                </span>
              ))}
            </WhatItIs>
          </Padding>
        </ContainerBlue>
      </BlueGridUnit>
    </LadderStepGrid>
  );
}

export default LadderStep;
