import * as React from 'react';
import {
  debugCasualCountAsPercentage,
  debugCasualMigrationForward,
  debugCasualRevenueAsPercentage,
} from '../debug/casuals';

import { IQuantData, ISegmentData, SEGMENTS } from '../enum';
import formatters, { formatter } from '../formatters';
import {
  insightsCasualCostToActivate,
  insightsCasualCountAsPercentage,
  insightsCasualMigrationForward,
  insightsCasualRevenueAsPercentage,
} from '../insights/casuals';
import {
  optimizationsCasualCostToActivate,
  optimizationsCasualCountAsPercentage,
  optimizationsCasualMigrationForward,
  optimizationsCasualRevenueAsPercentage,
} from '../optimizations/casuals';

import { WeightedScaleValue, WeightedValue, calcWeighted } from './common';

export interface BenchmarkCasuals {
  MIGRATION_FORWARD: number;
  COUNT_AS_PERCENTAGE: number;
  REVENUE_AS_PERCENTAGE: number;
  ACTIVATION_COST: number;
  CONVERSION_COST: number;
}

export function formulasCasualsCountAsPercentage(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.current.countPercentOfTotal;
  const weighted = calcWeighted(
    segment.current.countPercentOfTotal,
    benchmark,
    scale
  );

  return {
    raw: raw,
    weighted,
    name: debugCasualCountAsPercentage(benchmark, raw, weighted),
    benchmark: benchmark,
    insights: insightsCasualCountAsPercentage(benchmark, raw, weighted),
    optimizations: optimizationsCasualCountAsPercentage(
      benchmark,
      raw,
      weighted
    ),
  };
}

export function formulasCasualsRevenueAsPercentage(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.current.revenueAsPercentageOfTotal;
  const weighted = calcWeighted(
    segment.current.revenueAsPercentageOfTotal,
    benchmark,
    scale
  );

  return {
    raw,
    weighted,
    name: debugCasualRevenueAsPercentage(benchmark, raw, weighted),
    insights: insightsCasualRevenueAsPercentage(benchmark, raw, weighted),
    optimizations: optimizationsCasualRevenueAsPercentage(
      benchmark,
      raw,
      weighted
    ),
    benchmark: benchmark,
  };
}

export function formulasCasualsMigrationForward(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.migration.to.loyalists / segment.previous.count;
  const weighted = calcWeighted(
    segment.migration.to.loyalists / segment.previous.count,
    benchmark,
    scale
  );

  return {
    raw,
    weighted,
    name: debugCasualMigrationForward(benchmark, raw, weighted),
    insights: insightsCasualMigrationForward(benchmark, raw, weighted),
    optimizations: optimizationsCasualMigrationForward(
      benchmark,
      raw,
      weighted
    ),
    benchmark: benchmark,
  };
}

export function formulasCasualsCostToActivate(
  segment: ISegmentData,
  data: IQuantData,
  brand: any,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const marketingSpend =
    brand.dataConfig.prospects.users * brand.dataConfig.prospects.costPer;

  const newCustomerConversion = data[SEGMENTS.PROSPECTS].movement.migrationOut;

  const costofProspectAcquisition = marketingSpend / newCustomerConversion; // brand.dataConfig.prospects.costPer

  const raw =
    brand.dataConfig.casuals.targetAquisition / costofProspectAcquisition;

  const weighted = calcWeighted(raw, 1, scale);

  const name = (
    <div>
      <p>Cost to Activate</p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>marketing spend to acquire prospects</td>
            <td>{formatters.currency.format(marketingSpend)}</td>
          </tr>
          <tr>
            <td>total prospects</td>
            <td>{new Intl.NumberFormat('en-us').format(brand.dataConfig.prospects.users)}</td>
          </tr>
          <tr>
            <td>total prospects acquired</td>
            <td>{new Intl.NumberFormat('en-us').format(newCustomerConversion)}</td>
          </tr>
          {/* <tr>
            <td>new customer conversion (all segments)</td>
            <td>{formatters.count.format(newCustomerConversion)}</td>
            <td></td>
          </tr> */}
          <tr>
            <td>cost of acquisition per prospect</td>
            <td>{formatters.currency.format(costofProspectAcquisition)}</td>
          </tr>
          {/* <tr>
            <td>cost of acquisition per casual</td>
            <td>{formatters.currency.format(costofCasualAcquisition)}</td>
            <td>
              marketing spend to acquire prospects({formatters.currency.format(marketingSpend)}) / total prospects acquired({formatters.count.format(brand.dataConfig.prospects.users)})
            </td>
          </tr> */}
          <tr>
            <td>target acquisition</td>
            <td>
              {formatters.currency.format(
                brand.dataConfig.casuals.targetAquisition
              )}
            </td>
          </tr>
          <tr>
            <td>benchmark</td>
            <td>{formatters.percent.format(1)}</td>
          </tr>
          <tr>
            <td>value</td>
            <td>{formatters.percent.format(raw)}</td>
          </tr>
          <tr>
            <td>score</td>
            <td>{weighted}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return {
    raw: raw,
    weighted,
    name: name,
    insights: insightsCasualCostToActivate(1, raw, weighted),
    optimizations: optimizationsCasualCostToActivate(1, raw, weighted),
    benchmark: 1,
  };
}

export function scoreCasuals(
  segment: ISegmentData,
  data: IQuantData,
  brand: any,
  scale: WeightedScaleValue[],
  benchmarks: BenchmarkCasuals
) {
  const hasTargetCost =
    brand.dataConfig.prospects.users &&
    brand.dataConfig.prospects.costPer &&
    brand.dataConfig.casuals.targetAquisition;

  const scores = {
    use: true,
    grades: [],
    total: 0,
    totalFormatted: '0',
    insights: [],
    optimizations: [],
  };

  scores.grades = [
    formulasCasualsMigrationForward(
      segment,
      data,
      benchmarks.MIGRATION_FORWARD,
      scale
    ),
    formulasCasualsCountAsPercentage(
      segment,
      data,
      benchmarks.COUNT_AS_PERCENTAGE,
      scale
    ),
    formulasCasualsRevenueAsPercentage(
      segment,
      data,
      benchmarks.REVENUE_AS_PERCENTAGE,
      scale
    ),
    hasTargetCost
      ? formulasCasualsCostToActivate(
          segment,
          data,
          brand,
          benchmarks.ACTIVATION_COST,
          scale
        )
      : null,
  ].filter((v) => v !== null);

  const total = scores.grades.reduce(
    (total, score) => total + score.weighted,
    0
  );

  scores.total = total / scores.grades.length;
  scores.totalFormatted = formatters.count.format(scores.total);

  scores.insights = scores.grades.reduce(
    (insights, grade) => [...insights, ...grade.insights],
    []
  );

  scores.optimizations = scores.grades.reduce(
    (optimizations, grade) => [...optimizations, ...grade.optimizations],
    []
  );

  return scores;
}
