import * as React from 'react';
import { Link } from 'react-router-dom';
import AdminScreen from '../../components/admin/containers/screen';
import { useQuery, useMutation } from 'react-apollo-hooks';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import styled from 'styled-components';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import * as store from 'store';
import { ADD_USER, LOAD_USER, REMOVE_USER, UPDATE_USER } from '../../utils/gql';

export const StyledSelect = styled.select`
  min-width: 150px;
  padding: 12px 8px;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d7d7d8;
  font-family: proxima-nova, sans-serif;
  text-align: right;
  margin-bottom: 10px;

  :focus {
    border: 1px solid #509bb0;
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 6px 0;
  display: block;
`;

export const ContainerBlue = styled.div`
  background: #f7fbfd;
`;

export const BlueGridUnit = styled(Grid.Unit)`
  background: #d9ebf4;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

export const LadderHeaderGrid = styled(Grid)`
  margin-bottom: 4px;
`;

export const Heading = styled.p`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  text-align: left;
`;

export function AdminAddUser(props) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(!props.user ? ADD_USER : UPDATE_USER);
  const removeUser = useMutation(REMOVE_USER);

  const [firstName, setFirstName] = React.useState(props.user ? props.user.firstName : null);
  const [lastName, setLastName] = React.useState(props.user ? props.user.lastName : null);
  const [email, setEmail] = React.useState(props.user ? props.user.email : null);
  const [type, setType] = React.useState(props.user ? props.user.type : null);

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            type
          }
        }
      })
      if (response.data.addUser) {
        notify(setGlobal, 'Added User', 'success');
      }
    } catch (e) {
      notify(setGlobal, e.graphQLErrors[0].message, 'error');
    }
  }

  async function update() {
    try {
      const response: any = await mutation({
        variables: {
          input: {
            id: props.user.id,
            firstName,
            lastName,
            email,
            type
          }
        }
      })
      if (response.data.updateUser) {
        notify(setGlobal, 'Saved User', 'success');
      }
    } catch (e) {
      notify(setGlobal, e.graphQLErrors[0].message, 'error');
    }
  }

  async function remove() {
    try {
      const response: any = await removeUser({
        variables: {
          id: props.user.id
        }
      })
      if (response.data.removeUser) {
        notify(setGlobal, 'Removed User', 'success');
      }
    } catch (e) {
      notify(setGlobal, e.graphQLErrors[0].message, 'error');
    }
  }

  const buttons = props.user
    ? [
        { label: 'Delete User', type: 'delete', onClick: remove },
        { label: 'Save User', onClick: update },
      ]
    : [
        { label: 'Add User', onClick: save }
    ]

  return (
    <AdminScreen
      headline={`${props.user ? "Edit" : "Add"} User`}
      buttons={buttons}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        label="First Name"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Padding>
                    <Padding all={2}>
                      <TextInput
                        label="Last Name"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Padding>
                    <Padding all={2}>
                        <TextInput
                          label="Email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                    </Padding>
                    <Padding all={2}>
                      <StyledLabel>Type</StyledLabel>
                      <StyledSelect 
                          name="type"
                          value={type}
                          onChange={e => setType(e.target.value)}>
                          <option value="">Select a type</option>
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                      </StyledSelect>
                    </Padding>
                    <Padding all={2}>
                        {props.user
                          ? <Link to="/reset-password">Reset password</Link>
                          : "The user will receive an email to activate their account and set a password."
                        }
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function AdminAddUserLoader(props) {
  return <AdminAddUser history={props.history} />;
}

export function AdminEditUserLoader(props) {
  const { data, loading, error } = useQuery(LOAD_USER, {
    variables: {
      id: props.match.params.user
    }
  })

  if (loading || !data) return null;
  if (error) return null;

  return <AdminAddUser history={props.history} user={data.user} />;
}

export default AdminAddUserLoader;