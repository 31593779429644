import * as React from 'react';

import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge,
} from '../../components/typography';

import LadderStepAdmin from '../brand/ladders/components/loyality.step.admin';
import LadderHeader from '../brand/ladders/components/loyality.header';
import SideBar from '../../components/navigation/sidebar';
import { getLoyaltyLadder } from '../../data';
import {
  ButtonHollow,
  DownloadButton,
  DownloadButtonContainer,
} from '../../components/buttons';
import uuid from 'uuid/v4';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from './affinity.ladder';
import { useQuery, useMutation } from 'react-apollo-hooks';
import AdminScreen from '../../components/admin/containers/screen';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import * as store from 'store';
import { LOAD_BRAND_LOYALTYLADDER, UPDATE_DOC } from '../../utils/gql';

interface AffinityLadderProps {
  brand?: any;
  data?: any;
}

export function AdminLoyaltyLadder(props: AffinityLadderProps) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(UPDATE_DOC);
  
  const buttons = [
    {
      label: 'Save Loyalty Ladder',
      onClick: save,
    },
  ];

  const [steps, setSteps] = React.useState(
    props.brand.loyaltyLadder.contents || []
  );

  function save() {
    try {
      mutation({
        variables: {
          input: {
            id: props.brand.loyaltyLadder.id,
            type: props.brand.loyaltyLadder.type,
            contents: JSON.stringify(steps),
          },
        },
      });

      notify(setGlobal, 'Saved Loyalty Ladder');
    } catch (e) {}
  }

  function addStep() {
    setSteps((steps) => {
      return [...steps, { id: uuid() }];
    });
  }

  function removeStep(index) {
    setSteps((steps) => {
      steps.splice(index, 1);
      return [...steps];
    });
  }

  function changeLadderField(index, key, value) {
    setSteps((steps) => {
      steps[index][key] = value;
      return [...steps];
    });
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setSteps((steps) => {
      return reorder(steps, result.source.index, result.destination.index);
    });
  }

  return (
    <AdminScreen
      headline={'Loyalty Ladder'}
      buttons={buttons}
      >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <ParagraphLarge>
                        Identifies key points of customer contact and what each
                        means relative to the job they perform in the core
                        consumer’s life.
                      </ParagraphLarge>
                    </Padding>
                  </Grid.Unit>
                </Grid>
                <LadderHeader />
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {steps.map((step, i) => {
                          return (
                            <LadderStepAdmin
                              onChangeField={changeLadderField}
                              remove={removeStep}
                              key={step.id}
                              index={i}
                              {...step}
                            />
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Padding>
              <Padding style={{ textAlign: 'center' }} all={2} bottom={4}>
                <ButtonHollow onClick={addStep}>Add Step</ButtonHollow>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function AdminLoyaltyLadderLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND_LOYALTYLADDER, {
    variables: {
      id: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <AdminLoyaltyLadder
      data={[{ id: '1', touch: '', what: '', does: '' }]}
      brand={data.brand}
    />
  );
}

export default AdminLoyaltyLadderLoader;
