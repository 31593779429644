import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  HeadlineFour,
  Paragraph,
  QuoteBlue,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { LinkList, LinkListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';
import {
  ButtonHollow,
  DownloadButton,
  DownloadButtonContainer,
} from '../../components/buttons';
import { getConsumerFindings, getStakeholderFindings } from '../../data';
import SVGInline from 'react-svg-inline';
import breakpoint from 'styled-components-breakpoint';
import { StyledInput as StyledTextArea } from '../../components/admin/inputs/textarea';
import TextInput from '../../components/admin/inputs/text';
import AdminScreen from '../../components/admin/containers/screen';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { Themes } from './consumer.findings';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import CSVInput from '../../components/inputs/csv';
import * as store from 'store';
import { LOAD_BRAND_STAKEHOLDERFINDINGS, PARSE_THEMES, UPDATE_DOC } from '../../utils/gql';

interface ConsumerFindingsScreenProps {
  brand: any;
  findings: any;
}

export const ThemesGridUnit = styled(Grid.Unit)`
  background-color: #f7fbfd;
`;

export const ThemeHeadline = styled(HeadlineTwo)`
  color: #536976;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 17px;
  margin: 0;
`;

export const TextAreaInput = styled(StyledTextArea)`
  border: 1px solid #d7d7d836;
  background: #f6f3f038;
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  height: 140px;
`;

export function ConsumerFindingsScreen(props: ConsumerFindingsScreenProps) {
  const mutation = useMutation(UPDATE_DOC);
  
  const buttons = [
    {
      label: 'SAVE STAKEHOLDER FINDINGS',
      onClick: save,
    },
  ];

  const [themes, setThemes] = React.useState(
    props.findings.contents.themes || [
      { name: 'New Theme', description: '', quotes: [] },
    ]
  );

  const [findings, setFindings] = React.useState(props.findings.contents || {});
  const [global, setGlobal] = useGlobal();

  function save() {
    try {
      mutation({
        variables: {
          input: {
            id: props.brand.stakeholderFindings.id,
            type: props.brand.stakeholderFindings.type,
            contents: JSON.stringify({ ...findings, themes }),
          },
        },
      });

      notify(setGlobal, 'Saved Stakeholder Findings');
    } catch (e) {}
  }

  function changeField(key, value) {
    return setFindings((findings) => ({ ...findings, [key]: value }));
  }

  const parse = useMutation(PARSE_THEMES);

  async function onUpload(file) {
    const response: any = await parse({ variables: { file } });

    setThemes(response.data.parseThemes);
  }

  return (
    <AdminScreen
      headline={'STAKEHOLDER FINDINGS'}
      buttons={buttons}
    >
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1 }} />
                </Grid>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>PURPOSE</HeadlineTwo>
                      <Divider />
                      <TextAreaInput
                        value={findings.purpose}
                        onChange={(e) => changeField('purpose', e.target.value)}
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 8 / 12 }}>
                    <Padding all={3}>
                      <HeadlineTwo>METHODOLOGY</HeadlineTwo>
                      <Divider />
                      <Grid>
                        <Grid.Unit size={{ sm: 1, md: 8 / 12 }}>
                          <HeadlineFour>INTERVIEWEES</HeadlineFour>
                          <TextAreaInput
                            value={findings.interviewees}
                            onChange={(e) =>
                              changeField('interviewees', e.target.value)
                            }
                          />
                        </Grid.Unit>

                        <Grid.Unit size={{ sm: 1, md: 4 / 12 }}>
                          <Padding left={{ md: 3 }}>
                            <HeadlineFour>DURATION</HeadlineFour>
                            <TextAreaInput
                              value={findings.duration}
                              onChange={(e) =>
                                changeField('duration', e.target.value)
                              }
                            />
                          </Padding>
                        </Grid.Unit>
                      </Grid>
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Themes themes={themes} onUpload={onUpload} setThemes={setThemes} />
    </AdminScreen>
  );
}

export const ArrowContainer = styled.span`
  cursor: pointer;
  svg {
    fill: #536976;
  }
`;

export const ThemesHeadline = styled(HeadlineTwo)`
  span {
    margin-top: 2px;
  }

  ${breakpoint('md')`
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }
  `}
`;

export const ParagraphQuote = styled(Paragraph)`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const ParagraphDescription = styled(Paragraph)`
  font-size: 16px;
  margin-bottom: 24px;
  color: #222222;
`;

export function AdminConsumerFindingsLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND_STAKEHOLDERFINDINGS, {
    variables: {
      id: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  const findings = JSON.parse(
    JSON.stringify(data.brand.stakeholderFindings).split(' .').join('.')
  );

  return (
    <ConsumerFindingsScreen
      findings={findings}
      brand={data.brand}
    />
  );
}

export default AdminConsumerFindingsLoader;
