import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const DateInputClosed = styled.div`
  padding: 12px 18px 12px 12px;
  background: #ffffff;
  border: 1px solid #5454543b;
  border-radius: 4px;
  cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')}
  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)}
  position: relative;
  text-align:center;
` as any;

const Arrow = styled.span`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 10px;
  top: 19px;
  opacity: 0.7;
`;

const ArrowDown = styled(Arrow)`
  transform: rotate(45deg);
`;

const ArrowUp = styled(Arrow)`
  transform: rotate(-45deg);
`;

const DateInputOpen = styled.div`
  position: relative;
  z-index: 9999;
`;

const DateInputOpenMenu = styled.div`
  position: absolute;
  background: #fff;
  z-index: 99999;
  border: 1px solid #5454543b;
  border-radius: 4px;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1rem;
    line-height: 1.2rem;
    font-size: 0.8rem;
  }

  text-align: left;
  padding: 8px;
`;

const DateMenuQuickOptions = styled.ul`
  list-style: none;
  margin: 8px 0;
  padding: 0;
`;

const DateMenuQuickOption = styled.li`
  color: #545454;
  font-size: 600;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
`;

const DateValueLabel = styled.span`
  color: #545454;
  font-size: 13px;
  width: 100%;
  text-align: center;
  margin-right: 6px;
`;

const DateMenuLabel = styled.span`
  display: block;
  color: #000;
`;

const DateMenuOtherLabel = styled.span`
  display: block;
  color: #545454;
  font-size: 600;
  opacity: 0.5;
  font-size: 12px;
  margin: 8px 0;
`;

const options = [
  {
    value: moment().format('YYYY-MM-DD'),
    label: 'Today',
  },
  {
    value: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    label: '3 Months Past',
  },
  {
    value: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    label: '6 Months Past',
  },
  {
    value: moment().subtract(12, 'months').format('YYYY-MM-DD'),
    label: '1 Year Past',
  },
  {
    value: moment().subtract(24, 'months').format('YYYY-MM-DD'),
    label: '2 Year Past',
  },
];

function getOptions(hasRelativeOptions, base, today, options) {
  if (!hasRelativeOptions) {
    return [
      {
        value: moment(today, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        label: `${today}`,
        subLabel: null,
      },
    ];
  }

  if (options) return options;

  return [
    {
      value: moment(today, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      label: `${today}`,
      subLabel: null,
    },
    {
      value: moment(base).subtract(3, 'months').format('YYYY-MM-DD'),
      label: '3 Months Past',
      subLabel: null,
    },
    {
      value: moment(base).subtract(6, 'months').format('YYYY-MM-DD'),
      label: '6 Months Past',
      subLabel: null,
    },
    {
      value: moment(base).subtract(12, 'months').format('YYYY-MM-DD'),
      label: '1 Year Past',
      subLabel: null,
    },

    {
      value: moment(base).subtract(24, 'months').format('YYYY-MM-DD'),
      label: '2 Years Past',
      subLabel: null,
    },
  ];
}

function getDisplayLabel(date, base, today) {
  if (!date) return 'Select Date';

  const opt = getOptions(true, base, today, null).find((o) => o.value === date);

  if (opt) {
    return opt.label;
  }

  return date;
}

export function DateInput(props: any) {
  const node = useRef(null);
  const { inputProps } = props;

  const [open, setOpen] = useState(false);

  const display = getDisplayLabel(
    props.inputProps.value,
    props.base,
    props.today
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, []);

  if (props.disabled === true) {
    return (
      <DateInputClosed
        className="date-input-closed"
        disabled={props.disabled}
        onClick={(e) => (props.disabled ? null : setOpen(true))}
      >
        <DateValueLabel>{props.display || display}</DateValueLabel>
      </DateInputClosed>
    );
  }

  if (!open) {
    return (
      <DateInputClosed
        className="date-input-closed"
        disabled={props.disabled}
        onClick={(e) => (props.disabled ? null : setOpen(true))}
      >
        <DateValueLabel>{props.display || display}</DateValueLabel>
        <ArrowDown />
      </DateInputClosed>
    );
  }

  function onChange(date) {
    props.inputProps.onChange(moment(date).format('YYYY-MM-DD'));
    setOpen(false);
  }

  function handleClick(e) {
    if (node.current && node.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  }

  function renderQuickOptions(opt) {
    return (
      <DateMenuQuickOption onClick={(e) => onChange(opt.value)} key={opt.key}>
        {opt.label}{' '}
        {opt.subLabel && <span style={{ fontSize: 8 }}>{opt.subLabel}</span>}
      </DateMenuQuickOption>
    );
  }

  return (
    <DateInputOpen ref={node}>
      <DateInputClosed
        className="date-input-closed"
        disabled={props.disabled}
        onClick={(e) => (props.disabled ? null : setOpen(true))}
      >
        <DateValueLabel>{display}</DateValueLabel>
        <ArrowDown />
      </DateInputClosed>
      <DateInputOpenMenu>
        <DateMenuLabel>{props.label}</DateMenuLabel>
        <DateMenuQuickOptions>
          {getOptions(
            props.hasRelativeOptions,
            props.base,
            props.today,
            props.options
          ).map(renderQuickOptions)}
        </DateMenuQuickOptions>

        <DateMenuOtherLabel>OR CHOOSE A DATE</DateMenuOtherLabel>
        <DatePicker
          maxDate={
            props.type === 'base'
              ? moment(props.max, 'YYYY-MM-DD').toDate()
              : moment(props.base, 'YYYY-MM-DD').toDate()
          }
          minDate={props.min ? moment(props.min, 'YYYY-MM-DD').toDate() : null}
          dateFormat="YYYY-MM-DD"
          showTimeSelect={false}
          selected={moment(
            props.inputProps.value ||
              moment(props.max || props.base, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ),
            'YYYY-MM-DD'
          ).toDate()}
          onChange={onChange}
          inline
        />
      </DateInputOpenMenu>
    </DateInputOpen>
  );
}

export default DateInput;
