import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { AlignCenter } from '../../components/containers/align';
import { OrderedList, OrderedListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';
import { getBrands } from '../../data';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo-hooks';
import AdminScreen from '../../components/admin/containers/screen';

import * as store from 'store';
import { LOAD_BRANDS } from '../../utils/gql';

export const SegmentTitle = styled(HeadlineThree)``;
export const SegmentText = styled(Paragraph)``;

export const StoryUniversePlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background: #f3f3f3;
`;

interface DashboardProps {
  brands: any;
}

export const BrandGridUnit = styled(Grid.Unit)`
  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    min-height: 200px;
  }
`;

export function Brand(props) {
  return (
    <BrandGridUnit size={{ sm: 3 / 12 }}>
      <Padding all={2}>
        <Box>
          <Padding all={2}>
            <Grid>
              <Grid.Unit size={{ sm: 12 / 12, md: 12 / 12 }}>
                <Padding all={3} style={{ textAlign: 'center' }}>
                  <Link to={`/brands/${props.id}`}>
                    <div style={{position: "relative", height: 0, paddingBottom: "100%"}}>
                      <img src={`${process.env.REACT_APP_API || 'http://localhost:3000'}/${props.logo}`} style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "contain"}} />
                    </div>
                  </Link>
                </Padding>
              </Grid.Unit>
              <Grid.Unit size={{ sm: 12 / 12, md: 12 / 12 }}>
                <Padding all={2} style={{ textAlign: 'center' }}>
                  <Link to={`/brands/${props.id}`}>
                    <HeadlineTwo>{props.name}</HeadlineTwo>
                  </Link>
                </Padding>
              </Grid.Unit>
            </Grid>
          </Padding>
        </Box>
      </Padding>
    </BrandGridUnit>
  );
}

export function ChooseBrand(props: DashboardProps) {

  const buttons = store.get('type') === 'admin'
    ? [
        { label: "ADD BRAND", link: "/admin/add-brand" },
        { label: "USERS", link: "/admin/users" },
        { label: "ACCOUNT", link: "/account" },
      ]
    : [
        { label: "ACCOUNT", link: "/account" },
      ]

  return (
    <AdminScreen
      headline="Your Brands"
      buttons={buttons}
    >
      <Grid>
        {props.brands.map(brand => {
          return (
            <Brand
              name={brand.name}
              logo={brand.logo}
              id={brand.id}
              key={brand.id}
            />
          );
        })}
      </Grid>
    </AdminScreen>
  );
}

export function ChooseBrandLoader(props: any) {
  const { data, error, loading } = useQuery(LOAD_BRANDS);

  if (error) return null;
  if (loading || !data) return null;

  return <ChooseBrand brands={data.brands} />;
}

export default ChooseBrandLoader;
