import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadInput from '../../components/inputs/upload';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import * as store from 'store';
import { LOAD_BRAND_DOWNLOADS, UPDATE_BRAND_DOWNLOADS } from '../../utils/gql';

export function AdminAccount(props) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(UPDATE_BRAND_DOWNLOADS);

  const [stakeholderFindings, setStakeholderFindings] = React.useState(props.brand.downloads.stakeholderFindings);
  const [consumerFindings, setConsumerFindings] = React.useState(props.brand.downloads.consumerFindings);
  const [customerActivationCycle, setCustomerActivationCycle] = React.useState(props.brand.downloads.customerActivationCycle);
  const [loyaltyArchitecture, setLoyaltyArchitecture] = React.useState(props.brand.downloads.loyaltyArchitecture);
  const [loyaltyBusinessPlan, setLoyaltyBusinessPlan] = React.useState(props.brand.downloads.loyaltyBusinessPlan);
  const [brandBook, setBrandBook] = React.useState(props.brand.downloads.brandBook);

  const buttons = [
    {
      label: 'Save Downloads',
      onClick: save,
    },
  ];

  async function save() {
    try {
      const response: any = await mutation({
        variables: {
          id: props.brand.id,
          input: {
            stakeholderFindings,
            consumerFindings,
            customerActivationCycle,
            loyaltyArchitecture,
            loyaltyBusinessPlan,
            brandBook,
          },
        },
      });
      if (response.data.updateBrandDownloads) {
        notify(setGlobal, 'Saved Downloads');
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <AdminScreen
      headline={'Downloads'}
      buttons={buttons}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <UploadInput
                        value={stakeholderFindings}
                        onChange={setStakeholderFindings}
                        label="Stakeholder Findings"
                      />
                    </Padding>
                    <Padding all={2}>
                      <UploadInput
                        value={consumerFindings}
                        onChange={setConsumerFindings}
                        label="Consumer Findings"
                      />
                    </Padding>
                    <Padding all={2}>
                      <UploadInput
                        value={customerActivationCycle}
                        onChange={setCustomerActivationCycle}
                        label="Customer Activation Cycle"
                      />
                    </Padding>
                    <Padding all={2}>
                      <UploadInput
                        value={loyaltyArchitecture}
                        onChange={setLoyaltyArchitecture}
                        label="Loyalty Architecture"
                      />
                    </Padding>
                    <Padding all={2}>
                      <UploadInput
                        value={loyaltyBusinessPlan}
                        onChange={setLoyaltyBusinessPlan}
                        label="Curriculum Homework"
                      />
                    </Padding>
                    <Padding all={2}>
                      <UploadInput
                        value={brandBook}
                        onChange={setBrandBook}
                        label="Brand Compass"
                      />
                    </Padding>
                  </Grid.Unit>
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function AdminAccountLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND_DOWNLOADS, {
    variables: {
      id: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <AdminAccount
      history={props.history}
      brand={data.brand}
    />
  );
}

export default AdminAccountLoader;
