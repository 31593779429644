import React, { Component } from 'react';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import styled from 'styled-components';
import SVGInline from 'react-svg-inline';
import breakpoint from 'styled-components-breakpoint';
import { StyledInput as StyledTextArea } from '../../../../components/admin/inputs/textarea';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface LadderStepProps {
  touch: any;
  does: any;
  what: any;
  onChangeField: any;
  index: number;
  id?: string;
  remove?: any;
}

export const ContainerBlue = styled.div`
  background: #f7fbfd;
  width: 100%;
`;

export const BlueGridUnit = styled(Grid.Unit)`
  background: #f7fbfd;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

export const GridUnit = styled(Grid.Unit)`
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

export const LadderStepGrid = styled(Grid)`
  margin-bottom: 4px;
  position: relative;
`;

export const TouchPoint = styled.div`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 18px;
  width: 100%;

  span {
    text-align: center;
    display: inherit;
  }
`;

export const WhatItDoes = styled.div`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 18px;
  width: 100%;

  span {
    text-align: center;
    display: inherit;
  }
`;

export const WhatItIs = styled.div`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 18px;
  width: 100%;

  span {
    text-align: center;
    display: inherit;
  }

  textarea {
    color: #657c89;
    font-family: proxima-nova, sans-serif;
    font-size: 14px;
    line-height: 18px;
    background: transparent;
  }
`;

export const Arrow = styled.div`
  svg {
    width: 30px;
    position: absolute;
    right: -15px;
    top: calc(50% - 12px);
    fill: #d9ebf4;
    stoke: #d9ebf4;
    z-index: 999;

    ${breakpoint('xs', 'md')`
      transform: rotate(90deg);
      top: calc(100% - 15px);
      right: calc(10% - 12px);
    `}
  }
`;

export const TextAreaInput = styled(StyledTextArea)`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 18px;
  background: transparent;
  border-color: #d9ebf4;
`;

export const Remove = styled.div`
  background: #fff;
  display: flex;
  position: absolute;
  width: 32px;
  height: 32px;
  top: calc(50% - 16px);
  left: -16px;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  z-index: 9999;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    .a {
      stroke: #657c89;
    }
  }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 0 0`,

  // styles we need to apply on draggables
  ...draggableStyle
});

export function LadderStepAdmin(props: LadderStepProps) {
  return (
    <Draggable
      key={props.index}
      draggableId={`draggable-${props.index}`}
      index={props.index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}>
          <LadderStepGrid>
            <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
              <Padding style={{ width: '100%' }} all={4}>
                <TouchPoint>
                  <TextAreaInput
                    value={props.touch || ''}
                    onChange={e => {
                      props.onChangeField(props.index, 'touch', e.target.value);
                      e.stopPropagation();
                      return false;
                    }}
                    onMouseDown={e => e.stopPropagation()}
                    onMouseUp={e => e.stopPropagation()}
                  />
                </TouchPoint>
              </Padding>
              <Arrow>
                <SVGInline
                  svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#71B9CA;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>keyboard-arrow-right</title><line class="a" x1="23.25" y1="12.497" x2="0.75" y2="12.497"></line><polyline class="a" points="19.5 16.247 23.25 12.497 19.5 8.747"></polyline></svg>`}
                />
              </Arrow>
            </BlueGridUnit>
            <GridUnit size={{ sm: 1, md: 6 / 12 }}>
              <Padding style={{ width: '100%' }} all={4}>
                <WhatItDoes>
                  <TextAreaInput
                    value={props.does || ''}
                    onChange={e =>
                      props.onChangeField(props.index, 'does', e.target.value)
                    }
                  />
                </WhatItDoes>
                <Arrow>
                  <SVGInline
                    svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#71B9CA;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>keyboard-arrow-right</title><line class="a" x1="23.25" y1="12.497" x2="0.75" y2="12.497"></line><polyline class="a" points="19.5 16.247 23.25 12.497 19.5 8.747"></polyline></svg>`}
                  />
                </Arrow>
              </Padding>
            </GridUnit>
            <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
              <ContainerBlue>
                <Padding all={4}>
                  <WhatItIs>
                    <TextAreaInput
                      value={props.what || ''}
                      onChange={e =>
                        props.onChangeField(props.index, 'what', e.target.value)
                      }
                    />
                  </WhatItIs>
                </Padding>
              </ContainerBlue>
            </BlueGridUnit>
            <Remove onClick={e => props.remove(props.index)}>
              <SVGInline
                svg={`<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><defs><style>.a{fill:none;stroke:#71B9CA;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}</style></defs><title>bin</title><path class="a" d="M17.25,21H6.75a1.5,1.5,0,0,1-1.5-1.5V6h13.5V19.5A1.5,1.5,0,0,1,17.25,21Z"></path><line class="a" x1="9.75" y1="16.5" x2="9.75" y2="10.5"></line><line class="a" x1="14.25" y1="16.5" x2="14.25" y2="10.5"></line><line class="a" x1="2.25" y1="6" x2="21.75" y2="6"></line><path class="a" d="M14.25,3H9.75a1.5,1.5,0,0,0-1.5,1.5V6h7.5V4.5A1.5,1.5,0,0,0,14.25,3Z"></path></svg>`}
              />
            </Remove>
          </LadderStepGrid>
        </div>
      )}
    </Draggable>
  );
}

export default LadderStepAdmin;
