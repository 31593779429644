import moment from 'moment';

export function makePeriodsCompare(term, base, compare) {
  const terms = {
    '1-year': 1,
    '2-year': 2,
  };

  const periodOneStart = moment(base, 'YYYY-MM-DD')
    .subtract(terms[term], 'years')
    .format('YYYY-MM-DD');

  const periodOneEnd = moment(base, 'YYYY-MM-DD').format('YYYY-MM-DD');

  const periodTwoStart = moment(compare, 'YYYY-MM-DD')
    .subtract(terms[term], 'years')
    .format('YYYY-MM-DD');

  const periodTwoEnd = moment(compare, 'YYYY-MM-DD').format('YYYY-MM-DD');

  const periodThreeStart = moment()
    .subtract(terms[term] * 3, 'years')
    .format('YYYY-MM-DD');

  const periodThreeEnd = moment()
    .subtract(terms[term] * 2, 'years')
    .format('YYYY-MM-DD');

  return {
    periodOneStart,
    periodOneEnd,
    periodTwoStart,
    periodTwoEnd,
    periodThreeStart,
    periodThreeEnd,
  };
}
