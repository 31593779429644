export enum SEGMENTS {
  PROSPECTS = 'SEGMENT_PROSPECTS',
  CASUAL = 'SEGMENT_CASUAL',
  LOYALIST = 'SEGMENT_LOYALIST',
  CHEERLEADER = 'SEGMENT_CHEERLEADER',
  LOSTSOULS = 'SEGMENT_LOSTSOULS',
}

export interface ISegmentMetrics {
  users: number;
  usersFormatted: string;
  usersAsPercentageOfTotal: number;
  usersAsPercentageOfTotalFormatted: number;
  count: number;
  countFormatted: string;
  countPercentOfTotal: number;
  countPercentOfTotalFormatted: string;
  averageIndividualValue: number;
  averageIndividualValueFormatted: string;
  cogsPerUser: number;
  cogsPerUserFormatted: string;
  costPerUser: number;
  costPerUserFormatted: string;
  marginPerUser: number;
  marginPerUserFormatted: string;
  netRevenue: number;
  netRevenueFormatted: string;
  netRevenuePerUser: number;
  netRevenuePerUserFormatted: string;
  revenue: number;
  revenueFormatted: string;
  revenueAsPercentageOfTotal: number;
  revenueAsPercentageOfTotalFormatted: string;
  marketingSpend: number;
  marketingSpendFormatted: string;
  marketingSpendPercentOfTotal: number;
  marketingSpendPercentOfTotalFormatted: string;
}

export interface ISegmentMigration {
  to: {
    lostsouls: number;
    prospects: number;
    casuals: number;
    loyalists: number;
    cheerleaders: number;
  };
  from: {
    lostsouls: number;
    prospects: number;
    casuals: number;
    loyalists: number;
    cheerleaders: number;
  };
  stayed: {
    lostsouls: number;
    prospects: number;
    casuals: number;
    loyalists: number;
    cheerleaders: number;
  };
}

export interface ISegmentInsight {
  text: string;
  negative: boolean;
  level: number;
}

export interface ISegmentGrade {
  name: string;
}

export interface ISegmentScore {
  total: number;
  totalFormatted: number;
  insights: ISegmentInsight[];
  optimizations: ISegmentInsight[];
  grades: ISegmentGrade[];
}

export interface ISegmentGrowth {
  usersPercentChange: number;
  userPercentChangeFormatted: string;
  usersTotalChange: number;
  userTotalChangeFormatted: string;
  revenuePercentChange: number;
  revenuePercentChangeFormatted: string;
  revenueTotalChange: number;
  revenueTotalChangeFormatted: string;
  averageIndividualValuePercentChange: number;
  averageIndividualValuePercentChangeFormatted: string;
  averageIndividualValueTotalChange: number;
  averageIndividualValueTotalChangeFormatted: string;
  costPerUserPercentChange: number;
  costPerUserPercentChangeFormatted: string;
  costPerUserTotalChange: number;
  costPerUserTotalChangeFormatted: string;
  cogsPerUserPercentChange: number;
  cogsPerUserPercentChangeFormatted: string;
  cogsPerUserTotalChange: number;
  cogsPerUserTotalChangeFormatted: string;
  netRevenuePerUserPercentChange: number;
  netRevenuePerUserPercentChangeFormatted: string;
  netRevenuePerUserTotalChange: number;
  netRevenuePerUserTotalChangeFormatted: string;
  netRevenuePercentChange: number;
  netRevenuePercentChangeFormatted: string;
  netRevenueTotalChange: number;
  netRevenueTotalChangeFormatted: string;
}

export interface ISegmentProspect {
  enabled: boolean;
  change: number;
  currentPeriod: number;
}

export interface ISegmentMovement {
  migrationForward: number;
  migrationForwardKey: string;
  migrationBackward: number;
  migrationBackwardKey: string;
  migrationOut: number;
  migrationIn: number;
  stayed: number;
}

export interface ISegmentData {
  segment: {
    id: string;
  };
  abreviation: string;
  name: string;
  score: number;
  current: ISegmentMetrics;
  previous: ISegmentMetrics;
  migration: ISegmentMigration;
  scores: ISegmentScore;
  growth: ISegmentGrowth;
  movement: ISegmentMovement;
  prospectData?: ISegmentProspect;
}

export interface IQuantData {
  SEGMENT_CASUALS: ISegmentData;
  SEGMENT_PROSPECTS: ISegmentData;
  SEGMENT_LOYALISTS: ISegmentData;
  SEGMENT_CHEERLEADERS: ISegmentData;
  lastDataRecordDate: Date;
  firstDataRecordDate: Date;
}
