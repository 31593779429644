export default {};
import * as React from 'react';
import {
  insightsCountAsPercentage,
  insightsMigrationForward,
  insightsRevenueAsPercentage,
} from './common';

export function insightsLoyalistsCountAsPercentage(
  benchmark: number,
  value: number,
  score: number
): string | any {
  return insightsCountAsPercentage(benchmark, value, score);
}

export function insightsLoyalistsRevenueAsPercentage(
  benchmark: number,
  value: number,
  score: number
): string | any {
  return insightsRevenueAsPercentage(benchmark, value, score);
}

export function insightsLoyalistsMigrationForward(
  benchmark: number,
  value: number,
  score: number
): string | any {
  return insightsMigrationForward(benchmark, value, score);
}

export function insightsLoyalistsIndValue(
  benchmark: number,
  value: number,
  score: number
): string | any {
  if (value > benchmark) {
    return [
      {
        text: 'Individual Value above standard',
        short: 'Individual Value',
        negative: false,
      },
    ];
  } else {
    return [
      {
        text: 'Individual Value below standard',
        short: 'Individual Value',
        negative: true,
      },
    ];
  }
}
