import { getPositionOnRadius } from './util';

const SPHERE_RAIDUS = 120;

export function plotLevelOne(node) {
  const { index } = node;
  const { x, y } = { x: 0, y: 0 };

  switch (index) {
    case 0: {
      node.position = {
        ...getPositionOnRadius(50, SPHERE_RAIDUS, x, y)
      };
      break;
    }

    case 1: {
      node.position = {
        ...getPositionOnRadius(180, SPHERE_RAIDUS + 10, x, y)
      };
      break;
    }

    case 2: {
      node.position = {
        ...getPositionOnRadius(300, SPHERE_RAIDUS, x, y)
      };
      break;
    }
  }

  node.textPosition = {
    x: node.position.x - 30,
    y: node.position.y - 30,
    width: 60,
    height: 60,
    html: true
  };

  return node;
}

export default plotLevelOne;
