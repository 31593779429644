export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const formatterInt = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatterDollars = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatterCounts = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export const formatterPercentShort = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

export const formatterPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // currency: 'USD'
});

export const formatterPercentLong = new Intl.NumberFormat('en-US', {
  style: 'percent',
  // minimumFractionDigits: 2,
  maximumFractionDigits: 3,
  // currency: 'USD'
});

const formatterScore = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

export const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default {
  count: formatterCounts,
  percent: formatterPercent,
  currency: formatter,
};
