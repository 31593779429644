import React from 'react';
import styled from 'styled-components';
import { useGlobal } from 'reactn';
import { Loader } from '../../../../components/loader';
import { ISegmentData } from '../../../../metrics/enum';

export const SegmentDebugPanelContainer = styled.div`
  font-family: proxima-nova, sans-serif;
  color: #545454;

  .segment-grade {
    margin-bottom: 8px;
    padding: 8px;
    background: #f4fafd;
  }

  table {
    width: 100%;
    
    th {
      text-align: left;
      margin-right: 16px;
      padding: 4px 16px;
    }

    td {
      margin-right: 16px;
      padding: 8px 16px;
    }

    td {
      border-top: 1px solid #38627c;
    }

    td:nth-child(2) {font-weight: bold;}
    
    td:nth-child(1) {width: 600px;}

    tr:nth-child(even) {background: #eff4f7}
    tr:nth-child(odd) {background: #FFF}
  }
`;

export function SegmentDebugPanel({ segment }) {
  const [quantDataLoading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (quantDataLoading) {
    return (
      <SegmentDebugPanelContainer>
        <Loader />
      </SegmentDebugPanelContainer>
    );
  }

  const data: ISegmentData = quantData[segment.type];

  if (!data) {
    return null;
  }

  function renderGrade(grade, i) {
    return (
      <div key={i} className="segment-grade">
        {grade.name}
      </div>
    );
  }

  return (
    <SegmentDebugPanelContainer>
      {(data.scores.grades || []).map(renderGrade)}
    </SegmentDebugPanelContainer>
  );
}
