import stakeholderFindings from './obermeyer.stakeholders';
import consumerFindings from './obermeyer.consumers';

const brands = {
  'a74d9fd8-4a80-11e9-8646-d663bd873d93': {
    name: 'Obermeyer',
    logo: '/static/logo.svg'
  }
  /*'a74da2e4-4a80-11e9-8646-d663bd873d93': {
    name: 'Compass & Nail',
    logo: '/static/logo.png'
  }*/
};

const loyaltyLadders = {
  'a74d9fd8-4a80-11e9-8646-d663bd873d93': [
    {
      id: '1',
      path: `Need defined`,
      how: `Individual or family skiing event in any weather`,
      what: `Requirement for self, kids, dad, even mom to be warm, dry especially, comfortable (meaning range of motion), and look good (meaning look unique)`
    },
    {
      id: '2',
      path: `Discover Obermeyer`,
      how: `Via friend, significant other, parent

      Via presence in Aspen
      
      Via exposure to Klaus`,
      what: `Comes with reputation that
      "it works"`
    },
    {
      id: '3',
      path: `Research online`,
      how: `Visits Obermeyer website`,
      what: `To get full context of the product and brand and to make a transaction requires a visit to a store or an alternative online shopping experience`
    },
    {
      id: '4',
      path: `Visit a store`,
      how: `Via local mountain or a destination resort`,
      what: `Access is limited`
    },
    {
      id: '5',
      path: `Experience detail of the product`,
      how: `Via in store experience`,
      what: `Details, fabrics, finish, price, and style combination net a unique value

      Color variety enables unique identity; i.e. 
      self-expression`
    },
    {
      id: '6',
      path: `Makes first purchase`,
      how: `Via store, third party etailer, or Amazon depending on opportunity, what's most convenient`,
      what: `Opportunity, efficiency, ease of transaction, via trusted source is limited`
    },
    {
      id: '7',
      path: `Use product`,
      how: `On mountain themselves or observes kids use`,
      what: `It works: 
      Freedom of movement, stays dry, little details make life on the mountain easy, warm, looks good
      
      Engenders faith in Obermeyer as a thoughtful designer that understands
      
      Lessens pain of logistics of skiing`
    },
    {
      id: '8',
      path: `Continues use`,
      how: `Over time`,
      what: `Demonstrates construction holds up, colors are fast, waterproofness sustained, overall durability high

      Engenders faith in Obermeyer as a manufacturer`
    },
    {
      id: '9',
      path: `Purchases additional products`,
      how: `Need based

      Via a desire for durable, functional gear head to toe
      
      Outfit family
      
      Outfit self`,
      what: `Concludes Obermeyer is a unique solution specifically winning on fit, durability, style choices, affordabiility

      With Obermeyer
       "I can afford to ski, and/or travel, and/or outfit everyone"`
    },
    {
      id: '10',
      path: `Has a service experience`,
      how: `Via a required service on product or limited service experience via a third party etailer or retailer`,
      what: `Concludes Obermeyer stands behind their goods and over delivers in terms of customer satisfaction

      Concludes Obermeyer are good people, a unique find`
    },
    {
      id: '11',
      path: `Recommend to family, friends`,
      how: `Product satisfaction

      Service satisfaction
      
      Both exceed expectations`,
      what: `Integrity of product and service uniquely defines Obermeyer as a thoughtful company that cares`
    },
    {
      id: '12',
      path: `Pass along product to family and friends`,
      how: `As kids grow out of product and adults replace existing product with new styles`,
      what: `Recapitulates value of durability

      Enables individual to act on values of not being wasteful, helping others`
    },
    {
      id: '13',
      path: `Seek new styles`,
      how: `Visits local mountain retailer, destination resort, third party etailer, or Amazon`,
      what: `Color, design variety is valued to individualize, customize look, and keep things fresh`
    },
    {
      id: '14',
      path: `Seek more ways to engage in Obermeyer brand`,
      how: `Via content, reuse / repair / recycle network, via Obermeyer website, social media, local mountain services`,
      what: `Obermeyer concluded to be a full service product provider and point of inspiration and enablement of the core value of skiing`
    }
  ]
};

const affinityLadders = {
  'a74d9fd8-4a80-11e9-8646-d663bd873d93': [
    {
      id: '1',
      touch: 'Supply Chain',
      what: 'The origin of product',
      does: `Indicates whether a company is responsible socially (Fair Trade) or environmentally (Impact/Footprint) or supports local business (Made in the US)`
    },
    {
      id: '2',
      touch: 'Gear',
      what: `Required accoutrement

      Outerwear jackets and pants, gloves, sweaters, skis, poles, boots, helmets, base layers, cameras, phones, etc.`,
      does: `Enables fun`
    },
    {
      id: '3',
      touch: 'Competitors',
      what: `Limited set`,
      does: `Demonstrates that very few brands are able to provide function, attention to detail, optimal fit, ranges of color and style at a relatively competitive price`
    },
    {
      id: '3',
      touch: 'Stores',
      what: `Primary point of access

      Physical access to touch, try on, and purchase product 
      
      In some instances gain expert opinion or guidance`,
      does: `Builds confidence in choice

      Confirms fit
      
      Creates opportunity for direct compare and contrast`
    },
    {
      id: '4',
      touch: 'Customer Service',
      what: `Proxy for third party service

      Source for solutions to problems`,
      does: `Endears the brand to the customer

      Creates trust 
      
      Surprises 
      
      Engenders loyalty to Obermeyer`
    },
    {
      id: '5',
      touch: 'The Obermeyer Website',
      what: `A reference point to research product`,
      does: `Informs about product

      Adds complexity and slows the path to purchase
      
      Engenders frustration
      
      Limits brand access, lost opportunity to engage interested audience`
    },
    {
      id: '6',
      touch: 'The Internet',
      what: `A vast resource for research

      Brand, features, benefits, price, style comparison tool`,
      does: `The great helper

      Creates speedy, efficient, easy access to all things
      
      Improves awareness and knowledge`
    },
    {
      id: '7',
      touch: 'Sport',
      what: `Skiing`,
      does: `Gets the individual outside

      Creates peace of mind, solace, balance, connection
      
      A means to enjoy time with family, kids`
    },
    {
      id: '8',
      touch: 'Family',
      what: `A lifestyle`,
      does: `Dictates everything`
    },
    {
      id: '9',
      touch: 'Outside',
      what: `Skiing, golfing, hiking, walking, tennis, cycling`,
      does: `Gets individual, kids, family outside

      Opportunity to spend quality time together
      
      Provides life perspective`
    },
    {
      id: '10',
      touch: `Local Mountain`,
      what: `Immediate, daily, weekly access to skiing, being outside

      Local community`,
      does: `Enables at least weekly experience connecting to the bigger context of being outside

      Creates sense of belonging`
    },
    {
      id: '11',
      touch: `Details`,
      what: `Fabric, stitching, bells & whistles, pockets, finish, zippers, cuffs, etc.`,
      does: `Demonstrates someone was thoughtful, cares, and is similarly conscientious

      Demostrates creativity
      
      Creates value`
    },
    {
      id: '12',
      touch: `Color`,
      what: `Source of individualized style`,
      does: `Allows for the individual to be self expressive… "to be seen"`
    },
    {
      id: '13',
      touch: `Fit`,
      what: `Range of consistent and accommodating sizes for "real bodies"`,
      does: `Enables freedom of movement

      Improves experience
      
      Allows for body acceptance`
    },
    {
      id: '14',
      touch: `Social Media`,
      what: `Facebook, Instagram, etc.`,
      does: `Access to friends and family

      Source of information about brands and destinations`
    },
    {
      id: '15',
      touch: `Klaus`,
      what: `The founder and origin/source of the brand

      The reason for being`,
      does: `Demonstrates integrity

      Relates passion for the sport, the mountains, and the outdoors
      
      Reminder that skiing is fun
      
      Inspires`
    },
    {
      id: '16',
      touch: `Destinations`,
      what: `Places to ski away from local mountain`,
      does: `Enables newness, exploration, discovery, freshness of experience, and dedicated time with family`
    },
    {
      id: '17',
      touch: `Product`,
      what: `Jackets, pants, sweaters`,
      does: `Protects from the elements

      Provides comfort, warmth, dryness
      
      Outer skin means of self expression, personal style
      
      Creates sense of uniqueness
      
      Delivers functional style criteria at a relatively competitive price`
    },
    {
      id: '18',
      touch: `Discounts`,
      what: `A requirement to make life work`,
      does: `Enables the stretching of dollars to enable activities with family`
    },
    {
      id: '19',
      touch: `Sweaters`,
      what: `Extended pre-ski and après-ski wear`,
      does: `Extends the ski experience off the mountain`
    }
  ]
};

const segments = {
  'a74d9fd8-4a80-11e9-8646-d663bd873d93': {
    prospect: {
      id: 'prospect',
      name: 'PROSPECTS',
      description: `Prospects are practical, family oriented, fun-loving enthusiasts of skiing and year around outdoor activities such as cycling, hiking, golf, and tennis. They construct their lives so they can maximize time enjoyed outside in community. Balancing responsibilities and the logistics of daily life is hard. The Sherpa-like effort procuring and managing the associated accoutrement with any outdoor pursuit is also daunting, but spending a day freewheeling with family, kids, and friends is antidote to it all, and well worth it for Prospects. As result Prospects seek product that lessens the discomfort, expense, and complications of the entirety of the endeavor as a means to get to what truly matters.`,
      attributes: `Seeking a unique value to outfit themselves or another family member`,
      goals: `Clearly demonstrate intent to make skiing, life better by making both easier... Define and demonstrate the concept of win-win`,
      process: ` Impart performance algorithm: complicated made easy, uncomfortable made comfortable, serious made light- hearted, expensive made affordable, short- lived made to last—this an introduction of the founding philosophies`,
      insights: `“Life is good when things work.”`,

      triggersKeyExperience: `Understand the performance algorithm`,
      triggersDiscovery: `Word of mouth via hand-me-downs, recommendations, or a visit to a mountain retail shop`,
      triggersTouchpoint: `1. Wholesale (events) 2. Homepage 3. Customer Service`,

      tacticalComms: `Skiing made easy
        Uncomfortable made comfortable
        Expensive made affordable
      Short-lived, made to last`,

      callsToAction: `Read a testimonial
        See philosophy: life is good when things work
        Buy one thing`
    },
    casual: {
      id: 'casual',
      name: 'CASUALS',
      description: `Casuals are prudent. Their investment in gear is tangible to their ability to maximize quality time on the mountain and access to desired experiences and destinations. They plan, shop, and buy for themselves and their loved ones. They are hard on their gear and their kids are even more destructive; and yet, they expect their gear to stand up to the rigor. Casuals definevalue on thoughtfulness of design, fit, comfort, and attention to details, including aesthetics. Only when the performance of a product meets a lengthy list of expectations will Casuals deem a product a worthy value relative to its price.`,
      attributes: `Hoping to realize real value`,
      goals: `Define quality demonstrating/ showing why Obermeyer is a prudent investment`,
      process: ` Make life go right: easy access, concierge service, kids love it, “fits like a glove,” affordable, warm, dry, little surprises that make the experience better`,
      insights: `“Obermeyer is uniquely worth it.”`,

      triggersKeyExperience: `Experience Obermeyer is a prudent investment`,
      triggersDiscovery: `Positive product performance realized, fit, and durability confirmed`,
      triggersTouchpoint: `1.Outfit 2. pages / Bundles 3. eMail Social Media`,

      tacticalComms: `Value defined (A New Standard)
        Quality defined (Value Algorithm and Solving Problems
        Cast Iron Guarantee`,

      callsToAction: `See the list that makes up the value algorithm
        Read the guarantee
        Make a second purchase`
    },
    cheerleader: {
      id: 'cheerleaders',
      name: 'CHEERLEADERS',
      description: `The love of skiing is integral to Cheerleaders as is the pursuit of happiness. They grew up in the sport and endeavor to ski for a lifetime, sharing it with their loved ones and passing it and a love of the outdoors along to their chi ldren. They crave being on the mountain and revel in camaraderie and community. They believe in the adage the best skier is the one with the biggest smile on their face. The pursuit of joy, connection to self and others, and happiness overthrows any inkling of striving for perfection or the top of a podium. Anything disruptive to this pursuit is insufferable, anything advancing the cause invaluable. They see Obermeyer distincily as a partner in this quest.`,
      attributes: `Sharing the stoke`,
      goals: `Relate Obermeyer’s pursuit of happiness, further ingrain Klaus’ philosophies`,
      process: `Own the Obermeyer ethos. Link the founding inspiration to the company’s daily activity and the commitment to the principles of win-win, imparting hope by being outside, sharing skiing with families, integrity, the joie de vivre, and finally, and most importantly, that skiing is fun.`,
      insights: `“Obermeyer is championing happy.”`,

      triggersKeyExperience: `Understand the philosophy of happiness`,
      triggersDiscovery: `Sharing the value algorithm… "Obermeyer is uniquely worth it."`,
      triggersTouchpoint: `1. eMail 2. Events 3. Shared stories`,

      tacticalComms: `Share the love of skiing: smile
        Skiing is fun
        Family, friends, kids matter`,
      callsToAction: `Invite a friend
        Share a story: an experience of easy, skiing is fun`
    },
    loyalist: {
      id: 'loyalist',
      name: 'LOYALISTS',
      description: `Loyalists are conscientious and budget-minded. They are enamored with companies who have proven their performance over time. Color fastness, waterproofness, seam integrity, and overall durability are tested over years. As result, they have great appreciation for Obermeyer’s design process and longevity thinking, as it helps them get the most out of life by stretching their dollar, responsibly, even sustainably. The gear they use has come to represent an experience central to how they self-identify. Loyalists support those companies that understand the realities of life and provide the opportunity to make a seemingly elitist sport accessible and fun, for everyone.`,
      attributes: `Balancing priorities`,
      goals: `Radical transparency`,
      process: `Demonstrate intent to provide
      performance standards, design quality, sustainability practices, and aesthetics in a package that dispels the concept of exclusivity and invites the concept of community`,
      insights: `“Everything they do makes my experience of the sport better.”`,

      triggersKeyExperience: `Understand idea that skiing is for everyone`,
      triggersDiscovery: `Discovery of culture, Klaus, and integrity doing the right thing`,
      triggersTouchpoint: `Resort rental program
          Website: renew/recycle program
          Customer Service`,

      tacticalComms: `Everyone deserves access to great gear
        It works, looks good, and is built with integrity
        Expensive made affordable
        Obermeyer is intent to make getting outside easier and the sport better: win-win`,

      callsToAction: `1. Rent 2. Outfit the family 3.Buy a bundle`
    }
  }
};

export function getBrands() {
  return Object.keys(brands).map(key => {
    return { ...brands[key], id: key };
  });
}

export function getBrand(brand) {
  return brands[brand];
}

export function getConsumerFindings(brand) {
  return consumerFindings[brand];
}

export function getStakeholderFindings(brand) {
  return stakeholderFindings[brand];
}

export function getSegment(brand, id) {
  return segments[brand][id];
}

export function getSegments(brand) {
  return Object.keys(segments[brand]).map(key => segments[brand][key]);
}

export function getLoyaltyLadder(brand) {
  return loyaltyLadders[brand];
}

export function getAffinityLadder(brand) {
  return affinityLadders[brand];
}

export function getDownloads(brands) {}
