import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const HeadlineOne = styled.h1`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-size: 40px;
  letter-spacing: 1px;
  line-height: 28px;
  margin: ${(props: any) =>
    props.dataSection === true ? '0px' : '30px 0 0px 0'}

  font-size: 28px;
  color: #38627c;
  letter-spacing: 0.7px;
` as any;

export const HeadlineTwo = styled.h2`
  color: #425663;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
  margin-bottom: 0.5rem;
  margin-top: 0;

  span {
    font-size: 0.825rem;
    color: #545454;

    ${breakpoint('xs', 'sm')`
      display: block;
      width: 100%;
    `}
  }
`;

export const HeadlineThree = styled.h3`
  color: #8794a0;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 14px;
  text-align: center;
`;

export const HeadlineFour = styled.h3`
  color: #71b9ca;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin: 0;
  margin-bottom: 4px;
`;

export const QuoteGrey = styled.p`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
`;

export const QuoteBlue = styled(QuoteGrey)`
  color: #545454;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.color || '#545454'};
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 0.5rem;
  margin-top: 0;

  a {
    color: #509bb0;
    text-decoration: none;
  }
`;

export const ParagraphLarge = styled.p`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
`;

/*
Proxima Nova Bold

font-family: proxima-nova, sans-serif;

font-weight: 700;

font-style: normal;


Proxima Nova Bold Italic

font-family: proxima-nova, sans-serif;

font-weight: 700;

font-style: italic;


Proxima Nova Regular

font-family: proxima-nova, sans-serif;

font-weight: 400;

font-style: normal;


Proxima Nova Italic

font-family: proxima-nova, sans-serif;

font-weight: 400;

font-style: italic;


Proxima Nova Condensed Bold

font-family: proxima-nova-condensed, sans-serif;

font-weight: 700;

font-style: normal;


Proxima Nova Condensed Bold Italic

font-family: proxima-nova-condensed, sans-serif;

font-weight: 700;

font-style: italic;


Proxima Nova Condensed Regular

font-family: proxima-nova-condensed, sans-serif;

font-weight: 400;

font-style: normal;


Proxima Nova Condensed Italic

font-family: proxima-nova-condensed, sans-serif;

font-weight: 400;

font-style: italic;


Proxima Nova Extra Condensed Bold Italic

font-family: proxima-nova-extra-condensed, sans-serif;

font-weight: 700;

font-style: italic;


Proxima Nova Extra Condensed Bold

font-family: proxima-nova-extra-condensed, sans-serif;

font-weight: 700;

font-style: normal;


Proxima Nova Extra Condensed Italic

font-family: proxima-nova-extra-condensed, sans-serif;

font-weight: 400;

font-style: italic;


Proxima Nova Extra Condensed Regular

font-family: proxima-nova-extra-condensed, sans-serif;

font-weight: 400;

font-style: normal;
*/
