import { IQuantData, ISegmentData, SEGMENTS } from '../enum';
import { scoreCasuals } from '../formulas/casuals';
import { scoreCheerleaders } from '../formulas/cheerleaders';
import { scoreLoyalists } from '../formulas/loyalists';
import { scoreProspects } from '../formulas/prospects';

// export const STANDARD_SCALE1 = [
//   { low: 0.0000, high: 0.0125, value: 0 },
//   { low: 0.0125, high: 0.0250, value: 5 },
//   { low: 0.0250, high: 0.0375, value: 10 },
//   { low: 0.0375, high: 0.0500, value: 15 },
//   { low: 0.0500, high: 0.0625, value: 20 },
//   { low: 0.0625, high: 0.0750, value: 25 },
//   { low: 0.0750, high: 0.0875, value: 30 },
//   { low: 0.0875, high: 0.1000, value: 35 },
//   { low: 0.1000, high: 0.1125, value: 40 },
//   { low: 0.1125, high: 0.1250, value: 45 },
//   { low: 0.1250, high: 0.1375, value: 50 },
//   { low: 0.1375, high: 0.1500, value: 55 },
//   { low: 0.1500, high: 0.1625, value: 60 },
//   { low: 0.1625, high: 0.1750, value: 65 },
//   { low: 0.1750, high: 0.1875, value: 70 },
//   { low: 0.1875, high: 0.2000, value: 75 },
//   { low: 0.2000, high: 0.2125, value: 80 },
//   { low: 0.2125, high: 0.2250, value: 85 },
//   { low: 0.2250, high: 0.2375, value: 90 },
//   { low: 0.2375, high: 0.2500, value: 95 },

//   { low: 0.2500, high: 0.2625, value: 100 },
//   { low: 0.2625, high: 0.2750, value: 105 },
//   { low: 0.2750, high: 0.2875, value: 110 },
//   { low: 0.2875, high: 0.3000, value: 115 },
//   { low: 0.3000, high: 0.3125, value: 120 },
//   { low: 0.3125, high: 0.3250, value: 125 },
//   { low: 0.3250, high: 0.3375, value: 130 },
//   { low: 0.3375, high: 0.3500, value: 135 },
//   { low: 0.3500, high: 0.3625, value: 140 },
//   { low: 0.3625, high: 0.3750, value: 145 },
//   { low: 0.3750, high: 0.3875, value: 150 },
//   { low: 0.3875, high: 0.4000, value: 155 },
//   { low: 0.4000, high: 0.4125, value: 160 },
//   { low: 0.4125, high: 0.4250, value: 165 },
//   { low: 0.4250, high: 0.4375, value: 170 },
//   { low: 0.4375, high: 0.4500, value: 175 },
//   { low: 0.4500, high: 0.4625, value: 180 },
//   { low: 0.4625, high: 0.4750, value: 185 },
//   { low: 0.4750, high: 0.4875, value: 190 },
//   { low: 0.4875, high: 0.5000, value: 195 },
  
//   { low: 0.5000, high: 0.5100, value: 200 },
//   { low: 0.5100, high: 0.5200, value: 205 },
//   { low: 0.5200, high: 0.5300, value: 210 },
//   { low: 0.5300, high: 0.5400, value: 215 },
//   { low: 0.5400, high: 0.5500, value: 220 },
//   { low: 0.5500, high: 0.5600, value: 225 },
//   { low: 0.5600, high: 0.5700, value: 230 },
//   { low: 0.5700, high: 0.5800, value: 235 },
//   { low: 0.5800, high: 0.5900, value: 240 },
//   { low: 0.5900, high: 0.6000, value: 245 },
//   { low: 0.6000, high: 0.6100, value: 250 },
//   { low: 0.6100, high: 0.6200, value: 255 },
//   { low: 0.6200, high: 0.6300, value: 260 },
//   { low: 0.6300, high: 0.6400, value: 265 },
//   { low: 0.6400, high: 0.6500, value: 270 },
//   { low: 0.6500, high: 0.6600, value: 275 },
//   { low: 0.6600, high: 0.6700, value: 280 },
//   { low: 0.6700, high: 0.6800, value: 285 },
//   { low: 0.6800, high: 0.6900, value: 290 },
//   { low: 0.6900, high: 0.7000, value: 295 },

//   { low: 0.7000, high: 0.7075, value: 300 },
//   { low: 0.7075, high: 0.7150, value: 305 },
//   { low: 0.7150, high: 0.7225, value: 310 },
//   { low: 0.7225, high: 0.7300, value: 315 },
//   { low: 0.7300, high: 0.7375, value: 320 },
//   { low: 0.7375, high: 0.7450, value: 325 },
//   { low: 0.7450, high: 0.7525, value: 330 },
//   { low: 0.7525, high: 0.7600, value: 335 },
//   { low: 0.7600, high: 0.7675, value: 340 },
//   { low: 0.7675, high: 0.7750, value: 345 },
//   { low: 0.7750, high: 0.7825, value: 350 },
//   { low: 0.7825, high: 0.7900, value: 355 },
//   { low: 0.7900, high: 0.7975, value: 360 },
//   { low: 0.7975, high: 0.8050, value: 365 },
//   { low: 0.8050, high: 0.8125, value: 370 },
//   { low: 0.8125, high: 0.8200, value: 375 },
//   { low: 0.8200, high: 0.8275, value: 380 },
//   { low: 0.8275, high: 0.8350, value: 385 },
//   { low: 0.8350, high: 0.8425, value: 390 },
//   { low: 0.8425, high: 0.8500, value: 395 },

//   { low: 0.8500, high: 0.8575, value: 400 },
//   { low: 0.8575, high: 0.8650, value: 405 },
//   { low: 0.8650, high: 0.8725, value: 410 },
//   { low: 0.8725, high: 0.8800, value: 415 },
//   { low: 0.8800, high: 0.8875, value: 420 },
//   { low: 0.8875, high: 0.8950, value: 425 },
//   { low: 0.8950, high: 0.9025, value: 430 },
//   { low: 0.9025, high: 0.9100, value: 435 },
//   { low: 0.9100, high: 0.9175, value: 440 },
//   { low: 0.9175, high: 0.9250, value: 445 },
//   { low: 0.9250, high: 0.9325, value: 450 },
//   { low: 0.9325, high: 0.9400, value: 455 },
//   { low: 0.9400, high: 0.9475, value: 460 },
//   { low: 0.9475, high: 0.9550, value: 465 },
//   { low: 0.9550, high: 0.9625, value: 470 },
//   { low: 0.9625, high: 0.9700, value: 475 },
//   { low: 0.9700, high: 0.9775, value: 480 },
//   { low: 0.9775, high: 0.9850, value: 485 },
//   { low: 0.9850, high: 0.9925, value: 490 },
//   { low: 0.9925, high: 1.0000, value: 495 },
//   { low: 1.0000, high: 100.00, value: 500 },
// ]

// export const STANDARD_SCALE2 = [
//   { low: 0.0000, high: 0.0125, value: 0 },
//   { low: 0.0125, high: 0.0250, value: 5 },
//   { low: 0.0250, high: 0.0375, value: 10 },
//   { low: 0.0375, high: 0.0500, value: 15 },
//   { low: 0.0500, high: 0.0625, value: 20 },
//   { low: 0.0625, high: 0.0750, value: 25 },
//   { low: 0.0750, high: 0.0875, value: 30 },
//   { low: 0.0875, high: 0.1000, value: 35 },
//   { low: 0.1000, high: 0.1125, value: 40 },
//   { low: 0.1125, high: 0.1250, value: 45 },
//   { low: 0.1250, high: 0.1375, value: 50 },
//   { low: 0.1375, high: 0.1500, value: 55 },
//   { low: 0.1500, high: 0.1625, value: 60 },
//   { low: 0.1625, high: 0.1750, value: 65 },
//   { low: 0.1750, high: 0.1875, value: 70 },
//   { low: 0.1875, high: 0.2000, value: 75 },
//   { low: 0.2000, high: 0.2125, value: 80 },
//   { low: 0.2125, high: 0.2250, value: 85 },
//   { low: 0.2250, high: 0.2375, value: 90 },
//   { low: 0.2375, high: 0.2500, value: 95 },

//   { low: 0.2500, high: 0.2625, value: 100 },
//   { low: 0.2625, high: 0.2750, value: 105 },
//   { low: 0.2750, high: 0.2875, value: 110 },
//   { low: 0.2875, high: 0.3000, value: 115 },
//   { low: 0.3000, high: 0.3125, value: 120 },
//   { low: 0.3125, high: 0.3250, value: 125 },
//   { low: 0.3250, high: 0.3375, value: 130 },
//   { low: 0.3375, high: 0.3500, value: 135 },
//   { low: 0.3500, high: 0.3625, value: 140 },
//   { low: 0.3625, high: 0.3750, value: 145 },
//   { low: 0.3750, high: 0.3875, value: 150 },
//   { low: 0.3875, high: 0.4000, value: 155 },
//   { low: 0.4000, high: 0.4125, value: 160 },
//   { low: 0.4125, high: 0.4250, value: 165 },
//   { low: 0.4250, high: 0.4375, value: 170 },
//   { low: 0.4375, high: 0.4500, value: 175 },
//   { low: 0.4500, high: 0.4625, value: 180 },
//   { low: 0.4625, high: 0.4750, value: 185 },
//   { low: 0.4750, high: 0.4875, value: 190 },
//   { low: 0.4875, high: 0.5000, value: 195 },
  
//   { low: 0.5000, high: 0.5125, value: 200 },
//   { low: 0.5125, high: 0.5250, value: 205 },
//   { low: 0.5250, high: 0.5375, value: 210 },
//   { low: 0.5375, high: 0.5500, value: 215 },
//   { low: 0.5500, high: 0.5625, value: 220 },
//   { low: 0.5625, high: 0.5750, value: 225 },
//   { low: 0.5750, high: 0.5875, value: 230 },
//   { low: 0.5875, high: 0.6000, value: 235 },
//   { low: 0.6000, high: 0.6125, value: 240 },
//   { low: 0.6125, high: 0.6250, value: 245 },
//   { low: 0.6250, high: 0.6375, value: 250 },
//   { low: 0.6375, high: 0.6500, value: 255 },
//   { low: 0.6500, high: 0.6625, value: 260 },
//   { low: 0.6625, high: 0.6750, value: 265 },
//   { low: 0.6750, high: 0.6875, value: 270 },
//   { low: 0.6875, high: 0.7000, value: 275 },
//   { low: 0.7000, high: 0.7125, value: 280 },
//   { low: 0.7125, high: 0.7250, value: 285 },
//   { low: 0.7250, high: 0.7375, value: 290 },
//   { low: 0.7375, high: 0.7500, value: 295 },

//   { low: 0.7500, high: 0.7625, value: 300 },
//   { low: 0.7625, high: 0.7750, value: 305 },
//   { low: 0.7750, high: 0.7875, value: 310 },
//   { low: 0.7875, high: 0.8000, value: 315 },
//   { low: 0.8000, high: 0.8125, value: 320 },
//   { low: 0.8125, high: 0.8250, value: 325 },
//   { low: 0.8250, high: 0.8375, value: 330 },
//   { low: 0.8375, high: 0.8500, value: 335 },
//   { low: 0.8500, high: 0.8625, value: 340 },
//   { low: 0.8625, high: 0.8750, value: 345 },
//   { low: 0.8750, high: 0.8875, value: 350 },
//   { low: 0.8875, high: 0.9000, value: 355 },
//   { low: 0.9000, high: 0.9125, value: 360 },
//   { low: 0.9125, high: 0.9250, value: 365 },
//   { low: 0.9250, high: 0.9375, value: 370 },
//   { low: 0.9375, high: 0.9500, value: 375 },
//   { low: 0.9500, high: 0.9625, value: 380 },
//   { low: 0.9625, high: 0.9750, value: 385 },
//   { low: 0.9750, high: 0.9875, value: 390 },
//   { low: 0.9875, high: 1.0000, value: 395 },

//   { low: 1.0000, high: 1.0125, value: 400 },
//   { low: 1.0125, high: 1.0250, value: 405 },
//   { low: 1.0250, high: 1.0375, value: 410 },
//   { low: 1.0375, high: 1.0500, value: 415 },
//   { low: 1.0500, high: 1.0625, value: 420 },
//   { low: 1.0625, high: 1.0750, value: 425 },
//   { low: 1.0750, high: 1.0875, value: 430 },
//   { low: 1.0875, high: 1.1000, value: 435 },
//   { low: 1.1000, high: 1.1125, value: 440 },
//   { low: 1.1125, high: 1.1250, value: 445 },
//   { low: 1.1250, high: 1.1375, value: 450 },
//   { low: 1.1375, high: 1.1500, value: 455 },
//   { low: 1.1500, high: 1.1625, value: 460 },
//   { low: 1.1625, high: 1.1750, value: 465 },
//   { low: 1.1750, high: 1.1875, value: 470 },
//   { low: 1.1875, high: 1.2000, value: 475 },
//   { low: 1.2000, high: 1.2125, value: 480 },
//   { low: 1.2125, high: 1.2250, value: 485 },
//   { low: 1.2250, high: 1.2375, value: 490 },
//   { low: 1.2375, high: 1.2500, value: 495 },
//   { low: 1.2500, high: 100.00, value: 500 },
// ]

export const STANDARD_SCALE = [
  { low: 0.0000, high: 0.0150, value: 0 },
  { low: 0.0150, high: 0.0300, value: 5 },
  { low: 0.0300, high: 0.0450, value: 10 },
  { low: 0.0450, high: 0.0600, value: 15 },
  { low: 0.0600, high: 0.0750, value: 20 },
  { low: 0.0750, high: 0.0900, value: 25 },
  { low: 0.0900, high: 0.1050, value: 30 },
  { low: 0.1050, high: 0.1200, value: 35 },
  { low: 0.1200, high: 0.1350, value: 40 },
  { low: 0.1350, high: 0.1500, value: 45 },
  { low: 0.1500, high: 0.1650, value: 50 },
  { low: 0.1650, high: 0.1800, value: 55 },
  { low: 0.1800, high: 0.1950, value: 60 },
  { low: 0.1950, high: 0.2100, value: 65 },
  { low: 0.2100, high: 0.2250, value: 70 },
  { low: 0.2250, high: 0.2400, value: 75 },
  { low: 0.2400, high: 0.2550, value: 80 },
  { low: 0.2550, high: 0.2700, value: 85 },
  { low: 0.2700, high: 0.2850, value: 90 },
  { low: 0.2850, high: 0.3000, value: 95 },

  { low: 0.3000, high: 0.3150, value: 100 },
  { low: 0.3150, high: 0.3300, value: 105 },
  { low: 0.3300, high: 0.3450, value: 110 },
  { low: 0.3450, high: 0.3600, value: 115 },
  { low: 0.3600, high: 0.3750, value: 120 },
  { low: 0.3750, high: 0.3900, value: 125 },
  { low: 0.3900, high: 0.4050, value: 130 },
  { low: 0.4050, high: 0.4200, value: 135 },
  { low: 0.4200, high: 0.4350, value: 140 },
  { low: 0.4350, high: 0.4500, value: 145 },
  { low: 0.4500, high: 0.4650, value: 150 },
  { low: 0.4650, high: 0.4800, value: 155 },
  { low: 0.4800, high: 0.4950, value: 160 },
  { low: 0.4950, high: 0.5100, value: 165 },
  { low: 0.5100, high: 0.5250, value: 170 },
  { low: 0.5250, high: 0.5400, value: 175 },
  { low: 0.5400, high: 0.5550, value: 180 },
  { low: 0.5550, high: 0.5700, value: 185 },
  { low: 0.5700, high: 0.5850, value: 190 },
  { low: 0.5850, high: 0.6000, value: 195 },
  
  { low: 0.6000, high: 0.6100, value: 200 },
  { low: 0.6100, high: 0.6200, value: 205 },
  { low: 0.6200, high: 0.6300, value: 210 },
  { low: 0.6300, high: 0.6400, value: 215 },
  { low: 0.6400, high: 0.6500, value: 220 },
  { low: 0.6500, high: 0.6600, value: 225 },
  { low: 0.6600, high: 0.6700, value: 230 },
  { low: 0.6700, high: 0.6800, value: 235 },
  { low: 0.6800, high: 0.6900, value: 240 },
  { low: 0.6900, high: 0.7000, value: 245 },
  { low: 0.7000, high: 0.7100, value: 250 },
  { low: 0.7100, high: 0.7200, value: 255 },
  { low: 0.7200, high: 0.7300, value: 260 },
  { low: 0.7300, high: 0.7400, value: 265 },
  { low: 0.7400, high: 0.7500, value: 270 },
  { low: 0.7500, high: 0.7600, value: 275 },
  { low: 0.7600, high: 0.7700, value: 280 },
  { low: 0.7700, high: 0.7800, value: 285 },
  { low: 0.7800, high: 0.7900, value: 290 },
  { low: 0.7900, high: 0.8000, value: 295 },

  { low: 0.8000, high: 0.8050, value: 300 },
  { low: 0.8050, high: 0.8100, value: 305 },
  { low: 0.8100, high: 0.8150, value: 310 },
  { low: 0.8150, high: 0.8200, value: 315 },
  { low: 0.8200, high: 0.8250, value: 320 },
  { low: 0.8250, high: 0.8300, value: 325 },
  { low: 0.8300, high: 0.8350, value: 330 },
  { low: 0.8350, high: 0.8400, value: 335 },
  { low: 0.8400, high: 0.8450, value: 340 },
  { low: 0.8450, high: 0.8500, value: 345 },
  { low: 0.8500, high: 0.8550, value: 350 },
  { low: 0.8550, high: 0.8600, value: 355 },
  { low: 0.8600, high: 0.8650, value: 360 },
  { low: 0.8650, high: 0.8700, value: 365 },
  { low: 0.8700, high: 0.8750, value: 370 },
  { low: 0.8750, high: 0.8800, value: 375 },
  { low: 0.8800, high: 0.8850, value: 380 },
  { low: 0.8850, high: 0.8900, value: 385 },
  { low: 0.8900, high: 0.8950, value: 390 },
  { low: 0.8950, high: 0.9000, value: 395 },

  { low: 0.9000, high: 0.9050, value: 400 },
  { low: 0.9050, high: 0.9100, value: 405 },
  { low: 0.9100, high: 0.9150, value: 410 },
  { low: 0.9150, high: 0.9200, value: 415 },
  { low: 0.9200, high: 0.9250, value: 420 },
  { low: 0.9250, high: 0.9300, value: 425 },
  { low: 0.9300, high: 0.9350, value: 430 },
  { low: 0.9350, high: 0.9400, value: 435 },
  { low: 0.9400, high: 0.9450, value: 440 },
  { low: 0.9450, high: 0.9500, value: 445 },
  { low: 0.9500, high: 0.9550, value: 450 },
  { low: 0.9550, high: 0.9600, value: 455 },
  { low: 0.9600, high: 0.9650, value: 460 },
  { low: 0.9650, high: 0.9700, value: 465 },
  { low: 0.9700, high: 0.9750, value: 470 },
  { low: 0.9750, high: 0.9800, value: 475 },
  { low: 0.9800, high: 0.9850, value: 480 },
  { low: 0.9850, high: 0.9900, value: 485 },
  { low: 0.9900, high: 0.9950, value: 490 },
  { low: 0.9950, high: 1.0000, value: 495 },
  { low: 1.0000, high: 100.00, value: 500 },
]

export const PROSPECT_SCORING_BENCHMARKS = {
  MIGRATION_FORWARD: 0.015,
};

export const CASUAL_SCORING_BENCHMARKS = {
  MIGRATION_FORWARD: 0.15,
  COUNT_AS_PERCENTAGE: 0.7,
  REVENUE_AS_PERCENTAGE: 0.33,
  ACTIVATION_COST: 1,
  CONVERSION_COST: 1,
};

export const LOYALIST_SCORING_BENCHMARKS = {
  MIGRATION_FORWARD: 0.2,
  COUNT_AS_PERCENTAGE: 0.2,
  REVENUE_AS_PERCENTAGE: 0.33,
  ACTIVATION_COST: 1,
  IND_VALUE: 3,
};

export const CHEERLEADER_SCORING_BENCHMARKS = {
  MIGRATION_FORWARD: 0.8,
  COUNT_AS_PERCENTAGE: 0.1,
  REVENUE_AS_PERCENTAGE: 0.33,
  ACTIVATION_COST: 1,
  IND_VALUE: 12,
};

export function scoreProspectSegment(
  segment: ISegmentData,
  data: IQuantData,
  brand: any
) {
  return scoreProspects(
    segment,
    data,
    brand,
    STANDARD_SCALE,
    PROSPECT_SCORING_BENCHMARKS
  );
}

export function scoreCasualSegment(
  segment: ISegmentData,
  data: IQuantData,
  brand: any
) {
  return scoreCasuals(
    segment,
    data,
    brand,
    STANDARD_SCALE,
    CASUAL_SCORING_BENCHMARKS
  );
}

export function scoreLoyalistSegment(
  segment: ISegmentData,
  data: IQuantData,
  brand
) {
  return scoreLoyalists(
    segment,
    data,
    brand,
    STANDARD_SCALE,
    LOYALIST_SCORING_BENCHMARKS
  );
}

export function scoreCheerleaderSegment(
  segment: ISegmentData,
  data: IQuantData,
  brand: any
) {
  return scoreCheerleaders(
    segment,
    data,
    brand,
    STANDARD_SCALE,
    CHEERLEADER_SCORING_BENCHMARKS
  );
}

export function getColorForScore(score) {
  if (score > 4) {
    return '#574080';
  }

  if (score > 3) {
    return '#715E94';
  }

  if (score > 2) {
    return '#9C8FB4';
  }

  if (score > 1) {
    return '#BBB3CC';
  }

  if (score > 0) {
    return '#DDD9E6';
  }

  return '#DDD9E6';
}

export function getTextColorForScore(score) {}
