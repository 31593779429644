import * as React from 'react';
import styled from 'styled-components';

export const TextInputWrapper = styled.div`
  margin: 0 0 16px 0;
`;

export const StyledInput = styled.select`
  // width: 100%;
  min-width: 150px;
  padding: 12px 8px;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #d7d7d8;
  font-family: proxima-nova, sans-serif;
  text-align: right;

  :focus {
    border: 1px solid #509bb0;
    outline: none;
  }
`;

export const StyledLabel = styled.label`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 6px 0;
  display: block;
`;

export default function SelectInput(props) {
  function renderOption(opt) {
    return (
      <option key={opt.value} value={opt.value}>
        {opt.label}
      </option>
    );
  }

  return (
    <TextInputWrapper style={props.style || {}}>
      {props.label ? <StyledLabel>{props.label}</StyledLabel> : null}
      <StyledInput
        placeholder={props.placeholder}
        {...(props.inputProps || {})}>
        {(props.options || []).map(renderOption)}
      </StyledInput>
    </TextInputWrapper>
  );
}
