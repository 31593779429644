export const theme = {
  colors: {},
  typography: {},
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1220,
  },
};

export default theme;
