import React from 'react';

export const MigrationOvalLeft = ({ percent }) => {
  return (
    <span
      style={{
        width: 65,
        height: 56,
        display: 'inline-flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{ position: 'absolute', left: 0, top: 0, width: 65, height: 56 }}
      >
        <svg
          width="65px"
          height="56px"
          viewBox="0 0 65 56"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Retain-Oval" transform="translate(-9.000000, 0.000000)">
              <g id="Group" transform="translate(2.000000, 0.000000)">
                <path
                  d="M35,1 C45.0136052,1 56.8890532,10.1353558 70.7347499,28.0023885 C56.8875872,45.865772 45.0129871,55 35,55 C27.5441559,55 20.7941559,51.9779221 15.9081169,47.0918831 C11.0220779,42.2058441 8,35.4558441 8,28 C8,20.5441559 11.0220779,13.7941559 15.9081169,8.90811691 C20.7941559,4.02207794 27.5441559,1 35,1 Z"
                  id="Oval-Copy-4"
                  stroke="#D9EBF4"
                  strokeWidth="2"
                  fill="#FFFFFF"
                  transform="translate(39.500000, 28.000000) scale(-1, 1) translate(-39.500000, -28.000000) "
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <span
        style={{
          zIndex: 100,
          fontSize: 11,
          marginLeft: 9,
          color: '#509bb0',
          fontWeight: 'bold',
        }}
      >
        {percent}
      </span>
    </span>
  );
};

export const MigrationOvalLeftKey = (props: any) => {
  const width = props.width || '18.5px';
  const height = props.height || '16px';

  // return (
  //   <svg
  //     width={width}
  //     height={height}
  //     viewBox="0 0 65 56"
  //     version="1.1"
  //     xmlns="http://www.w3.org/2000/svg"
  //     style={{ marginRight: 8 }}
  //   >
  //     <g
  //       id="Symbols"
  //       stroke="none"
  //       strokeWidth="3"
  //       fill="none"
  //       fillRule="evenodd"
  //     >
  //       <g id="Retain-Oval" transform="translate(-9.000000, 0.000000)">
  //         <g id="Group" transform="translate(2.000000, 0.000000)">
  //           <path
  //             d="M35,1 C45.0136052,1 56.8890532,10.1353558 70.7347499,28.0023885 C56.8875872,45.865772 45.0129871,55 35,55 C27.5441559,55 20.7941559,51.9779221 15.9081169,47.0918831 C11.0220779,42.2058441 8,35.4558441 8,28 C8,20.5441559 11.0220779,13.7941559 15.9081169,8.90811691 C20.7941559,4.02207794 27.5441559,1 35,1 Z"
  //             id="Oval-Copy-4"
  //             stroke="#D9EBF4"
  //             strokeWidth="2"
  //             fill="#FFFFFF"
  //             transform="translate(39.500000, 28.000000) scale(-1, 1) translate(-39.500000, -28.000000) "
  //           ></path>
  //         </g>
  //       </g>
  //     </g>
  //   </svg>
  // );
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 19 16"
      style={{ enableBackground: 'new 0 0 19 16', marginRight: 8 } as any}
    >
      <g>
        <path
          style={{ fill: 'none' }}
          className="st0"
          d="M10.6,4C9.7,4,8,4.7,5.2,8c2.9,3.3,4.5,4,5.4,4c1.1,0,2.1-0.4,2.8-1.2c0.8-0.8,1.2-1.8,1.2-2.8
      s-0.4-2.1-1.2-2.8C12.6,4.4,11.6,4,10.6,4z"
        />
        <path
          style={{ fill: '#D9EBF4' }}
          className="st1"
          d="M16.2,2.3C14.7,0.8,12.7,0,10.6,0C7.7,0,4.8,2.1,1,6.7L0,8l1,1.3c3.8,4.7,6.7,6.7,9.6,6.7
      c2.1,0,4.1-0.8,5.7-2.3c1.5-1.5,2.3-3.5,2.3-5.7C18.6,5.9,17.7,3.9,16.2,2.3z M13.4,10.8c-0.8,0.8-1.8,1.2-2.8,1.2
      C9.7,12,8,11.3,5.2,8C8,4.7,9.7,4,10.6,4c1.1,0,2.1,0.4,2.8,1.2c0.8,0.8,1.2,1.8,1.2,2.8S14.2,10.1,13.4,10.8z"
        />
      </g>
    </svg>
  );
};

export const MigrationOvalRight = ({ percent }) => {
  return (
    <span
      style={{
        width: '65px',
        height: '56px',
        display: 'inline-flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{ position: 'absolute', left: 0, top: 0, width: 65, height: 56 }}
      >
        <svg
          width="65px"
          height="56px"
          viewBox="0 0 65 56"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Dash-Oval-/-Migrate-Forward-1">
              <path
                d="M28,56 C38.3093153,56 50.6426487,46.6666667 65,28 C50.6426487,9.33333333 38.3093153,0 28,0 C12.536027,0 0,12.536027 0,28 C0,43.463973 12.536027,56 28,56 Z"
                id="Oval-Copy-4"
                fill="#D9EBF4"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <span
        style={{
          zIndex: 100,
          fontSize: 12,
          color: '#509bb0',
          fontWeight: 'bold',
        }}
      >
        {percent}
      </span>
    </span>
  );
};

export const MigrationOvalRightSmall = () => {
  return (
    <span
      style={{
        width: '38.05px',
        height: '32px',
        display: 'inline-flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: 38.05,
          height: 32,
        }}
      >
        <svg
          width="100%"
          height="32px"
          viewBox="0 0 65 56"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Dash-Oval-/-Migrate-Forward-1">
              <path
                d="M28,56 C38.3093153,56 50.6426487,46.6666667 65,28 C50.6426487,9.33333333 38.3093153,0 28,0 C12.536027,0 0,12.536027 0,28 C0,43.463973 12.536027,56 28,56 Z"
                id="Oval-Copy-4"
                fill="#D9EBF4"
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </span>
  );
};

export const MigrationOvalRightKey = (props: any) => {
  const width = props.width || '18.5px';
  const height = props.height || '16px';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 56"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Dash-Oval-/-Migrate-Forward-1">
          <path
            d="M28,56 C38.3093153,56 50.6426487,46.6666667 65,28 C50.6426487,9.33333333 38.3093153,0 28,0 C12.536027,0 0,12.536027 0,28 C0,43.463973 12.536027,56 28,56 Z"
            id="Oval-Copy-4"
            fill="#D9EBF4"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const MigrationOvalRetain = ({ percent }) => {
  return (
    <span
      style={{
        width: 65,
        height: 56,
        display: 'inline-flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{ position: 'absolute', left: 0, top: 0, width: 65, height: 56 }}
      >
        <svg
          width="56px"
          height="56px"
          viewBox="0 0 56 56"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Oval Copy 2</title>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Dash-Oval-/-Retain"
              transform="translate(-8.000000, 0.000000)"
            >
              <circle
                id="Oval-Copy-2"
                stroke="#D9EBF4"
                strokeWidth="2"
                cx="36"
                cy="28"
                r="27"
              ></circle>
            </g>
          </g>
        </svg>
      </div>
      <span
        style={{
          zIndex: 100,
          fontSize: 10,
          color: '#509bb0',
          fontWeight: 'bold',
        }}
      >
        {percent}
      </span>
    </span>
  );
};

export const MigrationOvalRetainKey = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 56 56"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Dash-Oval-/-Retain" transform="translate(-8.000000, 0.000000)">
          <circle
            id="Oval-Copy-2"
            stroke="#D9EBF4"
            strokeWidth="4"
            cx="36"
            cy="28"
            r="27"
          ></circle>
        </g>
      </g>
    </svg>
  );
};

export const MigrationOvalLost = ({ percent }) => {
  return (
    <span
      style={{
        width: 65,
        height: 56,
        display: 'inline-flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{ position: 'absolute', left: 0, top: 0, width: 65, height: 56 }}
      >
        <svg
          width="56px"
          height="56px"
          viewBox="0 0 56 56"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Oval Copy</title>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Dash-Oval-/-Lost-Souls">
              <circle
                id="Oval-Copy"
                fill="#333333"
                cx="28"
                cy="28"
                r="28"
              ></circle>
            </g>
          </g>
        </svg>
      </div>
      <span
        style={{
          zIndex: 100,
          fontSize: 10,
          color: '#fff',
          fontWeight: 'bold',
        }}
      >
        {percent}
      </span>
    </span>
  );
};

export const MigrationOvalLostKey = (props: any) => {
  const width = props.width || '16px';
  const height = props.height || '16px';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
    >
      <title>Oval Copy</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Dash-Oval-/-Lost-Souls">
          <circle id="Oval-Copy" fill="#333333" cx="28" cy="28" r="28"></circle>
        </g>
      </g>
    </svg>
  );
};
