import React, { Component } from 'react';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import styled from 'styled-components';

interface LadderHeaderProps {}

export const ContainerBlue = styled.div`
  background: #f7fbfd;
`;

export const BlueGridUnit = styled(Grid.Unit)`
  background: #d9ebf4;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const LadderHeaderGrid = styled(Grid)`
  margin-bottom: 4px;
`;

export const Heading = styled.p`
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
`;

export function LadderHeader(props: LadderHeaderProps) {
  return (
    <LadderHeaderGrid>
      <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
        <Padding all={1}>
          <Heading>TOUCHPOINT</Heading>
        </Padding>
      </BlueGridUnit>
      <BlueGridUnit size={{ sm: 1, md: 6 / 12 }}>
        <Padding all={1}>
          <Heading>WHAT IT IS</Heading>
        </Padding>
      </BlueGridUnit>
      <BlueGridUnit size={{ sm: 1, md: 3 / 12 }}>
        <Padding all={1}>
          <Heading>WHAT IT DOES</Heading>
        </Padding>
      </BlueGridUnit>
    </LadderHeaderGrid>
  );
}

export default LadderHeader;
