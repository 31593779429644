import * as d3 from 'd3';
import prepareNodeData from './calc';

import plotLevelZero from './level.zero';
import plotLevelOne from './level.one';
import plotLevelTwo from './level.two';
import plotLevelThree from './level.three';
import plotLevelFour from './level.four';

export const vWidth = 1180;
export const vHeight = 980;
export const NODE_RADIUS = [60, 45, 10, 8, 4, 4];

export function handleMouseOver(node) {
  if (!node || !node.elements) return;
  for (let element of node.elements) {
    element.classed('highlight', true);
  }
  if (node.parent) {
    handleMouseOver(node.parent);
  }
}

export function handleMouseOut(node) {
  if (!node || !node.elements) return;
  for (let element of node.elements) {
    element.classed('highlight', false);
  }
  if (node.parent) {
    handleMouseOut(node.parent);
  }
}

export function hasTags(node, tags) {
  if (!tags) return true;

  const nodeTags = node.tags
    ? node.tags.map((tag) => (tag ? tag.split(' ').join('') : tag))
    : [];

  const diff = tags.filter((value) => nodeTags.includes(value));

  if (diff.length > 0) return false;
  return true;
}

export function drawNode(g, node, setNode, withTags) {
  if (!node || !node.position) {
    return;
  }

  const { x, y, angle } = node.position;

  const circle = g
    .append('circle')
    .attr('r', NODE_RADIUS[node.depth])
    .attr('transform', 'translate(' + parseInt(x) + ',' + parseInt(y) + ')')
    .classed(`depth-${node.depth}`, true)
    .classed('has-children', node.children ? node.children.length > 0 : false)
    .on('mouseover', () => {
      if (!node.active) handleMouseOver(node);
    })
    .on('mouseout', () => {
      if (!node.active) handleMouseOut(node);
    })
    .on('click', () => {
      handleMouseOver(node);

      setNode((n) => {
        if (n) n.active = false;
        handleMouseOut(n);
        node.active = true;
        return node;
      });
    });

  if (withTags) {
    circle.classed('filtered', hasTags(node, withTags));
  }
  //.on('mouseout', handleMouseOut);

  node.elements.push(circle);

  if (node.diag) {
    for (let diag of node.diag) {
      g.append('circle')
        .attr('r', diag.radius)
        .attr(
          'transform',
          'translate(' + parseInt(diag.x) + ',' + parseInt(diag.y) + ')'
        )
        .classed('diag', true)
        .classed(`depth-diag-${node.depth}`, true)
        .classed('filtered', hasTags(node, withTags));
    }
  }

  if (node.textPosition) {
    if (node.textPosition.html) {
      const f = g
        .append('foreignObject')
        .attr('width', node.textPosition.width)
        .attr('height', node.textPosition.height)
        .attr(
          'transform',
          'translate(' +
            parseInt(node.textPosition.x) +
            ',' +
            parseInt(node.textPosition.y) +
            ')'
        )
        .on('mouseover', () => {
          handleMouseOver(node);
        })
        .on('mouseout', () => {
          handleMouseOut(node);
        })
        .on('click', () => setNode(node));

      const div = f
        .append('xhtml:div')

        .attr('width', node.textPosition.width + 'px')
        .attr('height', node.textPosition.height + 'px')
        .classed('multitext-div', true);

      div.append('p').attr('class', `depth-${node.depth}`).html(node.name);

      node.elements.push(f);
    } else {
      const t = g
        .append('text')
        .attr('font-size', node.textPosition.size || 10)
        .attr(
          'transform',
          'translate(' +
            parseInt(node.textPosition.x) +
            ',' +
            parseInt(node.textPosition.y) +
            ')'
        )
        .text(`${node.name}`)
        .on('mouseover', () => {
          handleMouseOver(node);
        })
        .on('mouseout', () => {
          handleMouseOut(node);
        })
        .on('click', () => setNode(node));

      if (node.textPosition.width) {
        t.attr('width', node.textPosition.width);
      }

      if (node.textPosition.height) {
        t.attr('height', node.textPosition.height);
      }
      node.elements.push(t);
    }
  } else {
    g.append('text')
      .attr('font-size', '10')
      .attr(
        'transform',
        'translate(' +
          parseInt(x + NODE_RADIUS[node.depth] + 10) +
          ',' +
          parseInt(y + NODE_RADIUS[node.depth] / 2) +
          ')'
      )
      .text(`${node.name}`)
      .on('mouseover', () => {
        handleMouseOver(node);
      })
      .on('mouseout', () => {
        handleMouseOut(node);
      })
      .on('click', () => setNode(node));
  }
  /*
    .text(
      `${angle}:${
        node.nodePercentSpace ? node.nodePercentSpace.toFixed(2) : '0'
      }:${node.children ? node.children.length : 0}`
    );
    */

  if (node.depth === 2) {
    // drawConstraintPath(g, node);
  }
}
export function plotStoryUniverseNodes(root, depth, positions = []) {
  if (root.children) {
    for (let node of root.children) {
      switch (depth) {
        case 0: {
          positions.push(plotLevelZero(node));
          break;
        }

        case 1: {
          positions.push(plotLevelOne(node));
          break;
        }

        case 2: {
          positions.push(plotLevelTwo(node));
          break;
        }

        case 3: {
          positions.push(plotLevelThree(node));
          break;
        }

        case 4: {
          positions.push(plotLevelFour(node));
          break;
        }
      }

      if (node.children) {
        plotStoryUniverseNodes(node, depth + 1, positions);
      }
    }
  }

  return positions;
}

export function drawLine(g, n1, n2, withTags) {
  if (!n1.position || !n2.position) {
    return;
  }

  let defaultArc =
    'M' +
    n1.position.x +
    ',' +
    n1.position.y +
    'C' +
    n1.position.x +
    ',' +
    n1.position.y +
    ' ' +
    (n2.position.x + 10) +
    ',' +
    (n2.position.y + 20) +
    ' ' +
    n2.position.x +
    ',' +
    n2.position.y;

  const path = g
    .append('path')
    .attr('d', function (d) {
      return n2.drawTo ? n2.drawTo(n1, n2) : defaultArc;
    })
    .classed(`depth-path-${n1.depth}`, true);

  n2.elements.push(path);
}

export function drawPath(g, node, withTags) {
  if (!node.parent) {
    // return;
  }

  if (node.children) {
    for (let child of node.children) {
      drawLine(g, node, child, withTags);
    }
  }
}

export function drawStoryUniverse(ref, nodes, setNode, withTags = null) {
  nodes = prepareNodeData(nodes, 1);
  const positions = plotStoryUniverseNodes(nodes, 1);

  var g = d3
    .select(ref)
    .attr('width', vWidth)
    .attr('height', vHeight)
    .select('g');

  g.selectAll('*').remove();

  g.attr(
    'transform',
    'translate(' +
      (Math.round(vWidth / 2) + 50) +
      ',' +
      Math.round(vHeight / 2) +
      ')'
  );

  nodes.depth = 0;
  nodes.index = 0;
  nodes.elements = [];

  const root = plotLevelZero(nodes);

  drawPath(g, root, withTags);
  drawNode(g, root, setNode, withTags);

  for (let node of positions) {
    drawPath(g, node, withTags);
    drawNode(g, node, setNode, withTags);
  }
}
