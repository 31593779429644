import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const ButtonHollow = styled.div`
  border: 1px solid #509bb0;
  border-radius: 4px;
  padding: 0.5rem 2rem;
  color: #509bb0;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 14px;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.4s;

  :hover {
    border: 1px solid #334450;
    color: #334450;
  }
`;

export const DownloadButton = styled(ButtonHollow)`
  background: #509bb0;
  color: #fff;
  margin-top: 22px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: proxima-nova, sans-serif;
  border: none;
  background: ${(props: any) =>
    props.type === 'delete' ? '#ed4747' : '#509bb0'};

  width: auto;

  :hover {
    color: #fff;
    border: none;
  }

  svg {
    width: 18px;
    height: 16px;
    fill: #fff;
    // margin-left: 16px;
    margin-right: 16px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    width: 100%;
  }

  span {
    height: 16px;
  }

  font-size: 13px;

  ${breakpoint('xs', 'sm')`
    width: 100%;
    text-align: center;
    padding: 1rem 2rem;
  `}
` as any;

export const DownloadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin-left: 16px;
`;
