import React, { Component, useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { ISegmentData } from '../../../metrics/enum';

import styled from 'styled-components';
import { Padding } from 'styled-components-spacing';
import { Loader } from '../../../components/loader';

const SegmentStat = styled.div`
  height: 32px;
  padding: 0px 16px;
  display: inline-flex;

  border-radius: 14px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: #eee7e7;

  span {
    color: #666666;
    font-size: 12px;
    margin-right: 8px;
  }
`;

const SegmentContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
`;

export function SegmentCAC({ segment }) {
  const [quantDataLoading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (quantDataLoading || !quantData) {
    return (
      <SegmentContainer>
        <Padding all={2}>
          <Loader />
        </Padding>
      </SegmentContainer>
    );
  }

  const data: ISegmentData = quantData[segment.type];

  if (!data) {
    return null;
  }

  const migration = data.migration;
  const movement = data.movement;

  return (
    <>
      <SegmentContainer>
        <Padding top={2} bottom={2}>
          <SegmentStat>
            <span>Segment Count: {data.current.usersFormatted}</span>
            <span>Revenue: {data.current.revenueFormatted}</span>
            <span>CAS: {new Intl.NumberFormat('en-us', {maximumFractionDigits:0}).format(data.scores.total)}/500</span>
          </SegmentStat>
        </Padding>
      </SegmentContainer>
    </>
  );
}
