import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineThree, Paragraph } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo-hooks';

import * as store from 'store';
import { LOAD_BRAND_USERS } from '../../utils/gql';
import { checkPermissions, SCOPES } from '../../utils/permissions';

export function AdminBrand(props) {
  const buttons =
    checkPermissions([SCOPES.canManage], props.brand.id)
      ? [
          {
            label: 'Add User',
            link: `/admin/brand/${props.brand.id}/user`,
          },
          {
            label: 'Edit Brand',
            link: `/admin/brand/${props.brand.id}/edit`,
          },
        ]
      : []

  return (
    <AdminScreen
      headline="Admin"
      buttons={buttons}
      >
      <HeadlineTwo>Customer Segments</HeadlineTwo>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/segment/prospect`}>
          Prospects
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/segment/casual`}>
          Casuals
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/segment/loyalist`}>
          Loyalists
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/segment/cheerleaders`}>
          Cheerleaders
        </Link>
      </Row>
      <HeadlineTwo>Strategic Library</HeadlineTwo>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/su`}>
          Story Universe
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/bonds`}>
          Five Bonds of Loyalty
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/affinity`}>
          Affinity Ladder
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/loyalty`}>
          Loyalty Ladder
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/consumer`}>
          Consumer Findings
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/stakeholder`}>
          Stakeholder Findings
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/downloads`}>
          Downloads
        </Link>
      </Row>
      <HeadlineTwo>Data</HeadlineTwo>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/shopify-config`}>
          Shopify Config
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/import`}>
          Import Data
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/config`}>
          Configure Data
        </Link>
      </Row>
      <Row>
        <Link
          to={`/admin/brand/${props.match.params.brand}/migrate`}>
          Migrate Data
        </Link>
      </Row>
      <HeadlineTwo>Users</HeadlineTwo>
      {props.brand.users.map(user => {
        return (
          <Row key={user.id}>
            <Link to={`/admin/brand/${props.match.params.brand}/user/${user.id}`}>
              {user.firstName} {user.lastName}
            </Link>
          </Row>
        );
      })}
      {props.brand.users.length === 0 && (
        <Paragraph>No users found.</Paragraph>
      )}
    </AdminScreen>
  );
}

export function AdminBrandLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND_USERS, {
    variables: {
      id: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return <AdminBrand {...props} brand={data.brand} />;
}

export default AdminBrandLoader;
