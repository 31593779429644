import * as React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
  ParagraphLarge,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import { AlignCenter } from '../../components/containers/align';
import { OrderedList, OrderedListItem } from '../../components/lists';
import SideBar from '../../components/navigation/sidebar';

import { StyledInput as StyledTextArea } from '../../components/admin/inputs/textarea';
import TextInput from '../../components/admin/inputs/text';
import AdminScreen from '../../components/admin/containers/screen';
import {
  ButtonHollow,
  DownloadButton,
  DownloadButtonContainer,
} from '../../components/buttons';
import { useQuery, useMutation } from 'react-apollo-hooks';

import { getSegment } from '../../data';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import * as store from 'store';
import { LOAD_BRAND_SEGMENT, UPDATE_DOC } from '../../utils/gql';

export const TextAreaInput = styled(StyledTextArea)`
  border: 1px solid #d7d7d836;
  background: #f6f3f038;
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  height: 140px;
`;

export const TextAreaInputDescription = styled(StyledTextArea)`
  border: 1px solid #d7d7d836;
  background: #f6f3f038;
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  height: 250px;
`;

export function AdminCustomerActivationSegment(props) {}

const types = {
  prospect: {
    type: 'SEGMENT_PROSPECTS',
    name: 'PROSPECTS',
  },
  casual: {
    type: 'SEGMENT_CASUAL',
    name: 'CASUALS',
  },
  cheerleaders: {
    type: 'SEGMENT_CHEERLEADER',
    name: 'CHEERLEADERS',
  },
  loyalist: {
    type: 'SEGMENT_LOYALIST',
    name: 'LOYALISTS',
  },
};

export function AdminCustomerActivationSegmentLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND_SEGMENT, {
    variables: {
      id: props.match.params.brand,
      type: types[props.match.params.segment].type,
    },
  });

  if (loading || !data) return null;
  if (error) return null;
  return (
    <Segment
      name={types[props.match.params.segment].name}
      brand={data.brand}
    />
  );
}

export const SegmentTitle = styled(HeadlineThree)``;
export const SegmentText = styled(Paragraph)``;

export const StoryUniversePlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background: #f3f3f3;
`;

interface DashboardProps {
  segment?: any;
  brand?: any;
  name: any;
}

export function Segment(props: DashboardProps) {
  const [global, setGlobal] = useGlobal();
  const [segment, setSegment] = React.useState(
    props.brand.segment.contents || {}
  );
  const mutation = useMutation(UPDATE_DOC);

  const buttons = [
    {
      label: 'Save Segment',
      onClick: save,
    },
  ];

  function save() {
    try {
      mutation({
        variables: {
          input: {
            id: props.brand.segment.id,
            type: props.brand.segment.type,
            contents: JSON.stringify(segment),
          },
        },
      });

      notify(setGlobal, `Saved ${props.name}`);
    } catch (e) {}
  }

  function changeField(key, value) {
    setSegment((segment) => {
      return { ...segment, [key]: value };
    });
  }

  return (
    <AdminScreen
      headline={props.name}
      buttons={buttons}
      >
      <Grid>
        <Grid.Unit size={{ sm: 1 }}>
          <Box>
            <Padding all={3}>
              <Grid>
                <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                  <Padding all={3}>
                    <HeadlineTwo>DEFINITION</HeadlineTwo>
                    <Divider />
                    <TextAreaInputDescription
                      value={segment.description}
                      onChange={(e) =>
                        changeField('description', e.target.value)
                      }
                    />
                  </Padding>
                </Grid.Unit>
                <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                  <Grid>
                    <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                      <Padding all={3}>
                        <HeadlineTwo>STRATEGIES</HeadlineTwo>
                        <Divider />
                        <SegmentText>
                          <b>Attributes:</b>
                          <br />
                          <TextAreaInput
                            value={segment.attributes}
                            onChange={(e) =>
                              changeField('attributes', e.target.value)
                            }
                          />
                        </SegmentText>
                        <SegmentText>
                          <b>Goals:</b>
                          <br />
                          <TextAreaInput
                            value={segment.goals}
                            onChange={(e) =>
                              changeField('goals', e.target.value)
                            }
                          />
                        </SegmentText>
                        <SegmentText>
                          <b>Process:</b>
                          <br />
                          <TextAreaInput
                            value={segment.process}
                            onChange={(e) =>
                              changeField('process', e.target.value)
                            }
                          />
                        </SegmentText>
                        <SegmentText>
                          <b>Key Consumer Insight:</b>
                          <br />
                          <TextAreaInput
                            value={segment.insights}
                            onChange={(e) =>
                              changeField('insights', e.target.value)
                            }
                          />
                        </SegmentText>
                      </Padding>
                    </Grid.Unit>

                    <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                      <Padding all={3}>
                        <HeadlineTwo>TRIGGER</HeadlineTwo>
                        <Divider />
                        <SegmentText>
                          <b>Key Experience:</b>
                          <br />
                          <TextAreaInput
                            value={segment.triggersKeyExperience}
                            onChange={(e) =>
                              changeField(
                                'triggersKeyExperience',
                                e.target.value
                              )
                            }
                          />
                        </SegmentText>
                        <SegmentText>
                          <b>Discovery:</b>
                          <br />
                          <TextAreaInput
                            value={segment.triggersDiscovery}
                            onChange={(e) =>
                              changeField('triggersDiscovery', e.target.value)
                            }
                          />
                        </SegmentText>
                        <SegmentText>
                          <b>Touchpoint:</b>
                          <TextAreaInput
                            value={segment.triggersTouchpoint}
                            onChange={(e) =>
                              changeField('triggersTouchpoint', e.target.value)
                            }
                          />
                        </SegmentText>
                      </Padding>
                    </Grid.Unit>
                  </Grid>
                  <Grid>
                    <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                      <Padding all={3}>
                        <HeadlineTwo>TACTICAL COMMS</HeadlineTwo>
                        <Divider />

                        <TextAreaInput
                          value={segment.tacticalComms}
                          onChange={(e) =>
                            changeField('tacticalComms', e.target.value)
                          }
                        />
                      </Padding>
                    </Grid.Unit>
                    <Grid.Unit size={{ sm: 1, md: 6 / 12 }}>
                      <Padding all={3}>
                        <HeadlineTwo>CALLS TO ACTION</HeadlineTwo>
                        <Divider />
                        <TextAreaInput
                          value={segment.callsToAction}
                          onChange={(e) =>
                            changeField('callsToAction', e.target.value)
                          }
                        />
                      </Padding>
                    </Grid.Unit>
                  </Grid>
                </Grid.Unit>
              </Grid>
            </Padding>
          </Box>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export default AdminCustomerActivationSegmentLoader;
