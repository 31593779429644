import styled from 'styled-components';

export const LinkList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const LinkListItem = styled.li`
  color: ${(props: any) => (props.active ? '#425663' : '#509bb0')};
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  line-height: 17px;
  font-weight: ${(props: any) => (props.active ? '700' : '500')};
  margin-bottom: 0.5rem;
  cursor: pointer;

  a {
    color: ${(props: any) => (props.active ? '#425663' : '#509bb0')};
    text-decoration: none;
  }
` as any;

export const LinkListHeader = styled.li`
  color: ${(props: any) => (props.active ? '#425663' : '#425663')};
  font-family: proxima-nova, sans-serif;
  font-size: 15px;
  line-height: 17px;
  font-weight: ${(props: any) => (props.active ? '700' : '700')};
  margin-bottom: 0.5rem;

  span {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  a {
    color: ${(props: any) => (props.active ? '#425663' : '#425663')};
    text-decoration: none;
  }
` as any;

export const OrderedList = styled.ol`
  margin: 0 0 0 1rem;
  padding: 0;
`;

export const OrderedListItem = styled.li`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 0.25rem;
`;
