export function convertAngleToRadian(angle: number) {
  return angle * (Math.PI / 180);
}

export function getPositionOnRadius(
  angle: number,
  radius: number,
  cx: number = 0,
  cy: number = 0
) {
  angle = parseInt(angle.toFixed(2));
  const deg = convertAngleToRadian(angle);
  const x = radius * Math.cos(deg);
  const y = radius * Math.sin(deg);

  return { x: x + cx, y: y + cy, angle };
}

export function getPositionOnRectangle(angle, rect) {
  const deg = angle;
  var twoPI = Math.PI * 2;
  var theta = (deg * Math.PI) / 180;

  while (theta < -Math.PI) {
    theta += twoPI;
  }

  while (theta > Math.PI) {
    theta -= twoPI;
  }

  var rectAtan = Math.atan2(rect.height, rect.width);
  var tanTheta = Math.tan(theta);
  var region;

  if (theta > -rectAtan && theta <= rectAtan) {
    region = 1;
  } else if (theta > rectAtan && theta <= Math.PI - rectAtan) {
    region = 2;
  } else if (theta > Math.PI - rectAtan || theta <= -(Math.PI - rectAtan)) {
    region = 3;
  } else {
    region = 4;
  }

  var edgePoint = { x: rect.width / 2, y: rect.height / 2, angle };
  var xFactor = 1;
  var yFactor = 1;

  switch (region) {
    case 1:
      yFactor = -1;
      break;
    case 2:
      yFactor = -1;
      break;
    case 3:
      xFactor = -1;
      break;
    case 4:
      xFactor = -1;
      break;
  }

  if (region === 1 || region === 3) {
    edgePoint.x += xFactor * (rect.width / 2); // "Z0"
    edgePoint.y += yFactor * (rect.width / 2) * tanTheta;
  } else {
    edgePoint.x += xFactor * (rect.height / (2 * tanTheta)); // "Z1"
    edgePoint.y += yFactor * (rect.height / 2);
  }

  return edgePoint;
}

export function getOuterNodesTotal(node) {
  let total = 0;
  for (let child of node.children) {
    total = total + child.children.length;
  }
  return total;
}

export function getParentNodeAngledSpaceAvailable(node, area) {
  return node.parent.nodePercentSpace * area;
}

export function toCurve(x, y, ax, ay, bx, by, xx, yy) {
  return `M${x},${y}C${ax},${ay} ${bx},${by} ${xx},${yy}`;
}
