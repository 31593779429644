import * as React from 'react';
import {
  debugLoyalistsCountAsPercentage,
  debugLoyalistsIndValue,
  debugLoyalistsMigrationForward,
  debugLoyalistsRevenueAsPercentage,
} from '../debug/loyalists';

import { IQuantData, ISegmentData, SEGMENTS } from '../enum';
import formatters, { formatter } from '../formatters';
import {
  insightsLoyalistsIndValue,
  insightsLoyalistsCountAsPercentage,
  insightsLoyalistsMigrationForward,
  insightsLoyalistsRevenueAsPercentage,
} from '../insights/loyalists';
import {
  optimizationsLoyalistsIndValue,
  optimizationsLoyalistsCountAsPercentage,
  optimizationsLoyalistsMigrationForward,
  optimizationsLoyalistsRevenueAsPercentage,
} from '../optimizations/loyalists';
import { WeightedScaleValue, WeightedValue, calcWeighted } from './common';

export interface BenchmarkLoyalists {
  MIGRATION_FORWARD: number;
  COUNT_AS_PERCENTAGE: number;
  REVENUE_AS_PERCENTAGE: number;
  IND_VALUE: number;
}

export function formulasLoyalistsCountAsPercentage(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.current.countPercentOfTotal;
  const weighted = calcWeighted(
    segment.current.countPercentOfTotal,
    benchmark,
    scale
  );

  return {
    raw: raw,
    weighted,
    name: debugLoyalistsCountAsPercentage(benchmark, raw, weighted),
    benchmark: benchmark,
    insights: insightsLoyalistsCountAsPercentage(benchmark, raw, weighted),
    optimizations: optimizationsLoyalistsCountAsPercentage(
      benchmark,
      raw,
      weighted
    ),
  };
}

export function formulasLoyalistsRevenueAsPercentage(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.current.revenueAsPercentageOfTotal;
  const weighted = calcWeighted(
    segment.current.revenueAsPercentageOfTotal,
    benchmark,
    scale
  );

  return {
    raw,
    weighted,
    name: debugLoyalistsRevenueAsPercentage(benchmark, raw, weighted),
    insights: insightsLoyalistsRevenueAsPercentage(benchmark, raw, weighted),
    optimizations: optimizationsLoyalistsRevenueAsPercentage(
      benchmark,
      raw,
      weighted
    ),
    benchmark: benchmark,
  };
}

export function formulasLoyalistsMigrationForward(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw = segment.migration.to.cheerleaders / segment.previous.count;
  const weighted = calcWeighted(
    segment.migration.to.cheerleaders / segment.previous.count,
    benchmark,
    scale
  );

  return {
    raw,
    weighted,
    name: debugLoyalistsMigrationForward(benchmark, raw, weighted),
    insights: insightsLoyalistsMigrationForward(benchmark, raw, weighted),
    optimizations: optimizationsLoyalistsMigrationForward(
      benchmark,
      raw,
      weighted
    ),
    benchmark: benchmark,
  };
}

export function formulasLoyalistsIndValue(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const casuals: ISegmentData = data[SEGMENTS.CASUAL];
  const indValue = casuals.current.netRevenuePerUser;
  const raw = segment.current.netRevenuePerUser / indValue;
  const weighted = calcWeighted(raw, benchmark, scale);
  return {
    raw,
    weighted,
    name: debugLoyalistsIndValue(
      benchmark,
      raw,
      weighted,
      indValue,
      segment.current.netRevenuePerUserFormatted
    ),
    insights: insightsLoyalistsIndValue(benchmark, raw, weighted),
    optimizations: optimizationsLoyalistsIndValue(benchmark, raw, weighted),
    benchmark: benchmark,
  };
}

export function scoreLoyalists(
  segment: ISegmentData,
  data: IQuantData,
  brand: any,
  scale: WeightedScaleValue[],
  benchmarks: BenchmarkLoyalists
) {
  const hasTargetCost =
    brand.dataConfig.prospects.users &&
    brand.dataConfig.prospects.costPer &&
    brand.dataConfig.casuals.targetAquisition;

  const scores = {
    use: true,
    grades: [],
    total: 0,
    totalFormatted: '0',
    insights: [],
    optimizations: [],
  };

  scores.grades = [
    formulasLoyalistsMigrationForward(
      segment,
      data,
      benchmarks.MIGRATION_FORWARD,
      scale
    ),
    formulasLoyalistsCountAsPercentage(
      segment,
      data,
      benchmarks.COUNT_AS_PERCENTAGE,
      scale
    ),
    formulasLoyalistsRevenueAsPercentage(
      segment,
      data,
      benchmarks.REVENUE_AS_PERCENTAGE,
      scale
    ),
    formulasLoyalistsIndValue(segment, data, benchmarks.IND_VALUE, scale),
  ].filter((v) => v !== null);

  const total = scores.grades.reduce(
    (total, score) => total + score.weighted,
    0
  );

  scores.total = total / scores.grades.length;
  scores.totalFormatted = formatters.count.format(scores.total);

  scores.insights = scores.grades.reduce(
    (insights, grade) => [...insights, ...grade.insights],
    []
  );

  scores.optimizations = scores.grades.reduce(
    (optimizations, grade) => [...optimizations, ...grade.optimizations],
    []
  );

  return scores;
}
