import React from 'react';
import { useGlobal } from 'reactn';
import moment from 'moment';
import DateInput from '../../../../components/inputs/date';
import SelectInput from '../../../../components/inputs/select';
import * as store from 'store';

export function DataDateInput() {
  const [scoring, setScoring]: [any, any] = useGlobal('scoring');
  const [brand, setBrand]: [any, any] = useGlobal('brand');
  const [base, setBase] = useGlobal(`term_one_base`);
  const [compare, setCompare] = useGlobal(`term_one_compare`);

  function setBaseValue(value) {
    let _compare = value;
    switch (getDisplay()) {
      case '3 Months Ago':
        _compare = moment(value).subtract(3, 'months').format('YYYY-MM-DD');
        break;
      case '6 Months Ago':
        _compare = moment(value).subtract(6, 'months').format('YYYY-MM-DD');
        break;
      case '1 Year Ago':
        _compare = moment(value).subtract(12, 'months').format('YYYY-MM-DD');
        break;
      case '2 Years Ago':
        _compare = moment(value).subtract(24, 'months').format('YYYY-MM-DD');
        break;
    }

    setBase(value);

    // setCompare(moment(value).su)
    setCompare(_compare);
  }

  function setCompareValue(value) {
    setCompare(value);
  }

  if (!brand) {
    return null;
  }

  const options = [
    {
      value: moment(base).format('YYYY-MM-DD'),
      label: 'Today',
    },

    {
      value: moment(base).subtract(3, 'months').format('YYYY-MM-DD'),
      label: '3 Months Ago',
    },
    {
      value: moment(base).subtract(6, 'months').format('YYYY-MM-DD'),
      label: '6 Months Ago',
    },
    {
      value: moment(base).subtract(12, 'months').format('YYYY-MM-DD'),
      label: '1 Year Ago',
    },
    {
      value: moment(base).subtract(24, 'months').format('YYYY-MM-DD'),
      label: '2 Years Ago',
    },
  ];

  function getDisplay() {
    if (
      moment(base, 'YYYY-MM-DD').subtract(1, 'month').valueOf() ===
      moment(compare, 'YYYY-MM-DD').valueOf()
    ) {
      return '1 Month Ago';
    }

    if (
      moment(base, 'YYYY-MM-DD').subtract(3, 'months').valueOf() ===
      moment(compare, 'YYYY-MM-DD').valueOf()
    ) {
      return '3 Months Ago';
    }

    if (
      moment(base, 'YYYY-MM-DD').subtract(6, 'months').valueOf() ===
      moment(compare, 'YYYY-MM-DD').valueOf()
    ) {
      return '6 Months Ago';
    }

    if (
      moment(base, 'YYYY-MM-DD').subtract(9, 'months').valueOf() ===
      moment(compare, 'YYYY-MM-DD').valueOf()
    ) {
      return '9 Months Ago';
    }

    if (
      moment(base, 'YYYY-MM-DD').subtract(12, 'months').valueOf() ===
      moment(compare, 'YYYY-MM-DD').valueOf()
    ) {
      return '1 Year Ago';
    }

    if (
      moment(base, 'YYYY-MM-DD').subtract(24, 'months').valueOf() ===
      moment(compare, 'YYYY-MM-DD').valueOf()
    ) {
      return '2 Years Ago';
    }

    return null;
  }

  const display = getDisplay();

  return (
    <div>
      <div style={{ display: 'inline-block', marginRight: '8px' }}>
        <DateInput
          type="base"
          hasRelativeOptions={true}
          today={brand.lastDataRecordDate}
          base={base}
          min={brand.firstDataRecordDate}
          max={brand.lastDataRecordDate}
          label={'Select Base'}
          inputProps={{
            value: base,
            onChange: (value) => setBaseValue(value),
          }}
          options={options}
          style={{ margin: 0, marginTop: 40 }}
        />
      </div>
      <div style={{ display: 'inline-block' }}>
        <DateInput
          type="compare"
          hasRelativeOptions={true}
          today={brand.lastDataRecordDate}
          base={base}
          min={brand.firstDataRecordDate}
          max={brand.lastDataRecordDate}
          display={display}
          label={'Select Base'}
          inputProps={{
            value: compare,
            onChange: (value) => setCompareValue(value),
          }}
          options={options}
          style={{ margin: 0, marginTop: 40 }}
        />
      </div>
    </div>
  );
}
