import DashboardStrategy from './dashboard.strategy';
import DashboardData from './dashboard.data';
import DashboardEcom from './dashboard.ecom';
import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { checkPermissions, SCOPES } from '../../utils/permissions';

export function Dashboard(props) {
  const [mode, setMode] = useState('data');
  const [brand, setBrand]: [any, any] = useGlobal('brand');

  if (!brand) {
    return <div></div>;
  }

  if (brand.lastDataRecordDate && checkPermissions([SCOPES.canViewQuant], brand.id)) {
    // return mode === 'strategy'
    //   ? <DashboardStrategy setMode={setMode} {...props} />
    //   : <DashboardData setMode={setMode} {...props} />;
    if (mode === 'data') {
      return <DashboardData setMode={setMode} {...props} />
    }
    if (mode === 'strategy') {
      return <DashboardStrategy setMode={setMode} {...props} />
    }
    if (mode === 'ecom') {
      return <DashboardEcom setMode={setMode} {...props} />
    }
    return null;
  }

  return <DashboardStrategy {...props} />;
}

export default Dashboard;
