import * as React from 'react';
import { debugProspectMigrationForward } from '../debug/prospects';
import { IQuantData, ISegmentData, SEGMENTS } from '../enum';
import formatters, { formatter } from '../formatters';
import { insightsProspectsMigrationForward } from '../insights/prospects';
import { optimizationsProspectsMigrationForward } from '../optimizations/prospects';
import { WeightedScaleValue, WeightedValue, calcWeighted } from './common';

export interface BenchmarkProspects {
  MIGRATION_FORWARD: number;
}

export function formulasProspectsMigrationForward(
  segment: ISegmentData,
  data: IQuantData,
  benchmark: number,
  scale: WeightedScaleValue[]
): WeightedValue {
  const raw =
    (segment.movement.migrationForward || 0) / (segment.previous.users || 0);
  const weighted = calcWeighted(raw, benchmark, scale);
  return {
    raw,
    weighted,
    name: debugProspectMigrationForward(benchmark, raw, weighted),
    insights: insightsProspectsMigrationForward(benchmark, raw, weighted),
    optimizations: optimizationsProspectsMigrationForward(
      benchmark,
      raw,
      weighted
    ),
    benchmark: benchmark,
  };
}

export function scoreProspects(
  segment: ISegmentData,
  data: IQuantData,
  brand: any,
  scale: WeightedScaleValue[],
  benchmarks: BenchmarkProspects
) {
  const scores = {
    use: true,
    grades: [],
    total: 0,
    totalFormatted: '0',
    insights: [],
    optimizations: [],
  };

  scores.grades = [
    formulasProspectsMigrationForward(
      segment,
      data,
      benchmarks.MIGRATION_FORWARD,
      scale
    ),
  ].filter((v) => v !== null);

  const total = scores.grades.reduce(
    (total, score) => total + score.weighted,
    0
  );

  scores.total = total / scores.grades.length;
  scores.totalFormatted = formatters.count.format(scores.total);

  if (isNaN(scores.total) || scores.total === undefined || scores.total < 0) {
    return {
      insights: [],
      optimizations: [],
      grades: [],
      use: false,
      migrationForward: 0,
      total: 0,
      totalFormatted: 0,
    };
  }

  scores.insights = scores.grades.reduce(
    (insights, grade) => [...insights, ...grade.insights],
    []
  );

  scores.optimizations = scores.grades.reduce(
    (optimizations, grade) => [...optimizations, ...grade.optimizations],
    []
  );

  return scores;
}
