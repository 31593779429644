import * as React from 'react';
import styled from 'styled-components';

export const Loader = styled.div`
  border: 2px solid #f6f3f3;
  border-top: 2px solid #509bb0;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1.6s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const loader = <Loader />;

export default loader;
