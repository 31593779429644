import React from 'react';
import styled from 'styled-components';
import { Divider } from '../../../components/decoration/divider';
import { Link } from 'react-router-dom';

export const Toggle = styled.li``;

export const Menu = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 38px;
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.li`
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: #657c89;
  font-family: proxima-nova, sans-serif;
  margin: 8px 24px 0 0px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  .active {
    color: #509bb0;

    a {
      color: #509bb0;
    }
  }

  a {
    color: #657c89;
    text-decoration: none;
  }
` as any;

export const MenuItemActiveBorder = styled.div`
  position: absolute;
  height: 3px;
  background: #38627c;
  width: 100%;
  top: 27px;
  left: 0;
`;

export function SegmentMenu({ children }) {
  return (
    <div>
      <Menu>{children}</Menu>
    </div>
  );
}

export function SegmentMenuItem({
  active = false,
  href = '#',
  label,
  onClick = null,
}) {
  return (
    <MenuItem active={active}>
      <Link onClick={onClick || (() => {})} to={href}>
        {label}
        {active ? <MenuItemActiveBorder /> : null}
      </Link>
    </MenuItem>
  );
}
