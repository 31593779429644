import * as React from 'react';
import * as store from 'store';
import loader from '../loader';
import styled from 'styled-components';
import { ButtonHollow } from '../buttons';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';

export const StyledLabel = styled.label`
  color: #545454;
  font-family: proxima-nova, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 6px 0;
  display: block;
`;

export const UploadComponent = styled.div`
  input[type='file'] {
    display: none;
  }
`;

export function PhotoInput(props) {
  const [global, setGlobal] = useGlobal();
  const input = React.useRef(null);
  const [preview, setPreview] = React.useState(null);
  const [value, setValue] = React.useState(props.value);
  const [loading, setLoading] = React.useState(null);

  function simulateClick() {
    if (input && input.current !== undefined) {
      const current = input.current;
      current.click();
    }
  }

  function onFileChanged(e) {
    var image = new FileReader();
    image.onload = () => {
      setPreview(image.result);
    };
    image.readAsDataURL(e.target.files[0]);
  }

  async function upload(e) {
    setLoading(true);
    var formData = new FormData();
    formData.append('file', e.target.files[0]);

    const response = await fetch(
      (process.env.REACT_APP_API
        ? process.env.REACT_APP_API
        : 'http://localhost:3000') + '/upload',
      {
        method: 'POST',
        body: formData,
        headers: {
          authorization: store.get('token')
        }
      }
    );
    const { location, key } = await response.json();
    if (key) {
      notify(setGlobal, 'Upload successful!');
    }
    setValue(key);
    if (props.onChange) {
      props.onChange(key);
      console.log(key)
    }
    setLoading(false);
  }

  function onConfirm(output) {
    setPreview(null);
    upload(output.file);
  }

  function onCancel() {
    setPreview(null);
  }

  function onError(e) {
    console.log(e);
  }

  return (
    <UploadComponent>
      {props.label ? <StyledLabel>{props.label}</StyledLabel> : null}
      {value && (
        <a
          target="_blank"
          href={`${process.env.REACT_APP_API ||
            'http://localhost:3000'}/${value}`}>
          <ButtonHollow style={{ marginRight: '16px' }}>Download</ButtonHollow>
        </a>
      )}

      <ButtonHollow onClick={simulateClick}>
        {loading ? loader : 'Upload Document'}
      </ButtonHollow>
      <input onChange={upload} ref={input} type="file" />
    </UploadComponent>
  );
}

export default PhotoInput;
