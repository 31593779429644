import React, { useState } from 'react';
import styled from 'styled-components';
import { Divider } from '../../../../components/decoration/divider';
import { useGlobal } from 'reactn';
import { Loader } from '../../../../components/loader';
import { ISegmentData, SEGMENTS } from '../../../../metrics/enum';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { HeadlineTwo } from '../../../../components/typography';

export const SegmentDataArrow = styled.i`
  color: #545454;

  font-size: 12px;
`;

export const SegmentDataArrowContainer = styled.div`
  width: 20px;
  display: inline-block;
`;

export const SegmentDataRow: any = styled.div`
  background-color: ${(props: any) =>
    props.background ? '#f4fafd' : 'transparent'};
` as any;

export const SegmentDataRowLabel = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
` as any;

export const SegmentDataRowLabelBold = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #545454;
  font-weight: 500;
  font-size: 16px;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;
` as any;

export const SegmentDataRowStatContainer = styled.span`
  display: block;

  span {
    margin: 4px 0 4px 8px;
    font-family: proxima-nova, sans-serif;
    color: #545454;
    font-weight: 500;
    font-size: 12px;
    opacity: ${(props: any) => (props.light ? 0.7 : 1)};
    display: inline-block;
  }
` as any;

export const SegmentDataRowValue = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #666;
  font-size: 18px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;

  .fa-arrow-up {
    opacity: 0.3;
  }

  .fa-arrow-down {
    opacity: 0.5;
  }
` as any;

export const SegmentDataRowValueBold = styled.span`
  padding: 12px 8px;
  font-family: proxima-nova, sans-serif;
  color: #666;
  font-size: 18px;
  font-weight: 500;
  opacity: ${(props: any) => (props.light ? 0.7 : 1)};
  display: inline-block;

  .fa-arrow-up {
    opacity: 0.3;
  }

  .fa-arrow-down {
    opacity: 0.5;
  }
` as any;

export const SegmentDataDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9ebf4;
`;

export const PeriodText = styled.span`
  font-size: 12px;
  color: #999999;
`;

export const SegmentValuePanelContainer = styled.div`
  h2 {
  }
`;

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatterCounts = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'USD'
});

export const formatterPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  // currency: 'USD'
});

export const formatterPercentLong = new Intl.NumberFormat('en-US', {
  style: 'percent',
  // minimumFractionDigits: 2,
  maximumFractionDigits: 3,
  // currency: 'USD'
});

export function SegmentValuePanel({ segment }) {
  const [quantDataLoading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (quantDataLoading) {
    return (
      <SegmentValuePanelContainer>
        <Loader />
      </SegmentValuePanelContainer>
    );
  }

  const data: ISegmentData = quantData[segment.type];

  if (!data) {
    return null;
  }

  if (
    segment.type === SEGMENTS.LOSTSOULS ||
    segment.type === SEGMENTS.PROSPECTS
  ) {
    return null;
  }

  return (
    <SegmentValuePanelContainer>
      <HeadlineTwo>SEGMENT VALUE</HeadlineTwo>
      <Divider style={{ marginBottom: 0 }} />
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
          <SegmentDataRow background={true}>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabelBold>Segment Count</SegmentDataRowLabelBold>
              </Grid.Unit>

              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValueBold border={true}>
                  {formatterCounts.format(data.current.users)}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.userPercentChangeFormatted}</span>
                    <span>{data.growth.userTotalChangeFormatted}</span>
                    <span>
                      from {formatterCounts.format(data.previous.users)}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow background={true}>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabel light={true}>
                  % of Total Count
                </SegmentDataRowLabel>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValue border={true} light={true}>
                  {data.current.usersAsPercentageOfTotalFormatted}
                  <SegmentDataRowStatContainer>
                    <span>
                      from {data.previous.usersAsPercentageOfTotalFormatted}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabel>2 Year Revenue</SegmentDataRowLabel>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValue border={true}>
                  {formatter.format(data.current.revenue)}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.revenuePercentChangeFormatted}</span>
                    <span>{data.growth.revenueTotalChangeFormatted}</span>
                    <span>from {data.previous.revenueFormatted}</span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabel light={true}>
                  % of Total Revenue
                </SegmentDataRowLabel>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValue light={true} border={true}>
                  {data.current.revenueAsPercentageOfTotalFormatted}

                  <SegmentDataRowStatContainer>
                    <span>
                      from {data.previous.revenueAsPercentageOfTotalFormatted}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow background={true}>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabel>Marketing Spend</SegmentDataRowLabel>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValue border={true}>
                  {data.current.marketingSpendFormatted}
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow background={true}>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabel light={true}>
                  % of Total Spend
                </SegmentDataRowLabel>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValue light={true} border={true}>
                  {data.current.marketingSpendPercentOfTotalFormatted}
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataDivider />
          <SegmentDataRow>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabelBold>
                  Individual Revenue
                </SegmentDataRowLabelBold>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValueBold border={true}>
                  {data.current.averageIndividualValueFormatted}
                  <SegmentDataRowStatContainer>
                    <span>
                      {data.growth.averageIndividualValuePercentChangeFormatted}
                    </span>
                    <span>
                      {data.growth.averageIndividualValueTotalChangeFormatted}
                    </span>
                    <span>
                      from {data.previous.averageIndividualValueFormatted}
                    </span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>

          <SegmentDataRow background={true}>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabelBold>
                  Cost of Goods per
                </SegmentDataRowLabelBold>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValueBold border={true}>
                  {data.current.cogsPerUserFormatted}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.cogsPerUserPercentChangeFormatted}</span>
                    <span>{data.growth.cogsPerUserTotalChangeFormatted}</span>
                    <span>from {data.previous.cogsPerUserFormatted}</span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabel>Margin per</SegmentDataRowLabel>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValue border={true}>
                  {data.current.marginPerUserFormatted}
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataRow background={true}>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabel>Cost of Marketing per</SegmentDataRowLabel>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValue>
                  {formatter.format(data.current.costPerUser)}
                </SegmentDataRowValue>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>

          <SegmentDataRow>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabelBold>
                  Net Individual Value
                </SegmentDataRowLabelBold>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValueBold>
                  {data.current.netRevenuePerUserFormatted}
                  <SegmentDataRowStatContainer>
                    <span>
                      {data.growth.netRevenuePerUserPercentChangeFormatted}
                    </span>
                    <span>
                      {data.growth.netRevenuePerUserTotalChangeFormatted}
                    </span>
                    <span>from {data.previous.netRevenuePerUserFormatted}</span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
          <SegmentDataDivider />
          <SegmentDataRow>
            <Grid>
              <Grid.Unit size={{ sm: 6 / 12 }}>
                <SegmentDataRowLabelBold
                  border={true}
                  style={{ fontWeight: 'bold' }}
                >
                  Net Segment Value
                </SegmentDataRowLabelBold>
              </Grid.Unit>
              <Grid.Unit style={{ textAlign: 'right' }} size={{ sm: 6 / 12 }}>
                <SegmentDataRowValueBold
                  style={{ fontWeight: 'bold' }}
                  border={true}
                >
                  {formatter.format(data.current.netRevenue)}
                  <SegmentDataRowStatContainer>
                    <span>{data.growth.netRevenuePercentChangeFormatted}</span>
                    <span>{data.growth.netRevenueTotalChangeFormatted}</span>
                    <span>from {new Intl.NumberFormat('en-US', {
                      maximumFractionDigits: 2,
                    }).format(data.previous.netRevenue)}</span>
                  </SegmentDataRowStatContainer>
                </SegmentDataRowValueBold>
              </Grid.Unit>
            </Grid>
          </SegmentDataRow>
        </Grid.Unit>
      </Grid>
    </SegmentValuePanelContainer>
  );
}
