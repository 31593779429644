import React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/containers/container';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';

import {
  HeadlineOne,
} from '../../components/typography';
import { Divider } from '../../components/decoration/divider';
import SideBar from '../../components/navigation/sidebar';
import SVGInline from 'react-svg-inline';
import { useQuery } from 'react-apollo-hooks';
import cacIcon from "../../components/typography/icons/cacIcon";
import { DataDateInput } from './components/inputs/date';

import { SegmentMenu, SegmentMenuItem } from './components/segment.menu';
import { LOAD_BRAND_DASHBOARD_ECOM } from '../../utils/gql';
import { useGlobal } from 'reactn';
import moment from 'moment';
import { formatterDollars,formatterPercent, formatterInt, formatterCurrency } from '../../metrics/formatters';
import { Loader } from '../../components/loader';

export const StyledTable = styled.table`
  border-collapse: collapse;

  & th, & td {
    padding: 8px 12px;
    text-align: right;
    color: #545454;
    white-space: nowrap;
  }
  & th {
    background: #d9ebf4;
    border: 1px solid #71b9ca;
    font-size: 0.9em;
    text-transform: uppercase;
  }
  & td {
    border: 1px solid #e5e5e5;
    background: #ffffff;
  }
  & th:first-child, & td:first-child {
    position: absolute;
    width: 125px;
    z-index: 1;
  }
  & th:first-child {
    z-index: 2;
  }
  & th:nth-child(2), & td:nth-child(2) {
    padding-left: 170px;
  }
`;

export function DashboardEcom(props: any) {

  const [global, setGlobal] = useGlobal();

  const endDate = global.term_one_base;
  const startDate = moment(endDate, 'YYYY-MM-DD')
    .subtract(1, 'years')
    .format('YYYY-MM-DD');

  const { data, error, loading } = useQuery(LOAD_BRAND_DASHBOARD_ECOM, {
    variables: { 
      id: props.match.params.brand,
      endDate: endDate,
      startDate: startDate
    },
  });

  console.log(data)

  return (
    <Container>
      <SideBar active="bem" brand={props.match.params.brand} />
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 12 / 12 }}>
          <Padding all={2}>
            <SVGInline
                className="title-icon"
                style={{ marginRight: '15px', marginTop: '15px', height: '45px', width: '45px' }}
                svg={ cacIcon }/>
            <HeadlineOne>CUSTOMER ACTIVATION CYCLE</HeadlineOne>
          </Padding>
        </Grid.Unit>
      </Grid>
      <Grid>
        <Grid.Unit size={{ sm: 1, md: 1 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  <Grid.Unit size={{ sm: 1 / 2 }}>
                    <Padding top={3} left={2} right={2}>
                      <SegmentMenu>
                        <SegmentMenuItem
                          onClick={(e) => props.setMode('data')}
                          active={false}
                          label={'SEGMENT ACTIVATION'}
                        />
                        <SegmentMenuItem
                          onClick={(e) => props.setMode('strategy')}
                          active={false}
                          label={'DESCRIPTIONS'}
                        />
                        <SegmentMenuItem
                          active={true}
                          label={'ECOM STATS'}
                        />
                      </SegmentMenu>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1 / 2 }}>
                    <Padding top={3} left={3} right={2}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <div style={{ display: 'inline-block' }}>
                          <DataDateInput />
                        </div>
                      </div>
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1 }}>
                    <Padding left={0} right={0}>
                      <Divider />
                    </Padding>
                  </Grid.Unit>
                </Grid>
                {loading && (
                  <Loader />
                )}
                {!loading && !data.brand && (
                  "Google Analytics must be set up to see this section."
                )}
                {!loading && data.brand && data.brand.ecomStats && (
                  <div style={{
                    overflow: "auto",
                  }}>
                    <StyledTable>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          {data.brand.ecomStats.map((stat, index) => (
                            <th key={index}>{stat.month}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Sessions</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterInt.format(stat.traffic)}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>Conversion Rate</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterDollars.format(stat.conversionRate) + '%'}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>Transactions</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterInt.format(stat.transactions)}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>AOV</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterCurrency.format(stat.averageOrderValue)}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>Sales</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterCurrency.format(stat.sales)}</td>
                            
                          ))}
                        </tr>
                        <tr>
                          <td>Shipping</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterCurrency.format(stat.shipping)}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>Refunds</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterCurrency.format(stat.refunds)}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>Total Direct Sales</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterCurrency.format(stat.directSales)}</td>
                          ))}
                        </tr>
                        <tr>
                          <td>Return % of Total</td>
                          {data.brand.ecomStats.map((stat, index) => (
                            <td key={index}>{formatterPercent.format(stat.refundsPercentage)}</td>
                          ))}
                        </tr>
                      </tbody>
                    </StyledTable>
                  </div>
                )}
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </Container>
  );
}

export default DashboardEcom;
