import React from "react";
import { Padding } from "styled-components-spacing";
import Grid from 'styled-components-grid';
import { useQuery } from "react-apollo-hooks";
import { Link } from 'react-router-dom';

import AdminScreen from "../../components/admin/containers/screen";
import Row from "../../components/admin/containers/row";
import { LOAD_USERS } from "../../utils/gql";

export default function AdminUsers() {

    const { data, error, loading } = useQuery(LOAD_USERS);

    if (error) return null;
    if (loading || !data) return null;

    const buttons = [
        { label: "ADD USER", link: "/admin/add-user" }
    ]

    return (
        <AdminScreen headline="All Users" buttons={buttons}>
            <Grid>
                <Grid.Unit size={{ sm: 12 / 12 }}>
                    <Padding all={2}>
                        {data.users.map((user, index) => (
                            <Row key={index}>
                                <Link to={`/admin/user/${user.id}`}>
                                    {user.firstName}{" "}{user.lastName}
                                </Link>
                            </Row>
                        ))}
                    </Padding>
                </Grid.Unit>
            </Grid>
        </AdminScreen>
    )
}