import { getPositionOnRadius, getPositionOnRectangle } from './util';

const SPHERE_RAIDUS = 110;
const AREA = 180;

export function getOuterNodesTotal(node) {
  let total = 0;
  for (let child of node.children) {
    total = total + child.children.length;
  }
  return total;
}

export function plotBranchOne(node) {
  const { branch, parent, index, nodePercentSpace, ancestor } = node;
  const { x, y, angle } = node.parent.position;

  let startAngle;
  let space = ancestor ? ancestor.nodePercentSpace * 200 : 0;

  if (space < 18) {
    space = 18;
  }

  if (ancestor && ancestor.position) {
    startAngle = ancestor.position.angle + space;
  } else {
    startAngle = parent.position.angle - 60;
  }

  startAngle = Math.round(startAngle);

  node.position = {
    ...getPositionOnRadius(startAngle, 80 + index * 10, x, y)
  };

  node.drawTo = (n1, n2) => {
    let inc;

    const dy = n1.position.y - n2.position.y;

    const ax = n1.position.x + 0;
    const ay = n1.position.y;
    const bx = n2.position.x - 60;
    const by = n2.position.y + dy / 2;

    return `M${n1.position.x},${n1.position.y}C${ax},${ay} ${bx},${by} ${
      n2.position.x
    },${n2.position.y}`;
  };

  node.textPosition = {
    x: node.position.x - ((node.name || '').length * 5) / 2,
    y: node.position.y + 20
  };

  if (index === 0) {
    node.textPosition.y = node.position.y - 16;
  }

  return node;
}

export function plotBranchTwo(node) {
  const { branch, parent, index, nodePercentSpace, ancestor } = node;
  const { x, y, angle } = node.parent.position;

  let startAngle;
  let space = ancestor ? ancestor.nodePercentSpace * AREA : 0;

  if (space < 18) {
    space = 18;
  }

  if (ancestor && ancestor.position) {
    startAngle = ancestor.position.angle + space;
  } else {
    startAngle = parent.position.angle - 100;
  }

  startAngle = Math.round(startAngle);

  node.position = {
    ...getPositionOnRadius(
      startAngle,
      SPHERE_RAIDUS + getOuterNodesTotal(node.parent),
      x,
      y
    )
  };

  node.drawTo = (n1, n2) => {
    let inc;

    const dy = n1.position.y - n2.position.y;

    const ax = n1.position.x + 0;
    const ay = n1.position.y;
    const bx = n2.position.x + 60;
    const by = n2.position.y + dy / 2;

    return `M${n1.position.x},${n1.position.y}C${ax},${ay} ${bx},${by} ${
      n2.position.x
    },${n2.position.y}`;
  };

  node.textPosition = {
    x: node.position.x - ((node.name || '').length * 5) / 2,
    y: node.position.y + 20
  };

  // node.name = startAngle.toString();

  return node;
}

export function plotBranchThree(node) {
  const { branch, parent, index, nodePercentSpace, ancestor } = node;
  const { x, y, angle } = node.parent.position;

  let startAngle;
  let space = ancestor ? ancestor.nodePercentSpace * 210 : 0;

  if (space < 18) {
    space = 18;
  }

  if (ancestor && ancestor.position) {
    startAngle = ancestor.position.angle + space;
  } else {
    startAngle = parent.position.angle - 100;
  }

  startAngle = Math.round(startAngle);

  node.position = {
    ...getPositionOnRadius(startAngle, SPHERE_RAIDUS, x, y)
  };

  // node.name = startAngle.toString();

  node.textPosition = {
    x: node.position.x - ((node.name || '').length * 5) / 2,
    y: node.position.y + 20
  };

  return node;
}

export function plotLevelThree(node) {
  const { branch } = node;

  switch (branch) {
    case 0: {
      return plotBranchOne(node);
    }

    case 1: {
      return plotBranchTwo(node);
    }

    case 2: {
      return plotBranchThree(node);
    }
  }
}

export default plotLevelThree;
