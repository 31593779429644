import * as store from 'store';

export const SCOPES = {
    canCRUD: "canCRUD",
    canManage: "canManage",
    canViewFutureValue: "canViewFutureValue",
    canViewExec: "canViewExec",
    canViewQuant: "canViewQuant",
    canViewQual: "canViewQual",
    canView: "canView",
};

const PERMISSIONS = {
    Owner: [
        SCOPES.canCRUD,
        SCOPES.canManage,
        SCOPES.canViewExec,
        SCOPES.canViewFutureValue,
        SCOPES.canViewQuant,
        SCOPES.canViewQual,
        SCOPES.canView
    ],
    Manager: [
        SCOPES.canManage,
        SCOPES.canViewExec,
        SCOPES.canViewFutureValue,
        SCOPES.canViewQuant,
        SCOPES.canViewQual,
        SCOPES.canView
    ],
    Analyst: [
        SCOPES.canViewExec,
        SCOPES.canViewFutureValue,
        SCOPES.canViewQuant,
        SCOPES.canViewQual,
        SCOPES.canView
    ],
    Evaluator: [
        SCOPES.canViewExec,
        SCOPES.canViewFutureValue,
        SCOPES.canView
    ],
    Executive: [
        SCOPES.canViewExec,
        SCOPES.canView
    ],
    Strategist: [
        SCOPES.canViewQual,
        SCOPES.canView
    ],
}

export const checkPermissions = (scopes, brand) => {
    if (store.get('type') === 'admin') {
        return true;
    }

    let role = JSON.parse(store.get('roles')).find(e => e.brand === brand);

    if (!role) {
        return false;
    }

    role = role.role;

    if (role === 'Owner') {
        return true;
    }

    if (scopes.every(e => PERMISSIONS[role].includes(e))) {
        return true;
    }

    return false;
}