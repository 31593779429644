import * as React from 'react';
import AdminScreen from '../../components/admin/containers/screen';
import Row from '../../components/admin/containers/row';
import { HeadlineTwo, HeadlineOne } from '../../components/typography';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Box } from '../../components/containers/box';
import TextInput from '../../components/admin/inputs/text';
import UploadInput from '../../components/inputs/upload';
import CSVInput from '../../components/inputs/csv';
import notify from '../../utils/notify';
import { useGlobal } from 'reactn';
import {
  StoryUniverseContainer,
  Overlay,
  NodeInfo,
} from '../brand/story-universe/story.universe.screen';

import { drawStoryUniverse } from '../brand/story-universe/renderer/render';
import * as store from 'store';
import { LOAD_BRAND_SU, PARSE_SU, UPDATE_DOC } from '../../utils/gql';

export function AdminAccount(props) {
  const [global, setGlobal] = useGlobal();
  const [node, setNode] = React.useState(null);
  const canvas = React.useRef(null);
  const [storyUniverse, setStoryUniverse] = React.useState(
    props.brand.storyUniverse
  );
  const saveStoryUniverse = useMutation(UPDATE_DOC);
  const [json, setJson] = React.useState(
    JSON.stringify(props.brand.storyUniverse.contents)
  );

  const buttons = [
    {
      label: 'Save Story Universe',
      onClick: save,
    },
  ];

  async function save() {
    try {
      const input: any = {
        type: storyUniverse.type,
        contents: json,
        brand: props.brand.id,
      };

      if (storyUniverse.id) {
        input.id = storyUniverse.id;
      }

      const response: any = await saveStoryUniverse({
        variables: {
          input,
        },
      });

      if (response.data && response.data.updateDoc.id) {
        setStoryUniverse(response.data.updateDoc);
      }

      notify(setGlobal, 'Saved Story Universe');
    } catch (e) {
      console.log(e);
    }
  }

  React.useLayoutEffect(() => {
    const t = JSON.stringify(props.brand.storyUniverse.contents);
    drawStoryUniverse(canvas.current, JSON.parse(t) || {}, setNode);
  }, []);

  const parse = useMutation(PARSE_SU);

  async function onUpload(file) {
    const response: any = await parse({ variables: { file } });
    const t = JSON.stringify(response.data.parseStoryUniverse);

    setJson(t);

    drawStoryUniverse(
      canvas.current,
      response.data.parseStoryUniverse,
      setNode
    );
  }

  return (
    <AdminScreen
      headline={'Story Universe'}
      buttons={buttons}
    >
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Box>
            <Padding all={2}>
              <Padding all={2}>
                <CSVInput
                  value={null}
                  onChange={onUpload}
                  buttonLabel="Upload Story Universe"
                  label="Upload CSV"
                />
              </Padding>
              <Padding all={2}>
                <StoryUniverseContainer>
                  <Overlay className={node ? 'show' : 'hide'}>
                    <div className="bg" onClick={(e) => setNode(null)} />
                    <div className="info">
                      {node && <NodeInfo setNode={setNode} node={node} />}
                    </div>
                  </Overlay>
                  <svg ref={canvas}>
                    <g />
                  </svg>
                </StoryUniverseContainer>
              </Padding>
            </Padding>
          </Box>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function AdminAccountLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND_SU, {
    variables: {
      id: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return (
    <AdminAccount
      history={props.history}
      brand={data.brand}
    />
  );
}

export default AdminAccountLoader;
