import React, { Component, useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Link } from 'react-router-dom';

import {
  HeadlineOne,
  HeadlineTwo,
  HeadlineThree,
  Paragraph,
} from '../../../components/typography';

import { ButtonHollow } from '../../../components/buttons';
import { AlignCenter } from '../../../components/containers/align';
import SideBar from '../../../components/navigation/sidebar';
import breakpoint from 'styled-components-breakpoint';

import {
  formatterPercent,
  formatterPercentLong,
  formatterCounts,
  formatter,
} from '../segment';
import { Loader } from '../../../components/loader';
import { GridUnit } from '../ladders/components/ladder.step';

import {
  MigrationOvalLeft,
  MigrationOvalLeftKey,
  MigrationOvalLost,
  MigrationOvalLostKey,
  MigrationOvalRetain,
  MigrationOvalRetainKey,
  MigrationOvalRight,
  MigrationOvalRightKey,
} from './migration';
import { ISegmentData, SEGMENTS } from '../../../metrics/enum';
import { getColorForScore } from '../../../metrics/scoring';

export const SegmentTitle = styled(HeadlineThree as any)`
  color: #657c89;
  font-size: 14px;
`;
export const SegmentText = styled(Paragraph as any)``;

export const SegmentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  // pointer-events: none;

  ${breakpoint('md')`
    display: flex;
  `};
`;

export const SegmentCircle = styled.div`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
`;

export const SegmentCircleInner = styled.div`
  border-radius: 50%;
  width: 90px;
  height: 90px;
  background: white;
  border: 2px solid #657c89;
  display: inline-flex;
`;

export const SegmentCircleOuterTopLeft = styled(Link)`
  width: 39px;
  height: 39px;
  background: #fff;
  border: 1px solid ${(props: any) => getColorForScore(props.score)};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(props: any) => getColorForScore(props.score)};
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  z-index: 1000;
  cursor: pointer;
  position: relative;
  text-decoration: none;
` as any;

export const SegmentCircleOuterTopRight = styled(Link)`
  width: 32px;
  height: 32px;
  background: #fff;
  border: 4px solid ${(props: any) => getColorForScore(props.score)};

  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(props: any) => getColorForScore(props.score)};
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  z-index: 99;
  position: relative;
  cursor: pointer;
  text-decoration: none;
` as any;

export const SegmentCircleOuterBottomRight = styled(Link)`
  width: 24px;
  height: 24px;
  background: #fff;
  border: 8px solid ${(props: any) => getColorForScore(props.score)};

  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${(props: any) => getColorForScore(props.score)};
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  z-index: 99;
  cursor: pointer;
  position: relative;
  text-decoration: none;
` as any;

export const SegmentCircleOuterBottomLeft = styled(Link)`
  width: 40px;
  height: 40px;
  background: ${(props: any) => getColorForScore(props.score)};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  z-index: 99;
  cursor: pointer;
  position: relative;
  text-decoration: none;
` as any;

const HeadlineCount = styled(HeadlineTwo as any)`
  color: #509bb0;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 12px;
  margin-top: 8px;
`;

const HeadlineCountDirection = styled(HeadlineCount as any)`
  display: inline-block;
  margin: 0px 2px;
  color: #545454;
  opacity: 0.3;
`;

const HeadlineCountSub = styled(HeadlineTwo as any)`
  display: inline-block;
  font-size: 13px;
  margin: 8px 8px;
  color: #545454;
`;

const HeadlineCountSubBlock = styled(HeadlineTwo as any)`
  display: block;
  font-size: 13px;
  margin: 4px 8px;
  color: #545454;
  line-height: 15px;

  span {
    margin-right: 8px;
  }
`;

const Seperator = styled.hr`
  border-top: 1px solid #545454;
  border-bottom: none;
  margin: 16px 0;
  opacity: 0.2;
`;

export const HeadlineMigrationPercent = styled(HeadlineTwo as any)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 8px 8px;
  color: #545454;

  img {
    margin-right: 4px;
  }
`;

const CategorySubHeadline = styled(HeadlineTwo as any)`
  color: #545454;
  font-size: 13px;
  display: block;
  text-align: center;
  width: 100%;
`;

const RevenueHeadline = styled.h2`
  color: #509bb0;
  font-size: 18px;
  margin: 2px 0;
  line-height: 21px;
  color: #425663;
  font-family: proxima-nova, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
  margin-bottom: 0.5rem;
  margin-top: 0;
`;

const MarketingHeadline = styled(RevenueHeadline as any)`
  font-size: 18px;
  margin: 2px 0;
  line-height: 21px;
`;

const CategorySpan = styled.span`
  color: #545454;
  font-size: 12px;
  opacity: 0.3;
  display: inline-block;
  width: auto;
`;

const MigrationOvalRightContainer = styled.div`
  display: none;
  ${breakpoint('md')`
    display: block;
  `};
`;

const MigrationOvalRightContainerMobile = styled.div`
  display: inline-block;
  ${breakpoint('md')`
    display: none;
  `};
`;

export const Segments = styled.div`
  position: relative;
`;

export const HealthBar = styled.div`
  width: calc(100% - 0.2rem);
  margin-left: -0.9rem;
  height: 10px;
  background: ${(props: any) => getColorForScore(props.score)};
  position: absolute;
  top: 30px;
  z-index: 1;
` as any;

export const Insights = styled.div`
  margin-bottom: 8px;

  .optimization {
    margin-bottom: 8px;

    div {
      display: flex;
      width: 100%;
    }
  }

  .text {
    display: inline-flex;
    width: calc(100% - 26px);
    font-size: 14px;
    color: #333333;
  }

  .label {
    display: inline-flex;
    width: 18px;
    height: 18px;
    background: #b2d4b1;

    border-radius: 9999%;
    color: #fff;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    color: #333333;

    i {
      font-size: 10px;
      color: #fff;
    }
  }

  .label-warn {
    background: #b18e94;
  }
`;

export const ScoreCircleContainer = styled.div`
  margin-right: 12px;
`;

export const ScoreCircle = styled.div`
  width: 55px;
  height: 55px;
  background: ${(props: any) => props.backgroundColor};
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
  }
` as any;

export const ScoreCircleText = styled.div`
  color: rgb(155, 155, 155);
  width: 100%;
  font-size: 12px;
  margin-top: 4px;
  text-align: center;
` as any;

interface SegmentPanelProps {
  segment: any;
  index: number;
  id: any;
  brand: any;
  term: string;
  dataTerm: string;
  dataConfig: any;
}

const formatterScore = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
});

function getCircle(index, data, brand) {
  switch (index) {
    case 0: {
      return (
        <SegmentCircleOuterTopLeft
          to={`/brands/${brand}/segment/prospect`}
          score={data.scores.total}
          onClick={(e) => {}}
        >
          P
        </SegmentCircleOuterTopLeft>
      );
    }

    case 1: {
      return (
        <SegmentCircleOuterTopRight
          to={`/brands/${brand}/segment/casual`}
          score={data.scores.total}
          onClick={(e) => {}}
        >
          CA
        </SegmentCircleOuterTopRight>
      );
    }

    case 2: {
      return (
        <SegmentCircleOuterBottomRight
          to={`/brands/${brand}/segment/loyalist`}
          score={data.scores.total}
          onClick={(e) => {}}
        >
          L
        </SegmentCircleOuterBottomRight>
      );
    }

    case 3: {
      return (
        <SegmentCircleOuterBottomLeft
          to={`/brands/${brand}/segment/cheerleader`}
          score={data.scores.total}
          onClick={(e) => {}}
        >
          CH
        </SegmentCircleOuterBottomLeft>
      );
    }

    default: {
      return null;
    }
  }
}

export function getBorderStyle(index) {
  switch (index) {
    case 0: {
      return {
        borderRight: '1px solid #d9ebf4',
        borderBottom: '1px solid #d9ebf4',
      };
    }

    case 1: {
      return {
        borderBottom: '1px solid #d9ebf4',
      };
    }

    case 2: {
      return {
        borderRight: '1px solid #d9ebf4',
      };
    }
  }
}

export function getBorderRight(index) {
  switch (index) {
    case 0: {
      return '2px solid #d9ebf4';
    }

    case 1: {
      return '2px solid #d9ebf4';
    }

    case 2: {
      return '2px solid #d9ebf4';
    }
  }

  return 'none';
}

export function getBorderBottom(index) {
  return 'none';
}

export const SegmentGridUnit = styled(Grid.Unit)`
  ${breakpoint('md')`
    border-right: ${(props) => getBorderRight(props.index)}
    border-bottom: ${(props) => getBorderBottom(props.index)}

  `}// position: relative;
`;

export function SegmentPanel(props: any) {
  const [loading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (loading) {
    return (
      <SegmentGridUnit index={props.index} size={{ sm: 1, md: 1 / 4 }}>
        <Padding
          all={{ xs: 3, sm: 4, md: 4 }}
          top={{ xs: 3, sm: 3, md: 3 }}
          bottom={{ xs: 3, sm: 3, md: 3 }}
          style={{ position: 'relative', zIndex: 999 }}
        >
          <div style={{ minHeight: '300px' }}>
            <Loader />
          </div>
        </Padding>
      </SegmentGridUnit>
    );
  }

  const data = quantData[`SEGMENT_${props.id.toUpperCase()}`];

  const migratedIn = [
    'casuals',
    'prospects',
    'lostsouls',
    'cheerleaders',
    'loyalists',
  ].reduce((total, key) => {
    if (key !== id) {
      return total + data.migration.from[key];
    }

    return total;
  }, 0);

  const migratedOut = [
    'casuals',
    'prospects',
    'lostsouls',
    'cheerleaders',
    'loyalists',
  ].reduce((total, key) => {
    if (key !== id && key !== 'lostsouls') {
      return total + data.migration.to[key];
    }

    return total;
  }, 0);

  const lostSouls = [
    'casuals',
    'prospects',
    'lostsouls',
    'cheerleaders',
    'loyalists',
  ].reduce((total, key) => {
    if (key === 'lostsouls') {
      return total + data.migration.to[key];
    }

    return total;
  }, 0);

  const percentChange = data.current.users / data.previous.users - 1;

  const totalChange = data.current.users - data.previous.users;

  const total = data.previous.users; // + migratedOut + lostSouls;

  const segment = props.segment || { contents: { attributes: '' } };

  const id = `${props.id}s`;

  return (
    <SegmentGridUnit index={props.index} size={{ sm: 1, md: 1 / 4 }}>
      <Padding
        all={{ xs: 3, sm: 4, md: 3 }}
        top={{ xs: 3, sm: 3, md: 3 }}
        bottom={{ xs: 1, sm: 1, md: 1 }}
        style={{
          position: 'relative',
          zIndex: 999,
          display: 'flex',
          flex: '0 0 100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          height: '100%',
        }}
      >
        <div
          style={{
            minHeight: '300px',
            width: '100%',
            flex: '0 0 100%',
            flexDirection: 'column',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            {getCircle(props.index, data, props.brand)}
            <HealthBar score={data.scores.total} />
          </div>
          <MigrationOvalRightContainer
            style={{
              position: 'absolute',
              right: -36.5,
              top: 168,
            }}
          >
            {data.movement.migrationForward > 0 ? (
              <MigrationOvalRight
                percent={formatterPercent.format(
                  data.movement.migrationForward / total
                )}
              />
            ) : null}
          </MigrationOvalRightContainer>
          <SegmentTitle>{props.segment.name}</SegmentTitle>
          <SegmentText
            style={{
              textAlign: 'center',
              paddingRight: 12,
              paddingLeft: 12,
              marginBottom: 20,
            }}
          >
            <span
              style={{
                minHeight: `${props.largestHeight}px`,
                display: 'inline-block',
              }}
              className="segment-attribute"
            >
              {segment.contents.attributes}
            </span>
          </SegmentText>
          <HeadlineCountSubBlock style={{ textAlign: 'center' }}>
            SEGMENT CHANGE
          </HeadlineCountSubBlock>
          <HeadlineCount>
            <HeadlineCountDirection>
              {totalChange > 0 ? '+' : '-'}
            </HeadlineCountDirection>
            {formatterCounts.format(
              totalChange < 0 ? totalChange * -1 : totalChange
            )}
          </HeadlineCount>
          <div style={{ textAlign: 'center', minHeight: '60px' }}>
            <HeadlineCountSubBlock>
              <b>{formatterCounts.format(data.current.users)}</b> Segment Total
            </HeadlineCountSubBlock>
            <HeadlineCountSubBlock>
              {data.current.users > data.previous.users ? 'up' : 'down'}{' '}
              {formatterPercent.format(
                percentChange > 0 ? percentChange : percentChange * -1
              )}{' '}
              from last period
            </HeadlineCountSubBlock>
            <HeadlineCountSubBlock>
              {formatterPercent.format(data.current.usersAsPercentageOfTotal)}{' '}
              of total
            </HeadlineCountSubBlock>
          </div>
          <Seperator />

          <Grid>
            <GridUnit size={{ sm: 1 }}>
              <div style={{ width: '100%' }}>
                <CategorySubHeadline style={{ color: '#545454', fontSize: 12 }}>
                  2 YEAR REVENUE
                </CategorySubHeadline>

                <div style={{ flexGrow: 1, textAlign: 'center' }}>
                  <RevenueHeadline
                    style={{ fontSize: 28, color: '#5889A0', fontWeight: 400 }}
                  >
                    {formatter.format(data.current.revenue)}
                  </RevenueHeadline>
                </div>

                <div style={{ marginTop: 8 }}>
                  <HeadlineCountSubBlock
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <span>{data.growth.revenuePercentChangeFormatted}</span>
                    <span>{data.growth.revenueTotalChangeFormatted}</span>
                    {/* <span>from {data.previous.revenueFormatted}</span> */}
                    {/* {formatter.format(data.current.averageIndividualValue)} */}
                    {/* <CategorySpan
                      style={{
                        position: 'absolute',
                        left: 12,
                        fontSize: 12,
                        color: '#536976',
                      }}
                    >
                      Ind avg
                    </CategorySpan> */}
                  </HeadlineCountSubBlock>
                </div>
              </div>
            </GridUnit>
          </Grid>
          <Seperator />
          <Grid>
            <GridUnit size={{ sm: 1 }}>
              <div style={{ width: '100%' }}>
                <CategorySubHeadline style={{ color: '#545454', fontSize: 12 }}>
                  MARKETING SPEND
                </CategorySubHeadline>
                <div style={{ marginTop: 0 }}>
                  <MarketingHeadline
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      position: 'relative',
                      fontSize: 24,
                      color: '#8C8C8C',
                      fontWeight: 400,
                    }}
                  >
                    {formatter.format(
                      data.current.costPerUser * data.current.users
                    )}
                    {/* <CategorySpan style={{ position: 'absolute', left: 12 }}>
                      In period
                    </CategorySpan> */}
                  </MarketingHeadline>
                </div>
                <div style={{ marginTop: 8 }}>
                  {/* <MarketingHeadline
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      position: 'relative',
                      color: '#545454',
                      fontSize: 16,
                    }}
                  > */}
                  {/* {formatter.format(data.current.costPerUser)} */}
                  {/* <CategorySpan style={{ position: 'absolute', left: 12 }}>
                      Ind Avg
                    </CategorySpan> */}
                  {/* </MarketingHeadline> */}
                </div>

                <MarketingHeadline></MarketingHeadline>
              </div>
            </GridUnit>
          </Grid>
          <Seperator />
          <div style={{ textAlign: 'center', width: '100%' }}>
            {/* <HeadlineMigrationPercent className="test">
              <img style={{ width: 20, height: 20 }} src="/static/stay.png" />
              {formatterPercent.format(
                data.brand.segmentMigrationData.stayed[id] / total
              )}
            </HeadlineMigrationPercent> */}

            {/* {migration && migration !== 0 ? (
              <HeadlineMigrationPercent>
                <img
                  style={{ width: 20, height: 20 }}
                  src="/static/migrate.png"
                />
                {formatterPercent.format(migration / total)}
              </HeadlineMigrationPercent>
            ) : null} */}

            {/* <HeadlineMigrationPercent>
              <img style={{ width: 20, height: 20 }} src="/static/lost.png" />
              {formatterPercent.format(lostSouls / total)}
            </HeadlineMigrationPercent> */}

            {data.movement.migrationBackward > 0 ? (
              <MigrationOvalLeft
                percent={formatterPercent.format(
                  data.movement.migrationBackward / total
                )}
              />
            ) : null}
            <MigrationOvalRetain
              percent={formatterPercent.format(
                data.migration.stayed[id] / total
              )}
            />
            <MigrationOvalLost
              percent={formatterPercent.format(lostSouls / total)}
            />
            <MigrationOvalRightContainerMobile>
              <MigrationOvalRight
                percent={formatterPercent.format(
                  data.movement.migrationForward / total
                )}
              />
            </MigrationOvalRightContainerMobile>
          </div>
        </div>
        <div
          style={{
            paddingBottom: '1rem',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <AlignCenter>
            <Padding all={2}>
              <Link
                style={{ position: 'relative', zIndex: 999 }}
                to={`/brands/${props.brand}/segment/${props.id}`}
              >
                <ButtonHollow>SEGMENT DETAILS</ButtonHollow>
              </Link>
            </Padding>
          </AlignCenter>
          <Seperator />
        </div>
      </Padding>
    </SegmentGridUnit>
  );
}

export function SegmentInsightPanel(props: any) {
  const [loading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (loading || !quantData) {
    return null;
  }

  const id = props.id === 'prospect' ? 'prospects' : props.id;

  const data = quantData[`SEGMENT_${id.toUpperCase()}`];

  if (!data || !data.scores || !data.scores.insights) {
    return (
      <SegmentGridUnit
        index={props.index}
        size={{ sm: 1, md: 1 / 4 }}
      ></SegmentGridUnit>
    );
  }

  return (
    <SegmentGridUnit index={props.index} size={{ sm: 1, md: 1 / 4 }}>
      <Padding
        all={{ xs: 3, sm: 4, md: 3 }}
        top={{ xs: 1, sm: 1, md: 1 }}
        bottom={{ xs: 3, sm: 3, md: 3 }}
      >
        <div style={{ display: 'flex' }}>
          <div>
            <ScoreCircleContainer>
              <ScoreCircle
                backgroundColor={getColorForScore(data.scores.total)}
              >
                <span>{formatterScore.format(data.scores.total)}</span>
              </ScoreCircle>
              {/* <ScoreCircleText>out of 5</ScoreCircleText> */}
            </ScoreCircleContainer>
          </div>
          <div>
            <Insights>
              {data.scores.insights.map((optimization, i) => {
                return (
                  <div key={i} className="optimization">
                    <div className="">
                      <span
                        className={`label ${
                          optimization.negative ? 'label-warn' : ''
                        }`}
                      >
                        <i
                          className={`fa ${
                            optimization.negative
                              ? 'fa-exclamation'
                              : 'fa-check'
                          }`}
                        ></i>
                      </span>
                      <span className="text">{optimization.text}</span>
                    </div>
                  </div>
                );
              })}
            </Insights>
          </div>
        </div>
      </Padding>
    </SegmentGridUnit>
  );
}

export function ProspectPanel(props) {
  const [loading] = useGlobal('quantDataLoading');
  const [quantData] = useGlobal('quantData');

  if (loading) {
    return (
      <SegmentGridUnit index={props.index} size={{ sm: 1, md: 1 / 4 }}>
        <Padding
          all={{ xs: 3, sm: 4, md: 5 }}
          top={{ xs: 3, sm: 3, md: 3 }}
          bottom={{ xs: 3, sm: 3, md: 3 }}
          style={{ position: 'relative', zIndex: 999 }}
        >
          <div style={{ minHeight: '300px' }}>
            <Loader />
          </div>
        </Padding>
      </SegmentGridUnit>
    );
  }

  const data: ISegmentData = quantData[SEGMENTS.PROSPECTS];

  const migration = data.migration.from['prospects'];

  return (
    <SegmentGridUnit index={0} size={{ sm: 1, md: 1 / 4 }}>
      <Padding
        all={{ xs: 3, sm: 4, md: 3 }}
        top={{ xs: 3, sm: 3, md: 3 }}
        bottom={{ xs: 3, sm: 3, md: 3 }}
        style={{
          position: 'relative',
          zIndex: 999,
          display: 'flex',
          flex: '0 0 100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          height: '100%',
        }}
      >
        <div
          style={{
            minHeight: '300px',
            width: '100%',
            flex: '0 0 100%',
            flexDirection: 'column',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            {getCircle(0, data, props.brand)}
            <HealthBar score={data.scores.total} />
          </div>
          <MigrationOvalRightContainer
            style={{
              position: 'absolute',
              right: -36.5,
              top: 168,
            }}
          >
            {data.movement.migrationForward > 0 && data.previous.users > 0 ? (
              <MigrationOvalRight
                percent={formatterPercent.format(
                  data.movement.migrationForward / data.previous.users
                )}
              />
            ) : null}
          </MigrationOvalRightContainer>
          <div
            style={{
              position: 'absolute',
              right: -36.5,
              top: 158,
            }}
          ></div>
          <SegmentTitle>{props.segment.name}</SegmentTitle>
          <SegmentText
            style={{
              textAlign: 'center',
              paddingRight: 12,
              paddingLeft: 12,
              marginBottom: 20,
            }}
          >
            <span
              style={{
                minHeight: `${props.largestHeight}px`,
                display: 'inline-block',
              }}
              className="segment-attribute"
            >
              {props.segment.contents.attributes}
            </span>
          </SegmentText>
          <HeadlineCountSubBlock style={{ textAlign: 'center' }}>
            SEGMENT TOTAL
          </HeadlineCountSubBlock>
          <HeadlineCount>
            {formatterCounts.format(data.current.users || 0)}
          </HeadlineCount>
        </div>
        <div
          style={{
            paddingBottom: '1rem',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <AlignCenter>
            <Padding all={2}>
              <Link
                style={{ position: 'relative', zIndex: 999 }}
                to={`/brands/${props.brand}/segment/${props.segment.id}`}
              >
                <ButtonHollow>SEGMENT DETAILS</ButtonHollow>
              </Link>
            </Padding>
          </AlignCenter>
          <Seperator />
        </div>
      </Padding>
    </SegmentGridUnit>
  );
}

export function SegmentPanelBlank({ segment, id, index, largestHeight }) {
  return (
    <SegmentGridUnit index={index} size={{ sm: 1, md: 1 / 4 }}>
      <Padding
        all={{ xs: 3, sm: 4, md: 3 }}
        top={{ xs: 3, sm: 3, md: 3 }}
        bottom={{ xs: 3, sm: 3, md: 3 }}
        style={{
          position: 'relative',
          zIndex: 999,
          display: 'flex',
          flex: '0 0 100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          height: '100%',
        }}
      >
        <div
          style={{
            minHeight: '300px',
            width: '100%',
            flex: '0 0 100%',
            flexDirection: 'column',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            {getCircle(index, { scores: { total: 5 } }, id)}
            <HealthBar />
          </div>
          <div
            style={{
              position: 'absolute',
              right: -36.5,
              top: 158,
            }}
          ></div>
          <SegmentTitle>{segment.name}</SegmentTitle>
          <SegmentText
            style={{
              textAlign: 'center',
              paddingRight: 12,
              paddingLeft: 12,
              marginBottom: 20,
            }}
          >
            <span
              style={{
                minHeight: `${largestHeight}px`,
                display: 'inline-block',
              }}
              className="segment-attribute"
            >
              {segment.contents.attributes}
            </span>
          </SegmentText>
          <HeadlineCountSubBlock style={{ textAlign: 'center' }}>
            SEGMENT CHANGE
          </HeadlineCountSubBlock>
          {/* <HeadlineCount>
            {formatterCounts.format(data.brand.segmentData.metricData.users)}
          </HeadlineCount> */}
          <HeadlineCount>{formatterCounts.format(0)}</HeadlineCount>
          <div style={{ textAlign: 'center', minHeight: '60px' }}>
            <HeadlineCountSubBlock>
              <b>{formatterCounts.format(0)}</b> Segment Total
            </HeadlineCountSubBlock>
            <HeadlineCountSubBlock>
              {formatterPercent.format(0)} from last period
            </HeadlineCountSubBlock>
            <HeadlineCountSubBlock>
              {formatterPercent.format(0)} of total
            </HeadlineCountSubBlock>
          </div>
          <Seperator />
        </div>
        <div
          style={{
            paddingBottom: '1rem',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          <AlignCenter>
            <Padding all={2}>
              <Link
                style={{ position: 'relative', zIndex: 999 }}
                to={`/brands/${id}/segment/${segment.id}`}
              >
                <ButtonHollow>SEGMENT DETAILS</ButtonHollow>
              </Link>
            </Padding>
          </AlignCenter>
          <Seperator />
        </div>
      </Padding>
    </SegmentGridUnit>
  );
}
