import * as React from 'react';
import { useGlobal } from 'reactn';
import { makePeriodsCompare } from '../../../metrics/dates';
import { PeriodText } from '../segment';

export function CurrentPeriod() {
  const [globalBaseDate, setGlobalBaseDate] = useGlobal(`term_one_base`);
  const [globalCompareBaseDate, setGlobalCompareDate] =
    useGlobal(`term_one_compare`);

  const { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd } =
    makePeriodsCompare('2-year', globalBaseDate, globalCompareBaseDate);

  return (
    <div
      style={{
        padding: 16,
        display: 'block',

        textAlign: 'right',
      }}
    >
      <PeriodText style={{ margin: '0 4px 0 4px' }}>
        current period({periodOneStart} - {periodOneEnd})
      </PeriodText>
      <PeriodText style={{ margin: '0 4px 0 4px' }}>
        previous period({periodTwoStart} - {periodTwoEnd})
      </PeriodText>
    </div>
  );
}
