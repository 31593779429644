import {
  getPositionOnRadius,
  getOuterNodesTotal,
  getPositionOnRectangle,
  getParentNodeAngledSpaceAvailable,
  toCurve
} from './util';

export function getBranchOneQuadrant(parent) {
  const { angle } = parent.position;

  if (angle > -90 && angle < 0) {
    return 'UPPER_RIGHT';
  }

  if (angle > 0 && angle < 90) {
    return 'BOTTOM_RIGHT';
  }

  if (angle > 90 && angle < 180) {
    return 'BOTTOM_LEFT';
  }
}

export const adjusterBranchOne = [
  {
    test(node, angle) {
      return angle > 50 && angle < 75;

      // test angles if pass apply condition
    },
    adjust(node) {
      node.position.y = node.position.y + node.index * 10;
      // this runs if test passes
    }
  }
];

export function adjuster(node, angle, rules) {
  for (let r of rules) {
    if (r.test(node, angle)) {
      r.adjust(node);
    }
  }
}

export function plotBranchOne(node) {
  const { parent, ancestor, index } = node;
  let { x, y, angle } = parent.position;

  if (angle > 75) {
    // angle = angle + 10;
  }

  angle = angle - (parent.children.length * 4) / 2;
  const outerNodesTotal = getOuterNodesTotal(parent.parent);
  const orbitRadius = 220 + index * 10;
  const boost = parent.nodePercentSpace * 200;
  const radius = 120 + boost + outerNodesTotal * 3;
  const nodeAngle = angle + index * 4;

  /*parent.parent.diag = [
    { x: parent.parent.position.x, y: parent.parent.position.y, radius }
  ];*/

  const quad = getBranchOneQuadrant(parent);

  const orbit = getPositionOnRadius(
    nodeAngle,
    radius,
    parent.parent.position.x,
    parent.parent.position.y
  );

  node.position = {
    ...orbit
  };

  if (angle > 75) {
    node.position.y = node.position.y - index * 12;
    // node.position.x = node.position.x + index * 3;
  }

  adjuster(node, angle, adjusterBranchOne);

  node.draw = g => {
    // draw this node...
  };

  node.drawText = g => {
    // draw this nodes text
  };

  node.drawTo = (n1, n2) => {
    const dy = n1.position.y - n2.position.y;

    let incx = -30;
    let incy = dy / 2;

    if (angle > 150 && angle < 200) {
      incx = 30;
      incy = 30;
    }

    if (angle > 200) {
      incx = 30;
      incy = dy / 2;
    }

    const ax = n1.position.x + 0;
    const ay = n1.position.y;
    const bx = n2.position.x + incx;
    const by = n2.position.y + incy;

    return `M${n1.position.x},${n1.position.y}C${ax},${ay} ${bx},${by} ${
      n2.position.x
    },${n2.position.y}`;
  };

  if (nodeAngle > 80) {
    node.textPosition = {
      x: node.position.x - 10 - (node.name ? node.name.length : 9) * 5,
      y: node.position.y + 4,
      size: 9
    };
  } else {
    node.textPosition = {
      x: node.position.x + 10,
      y: node.position.y + 4,
      size: 9
    };
  }

  return node;
}

export function plotBranchTwo(node) {
  const { parent, ancestor, index } = node;
  let { x, y, angle } = parent.position;
  const outerNodesTotal = getOuterNodesTotal(parent.parent);
  const boost = parent.nodePercentSpace * 200;
  const radius = 120 + boost + outerNodesTotal * 3;
  const space = parent.nodePercentSpace * 180;
  const inc = space / parent.children.length;

  // angle = angle + 20;
  // angle = angle - (parent.children.length * 6) / 2;
  // angle = angle - space / 2;

  if (parent.children.length === 1) {
    angle = parent.position.angle;
  }

  const nodeAngle = angle + index * 4;

  const orbit = getPositionOnRadius(
    nodeAngle,
    radius,
    parent.parent.position.x,
    parent.parent.position.y
  );

  node.position = {
    ...orbit
  };

  /*node.diag = [
    {
      x: parent.parent.position.x,
      y: parent.parent.position.y,
      radius
    }
  ];*/

  if (angle > 215 && angle < 290) {
    // top of circle
    node.position.y = node.position.y - index * 12;
    node.position.x = node.position.x + index * 3;
  }

  if (angle > 100 && angle < 120) {
    node.position.y = node.position.y + index * 20;
    node.position.x = node.position.x - index * 6;
    // node.position.x = node.position.x - 20;
  }

  if (angle > 130 && angle < 160) {
    // bring in since they furthest on the radius
    node.position.x = node.position.x + 20;
  }

  // node.name = angle.toString();

  if (angle > 65 && angle < 99) {
    node.position.y = node.position.y + index * 16;
  }

  if (angle > 90 && angle < 250) {
    node.textPosition = {
      x: node.position.x - 10 - (node.name ? node.name.length : 9) * 5.1,
      y: node.position.y + 3,
      size: 9
    };
  } else {
    node.textPosition = {
      x: node.position.x + 10,
      y: node.position.y + 3,
      size: 9
    };
  }

  node.drawTo = (n1, n2) => {
    const dy = n1.position.y - n2.position.y;

    let incx = -30;
    let incy = dy / 2;

    if (angle > 150 && angle < 200) {
      incx = 30;
      incy = 30;
    }

    if (angle > 200) {
      incx = 30;
      incy = dy / 2;
    }

    const ax = n1.position.x + 0;
    const ay = n1.position.y;
    const bx = n2.position.x + incx;
    const by = n2.position.y + incy;

    return `M${n1.position.x},${n1.position.y}C${ax},${ay} ${bx},${by} ${
      n2.position.x
    },${n2.position.y}`;
  };

  return node;
}

export function plotBranchThree(node) {
  const { parent, ancestor, index } = node;
  let { x, y, angle } = parent.position;

  const boost = parent.children.length * 10;
  const radius = 180 + boost;

  /*parent.parent.diag = [
    {
      x: parent.parent.position.x,
      y: parent.parent.position.y,
      radius
    }
  ];*/

  const quad = getBranchOneQuadrant(parent);
  const available = getParentNodeAngledSpaceAvailable(node, 180);
  const inc = available / parent.children.length;

  const orbit = getPositionOnRadius(
    angle + index * 5,
    radius,
    parent.parent.position.x,
    parent.parent.position.y
  );

  node.position = {
    ...orbit
  };

  if (angle > 225 && angle < 270) {
    // top of circle
    node.position.y = node.position.y + index * 20;
    node.position.x = node.position.x + index * 4;
  }

  if (angle > 269 && angle < 310) {
    // top of circle
    node.position.y = node.position.y + index * 8;
  }

  if (angle > 75 && angle < 115) {
    // node.position.y = node.position.y + index * 20;
  }

  // node.name = `${available.toString()}/${inc * index}/${4 * index}`;

  if (angle < 270) {
    node.textPosition = {
      x: Math.round(
        node.position.x - 10 - (node.name ? node.name.length : 9) * 5.2
      ),
      y: Math.round(node.position.y + 3),
      size: 9
    };
  } else {
    node.textPosition = {
      x: node.position.x + 10,
      y: node.position.y + 3,
      size: 9
    };
  }

  node.drawTo = drawToBranchThree(node, angle);

  // node.name = angle.toString();

  return node;
}

export function drawToBranchThree(node, angle) {
  return (n1, n2) => {
    const { x: x1, y: y1 } = n1.position;
    const { x: x2, y: y2 } = n2.position;

    const dy = y1 - y2;

    let incx = 20;
    let incy = 100;

    if (angle > 250) {
      incy = 50;
      incx = 0;
    }

    if (angle > 300) {
      incy = -20;
      incx = -40;
    }

    const ax = x1 + 0;
    const ay = y1;
    const bx = x2 + incx;
    const by = y2 + incy;

    return toCurve(x1, y1, ax, ay, bx, by, x2, y2);
  };
}

export function plotLevelFour(node) {
  const { branch } = node;

  switch (branch) {
    case 0: {
      return plotBranchOne(node);
    }

    case 1: {
      return plotBranchTwo(node);
    }

    case 2: {
      return plotBranchThree(node);
    }
  }
}

export default plotLevelFour;
