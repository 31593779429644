import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { GraphQLClient } from 'graphql-request';
import * as store from 'store';
import moment from 'moment';

import { makePeriodsCompare } from './dates';
import {
  scoreProspectSegment,
  scoreCasualSegment,
  scoreLoyalistSegment,
  scoreCheerleaderSegment,
} from './scoring/scoring.beta.two';
import { LOAD_BRAND_DATA, LOAD_BRAND_PROSPECT_DATA_V2 } from '../utils/gql';
import { useDataCalculation } from '../utils/useDataCalculation';

const API_URL = process.env.REACT_APP_API
  ? `${process.env.REACT_APP_API}/gql`
  : 'http://localhost:3000/gql';

const client = new GraphQLClient(API_URL);

// Constants.

const SEGMENTS = [
  'prospects',
  'casuals',
  'loyalists',
  'cheerleaders',
  'lostsouls',
];

const SEGMENT_NAME_MAP = {
  prospects: 'SEGMENT_PROSPECTS',
  casuals: 'SEGMENT_CASUAL',
  loyalists: 'SEGMENT_LOYALIST',
  cheerleaders: 'SEGMENT_CHEERLEADER',
  lostsouls: 'SEGMENT_LOSTSOULS',
}

const SEGMENT_ABBREVIATION_MAP = {
  prospects: 'P',
  casuals: 'C',
  loyalists: 'L',
  cheerleaders: 'CH',
  lostsouls: 'X',
}

const MIGRATION_FORWARD_MAP = {
  prospects: 'casuals',
  casuals: 'loyalists',
  loyalists: 'cheerleaders',
  cheerleaders: null,
  lostsouls: null,
}

const MIGRATION_BACKWARD_MAP = {
  prospects: null,
  casuals: null,
  loyalists: 'casuals',
  cheerleaders: 'loyalists',
  lostsouls: null,
}

const SCORING_FUNCTIONS = {
  prospects: scoreProspectSegment,
  casuals: scoreCasualSegment,
  loyalists: scoreLoyalistSegment,
  cheerleaders: scoreCheerleaderSegment,
}

// Formatter utilities.

const formatterPercent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatterCount = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const formatterScore = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

function formatPercentChange(value: number, change: number) {
  return change > 0
    ? `+${formatterPercent.format(value)}`
    : `${formatterPercent.format(value)}`;
}

function formatCountChange(value: number) {
  return value > 0
    ? `+${formatterCount.format(value)}`
    : `${formatterCount.format(value)}`;
}

// Response utilities.

function makeQuantDataWithScores(brand: any, segmentMap: any) {
  return Object.entries(segmentMap).reduce((map, entry) => {
    const [key, value]: [string, any] = entry;
    let scores = {
      grades: [],
      insights: [],
      optimizations: [],
      total: 0,
      totalFormatted: 0,
    }
    if (SCORING_FUNCTIONS[value.segment.id]) {
      scores = SCORING_FUNCTIONS[value.segment.id](value, segmentMap, brand);
    }
    return {
      ...map,
      [key]: {
        ...value,
        scores,
      }
    }
  }, {})
}

function makeMigrationDataFromSegment(segment: string, period: any) {
  return {
    to: {
      casuals: period.migrations.outbound.casuals.customers,
      loyalists: period.migrations.outbound.loyalists.customers,
      cheerleaders: period.migrations.outbound.cheerleaders.customers,
      prospects: period.migrations.outbound.prospects.customers,
      lostsouls: period.migrations.outbound.lostsouls.customers,
    },
    from: {
      casuals: period.migrations.inbound.casuals.customers,
      loyalists: period.migrations.inbound.loyalists.customers,
      cheerleaders: period.migrations.inbound.cheerleaders.customers,
      prospects: period.migrations.inbound.prospects.customers,
      lostsouls: period.migrations.inbound.lostsouls.customers,
    },
    stayed: {
      casuals: segment === 'casuals' ? period.migrations.stayed.customers : 0,
      loyalists: segment === 'loyalists' ? period.migrations.stayed.customers : 0,
      cheerleaders: segment === 'cheerleaders' ? period.migrations.stayed.customers : 0,
      prospects: segment === 'prospects' ? period.migrations.stayed.customers : 0,
      lostsouls: segment === 'lostsouls' ? period.migrations.stayed.customers : 0,
    }
  }
}

function makePeriodDataFromSegment(brand: any, segment: string, period: any) {
  const averageIndividualValue = period.totalRevenue / period.totalCustomers;
  const cogsPerUser = brand.dataConfig[segment] && brand.dataConfig[segment].marginPer
    ? averageIndividualValue * (1 - brand.dataConfig[segment].marginPer)
    : 0;
  const costPerUser = brand.dataConfig[segment] && brand.dataConfig[segment].costPer
    ? brand.dataConfig[segment].costPer
    : 0;
  const marketingSpend = costPerUser
    ? costPerUser * period.totalCustomers
    : 0;
  const marginPerUser = brand.dataConfig[segment] && brand.dataConfig[segment].marginPer
    ? brand.dataConfig[segment].marginPer
    : 0;
  const netRevenuePerUser = averageIndividualValue - cogsPerUser - costPerUser;
  const netRevenue = netRevenuePerUser * period.totalCustomers;

  return {
    count: period.totalCustomers,
    countFormatted: formatterCount.format(period.totalCustomers),
    countPercentOfTotal: period.totalCustomersPercent,
    countPercentOfTotalFormatted: formatterPercent.format(period.totalCustomersPercent),
    users: period.totalCustomers,
    usersFormatted: formatterCount.format(period.totalCustomers),
    usersAsPercentageOfTotal: period.totalCustomersPercent,
    usersAsPercentageOfTotalFormatted: formatterPercent.format(period.totalCustomersPercent),
    averageIndividualValue,
    averageIndividualValueFormatted: formatterCurrency.format(averageIndividualValue),
    cogsPerUser,
    cogsPerUserFormatted: formatterCurrency.format(cogsPerUser),
    costPerUser,
    costPerUserFormatted: formatterCurrency.format(costPerUser),
    marketingSpend,
    marketingSpendFormatted: formatterCurrency.format(marketingSpend),
    marginPerUser,
    marginPerUserFormatted: formatterPercent.format(marginPerUser),
    netRevenuePerUser,
    netRevenuePerUserFormatted: formatterCurrency.format(netRevenuePerUser),
    netRevenue,
    netRevenueFormatted: formatterCurrency.format(netRevenue),
    revenue: period.totalRevenue,
    revenueFormatted: formatterCurrency.format(period.totalRevenue),
    revenueAsPercentageOfTotal: period.totalRevenuePercent,
    revenueAsPercentageOfTotalFormatted: formatterPercent.format(period.totalRevenuePercent),
    averageOrderValue: period.avgOrderValue,
    averageOrderValueFormatted: formatterCurrency.format(period.avgOrderValue),
    averageOrderCount: period.avgOrderCount,
    averageOrderTimeDiff: period.avgOrderTimeDiff,
    // topProducts: period.topProducts,
  }
}

function makeQuantDataFromSegment(brand: any, segment: string, data: any) {
  const currentPeriod = data.currentPeriod.segments[segment];
  const previousPeriod = data.lastPeriod.segments[segment];

  const currentPeriodData = makePeriodDataFromSegment(brand, segment, currentPeriod);
  const previousPeriodData = makePeriodDataFromSegment(brand, segment, previousPeriod);

  const currentMigrationData = makeMigrationDataFromSegment(segment, currentPeriod);

  const migrationId = MIGRATION_FORWARD_MAP[segment];
  const regressionId = MIGRATION_BACKWARD_MAP[segment];
  const migrationForward = migrationId
    ? currentMigrationData.to[migrationId]
    : null;
  const migrationBackward = regressionId
    ? currentMigrationData.to[regressionId]
    : null;
  const migrationIn = Object.values(currentMigrationData.from).reduce((sum, value) => sum + value, 0);
  const migrationOut = Object.values(currentMigrationData.to).reduce((sum, value) => sum + value, 0);

  const usersPercentChange = (currentPeriod.totalCustomers / previousPeriod.totalCustomers) - 1;
  const usersTotalChange = currentPeriod.totalCustomers - previousPeriod.totalCustomers;
  const revenuePercentChange = (currentPeriod.totalRevenue / previousPeriod.totalRevenue) - 1;
  const revenueTotalChange = currentPeriod.totalRevenue - previousPeriod.totalRevenue;
  const averageIndividualValuePercentChange = (currentPeriodData.averageIndividualValue / previousPeriodData.averageIndividualValue) - 1;
  const averageIndividualValueTotalChange = currentPeriodData.averageIndividualValue - previousPeriodData.averageIndividualValue;
  const costPerUserPercentChange = (currentPeriodData.costPerUser / previousPeriodData.costPerUser) - 1;
  const costPerUserTotalChange = currentPeriodData.costPerUser - previousPeriodData.costPerUser;
  const cogsPerUserPercentChange = (currentPeriodData.cogsPerUser / previousPeriodData.cogsPerUser) - 1;
  const cogsPerUserTotalChange = currentPeriodData.cogsPerUser - previousPeriodData.cogsPerUser;
  const netRevenuePerUserPercentChange = (currentPeriodData.netRevenuePerUser / previousPeriodData.netRevenuePerUser) - 1;
  const netRevenuePerUserTotalChange = currentPeriodData.netRevenuePerUser - previousPeriodData.netRevenuePerUser;
  const netRevenuePercentChange = (currentPeriodData.netRevenue / previousPeriodData.netRevenue) - 1;
  const netRevenueTotalChange = currentPeriodData.netRevenue - previousPeriodData.netRevenue;

  const userPercentChangeFormatted = formatPercentChange(usersPercentChange, usersTotalChange);
  const userTotalChangeFormatted = formatCountChange(usersTotalChange);
  const revenuePercentChangeFormatted = formatPercentChange(revenuePercentChange, revenueTotalChange);
  const revenueTotalChangeFormatted = formatCountChange(revenueTotalChange);
  const averageIndividualValuePercentChangeFormatted = formatPercentChange(averageIndividualValuePercentChange, averageIndividualValueTotalChange);
  const averageIndividualValueTotalChangeFormatted = formatCountChange(averageIndividualValueTotalChange);
  const costPerUserPercentChangeFormatted = formatPercentChange(costPerUserPercentChange, costPerUserTotalChange);
  const costPerUserTotalChangeFormatted = formatCountChange(costPerUserTotalChange);
  const cogsPerUserPercentChangeFormatted = formatPercentChange(cogsPerUserPercentChange, cogsPerUserTotalChange);
  const cogsPerUserTotalChangeFormatted = formatCountChange(cogsPerUserTotalChange);
  const netRevenuePerUserPercentChangeFormatted = formatPercentChange(netRevenuePerUserPercentChange, netRevenuePerUserTotalChange);
  const netRevenuePerUserTotalChangeFormatted = formatCountChange(netRevenuePerUserTotalChange);
  const netRevenuePercentChangeFormatted = formatPercentChange(netRevenuePercentChange, netRevenueTotalChange);
  const netRevenueTotalChangeFormatted = formatCountChange(netRevenueTotalChange);

  const totalCurrentMarketingSpend = Object.entries(data.currentPeriod.segments).reduce((sum, entry) => {
    const [key, value]: [string, any] = entry;
    const costPerUser = brand.dataConfig[key] && brand.dataConfig[key].costPer
      ? brand.dataConfig[key].costPer
      : 0;
    return sum + (value.totalCustomers * costPerUser);
  }, 0);
  const marketingSpendPercentOfTotal = (currentPeriod.totalCustomers * currentPeriodData.costPerUser) / totalCurrentMarketingSpend;

  return {
    name: SEGMENT_NAME_MAP[segment],
    abreviation: SEGMENT_ABBREVIATION_MAP[segment],
    segment: {
      id: segment
    },
    current: {
      ...currentPeriodData,
      marketingSpendPercentOfTotal,
      marketingSpendPercentOfTotalFormatted: formatterPercent.format(marketingSpendPercentOfTotal),
    },
    previous: previousPeriodData,
    growth: {
      usersPercentChange,
      userPercentChangeFormatted,
      usersTotalChange,
      userTotalChangeFormatted,
      revenuePercentChange,
      revenuePercentChangeFormatted,
      revenueTotalChange,
      revenueTotalChangeFormatted,
      averageIndividualValuePercentChange,
      averageIndividualValuePercentChangeFormatted,
      averageIndividualValueTotalChange,
      averageIndividualValueTotalChangeFormatted,
      costPerUserPercentChange,
      costPerUserPercentChangeFormatted,
      costPerUserTotalChange,
      costPerUserTotalChangeFormatted,
      cogsPerUserPercentChange,
      cogsPerUserPercentChangeFormatted,
      cogsPerUserTotalChange,
      cogsPerUserTotalChangeFormatted,
      netRevenuePerUserPercentChange,
      netRevenuePerUserPercentChangeFormatted,
      netRevenuePerUserTotalChange,
      netRevenuePerUserTotalChangeFormatted,
      netRevenuePercentChange,
      netRevenuePercentChangeFormatted,
      netRevenueTotalChange,
      netRevenueTotalChangeFormatted,
    },
    movement: {
      stayed: currentMigrationData.stayed[segment],
      migrationForward,
      migrationBackward,
      migrationForwardKey: migrationId,
      migrationBackwardKey: regressionId,
      migrationIn,
      migrationOut,
    },
    migration: currentMigrationData
  }
}

function makeQuantDataFromSegments(brand: any, data: any) {
  const { firstDataRecordDate, lastDataRecordDate } = brand;
  const segmentMap = SEGMENTS.reduce((map, segment) => {
    const key = SEGMENT_NAME_MAP[segment];
    return {
      ...map,
      [key]: makeQuantDataFromSegment(brand, segment, data)
    }
  }, {});
  const segmentMapWithScores = makeQuantDataWithScores(brand, segmentMap);
  const scoreGrades = Object.values(segmentMapWithScores).map((segment: any) => segment.scores.grades).flat();
  const scoreTotal = scoreGrades.reduce((sum, value) => sum + value.weighted, 0) / scoreGrades.length;

  return {
    firstDataRecordDate,
    lastDataRecordDate,
    scores: {
      total: scoreTotal,
      totalFormatted: formatterScore.format(scoreTotal)
    },
    ...segmentMapWithScores,
  }
}

export function useAdhocQuantDatav2(base: string, compare: string) {
  const [brand, setBrand]: [any, any] = useGlobal('brand');
  const [loadingData, setLoadingData] = useState(false);
  const [quantData, setQuantData] = useState(null);

  const [requestIds, setRequestIds] = useState([]);
  const [requestPayload, setRequestPayload] = useState(null)

  const {data} = useDataCalculation(requestPayload);

  useEffect(() => {
    if (brand && base && compare) {
      setLoadingData(true);
      const dates = makePeriodsCompare(
        brand.dataConfig.reportingTerm, 
        base, 
        compare
      )
      setRequestPayload({
        brandId: brand.id,
        periods: [
          {
            brandId: brand.id,
            period: 1,
            periodEnd: dates.periodOneEnd,
            periodStart: dates.periodOneStart,
          },
          {
            brandId: brand.id,
            period: 2,
            periodEnd: dates.periodTwoEnd,
            periodStart: dates.periodTwoStart,
          },
          {
            brandId: brand.id,
            period: 3,
            periodEnd: dates.periodTwoStart,
            periodStart: moment(dates.periodTwoStart, 'YYYY-MM-DD')
              .subtract(2, 'years')
              .format('YYYY-MM-DD')
          },
        ]
      })
    }
  }, [brand, base, compare]);

  useEffect(() => {
    if (data) {
      setQuantData(makeQuantDataFromSegments(brand, data));
      setLoadingData(false);
    }
  }, [data]);

  return { 
    data: quantData, 
    loading: loadingData 
  }
}

export function useLoadQuantDatav2(brandId: string) {
  const [brand, setBrand]: [any, any] = useGlobal('brand');
  const [base, setBase] = useGlobal('term_one_base'); // period one end
  const [compare, setCompare] = useGlobal('term_one_compare'); // period two end
  const [loadingBrand, setLoadingBrand] = useGlobal('loadingBrand');
  const [loadingData, setLoadingData] = useGlobal('quantDataLoading');
  const [quantData, setQuantData] = useGlobal('quantData');

  const [requestIds, setRequestIds] = useState([]);
  const [requestPayload, setRequestPayload] = useState(null)
  
  const {data: dataCalculation} = useDataCalculation(requestPayload);

  const getBrandData = async (id: string) => {
    return await client.request(
      LOAD_BRAND_DATA,
      { id },
      { authorization: store.get('token') }
    )
  }

  const getProspectData = async (id, periodEnd, periodStart) => {
    return await client.request(
      LOAD_BRAND_PROSPECT_DATA_V2,
      { id, periodEnd, periodStart },
      { authorization: store.get('token') }
    )
  }

  const handleDataCalculation = async (data: any) => {
    const prospectRes = await getProspectData(brand.id, base, compare);
    if (prospectRes.brand && prospectRes.brand.prospectData.enabled) {
      data.currentPeriod.segments.prospects.totalCustomers = prospectRes.brand.prospectData.currentPeriod;
      data.lastPeriod.segments.prospects.totalCustomers = prospectRes.brand.prospectData.previousPeriod;
    }
    setQuantData(makeQuantDataFromSegments(brand, data));
    setLoadingData(false);
  }

  useEffect(() => {
    if (!brand || brand.id !== brandId) {
      setBrand(null);
      setLoadingBrand(true);
      setLoadingData(true);
      getBrandData(brandId).then(data => {
        setBrand(data.brand);
        setLoadingBrand(false);
        if (!data.brand.firstDataRecordDate || !data.brand.lastDataRecordDate) {
          setLoadingData(false);
        } else {
          const initialBase = data.brand.lastDataRecordDate;
          const initialCompare = moment(data.brand.lastDataRecordDate, 'YYYY-MM-DD')
            .subtract(1, 'year')
            .format('YYYY-MM-DD');
          setBase(initialBase);
          setCompare(initialCompare);
        }
      })
    }
  }, []);

  useEffect(() => {
    if (brand && base && compare) {
      setLoadingData(true);
      const dates = makePeriodsCompare(
        brand.dataConfig.reportingTerm, 
        base, 
        compare
      );
      setRequestPayload({
        brandId: brand.id,
        periods: [
          {
            brandId: brand.id,
            period: 1,
            periodEnd: dates.periodOneEnd,
            periodStart: dates.periodOneStart,
          },
          {
            brandId: brand.id,
            period: 2,
            periodEnd: dates.periodTwoEnd,
            periodStart: dates.periodTwoStart,
          },
          {
            brandId: brand.id,
            period: 3,
            periodEnd: dates.periodTwoStart,
            periodStart: moment(dates.periodTwoStart, 'YYYY-MM-DD')
              .subtract(2, 'years')
              .format('YYYY-MM-DD')
          },
        ]
      })
    }
  }, [brand, base, compare]);

  useEffect(() => {
    if (dataCalculation) {
      handleDataCalculation(dataCalculation);
    }
  }, [dataCalculation]);

  return {
    data: quantData,
  };
}