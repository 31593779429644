import * as React from 'react';
import formatters from '../formatters';

export function optimizationsCountAsPercentage(
  benchmark: number,
  value: number,
  score: number
): string | any {
  if (value > benchmark) {
    return [
      {
        text: `Segment Count ${formatters.percent.format(
          value
        )}, ${formatters.count.format(
          (value - benchmark) * 100
        )} points above benchmark`,
        negative: false,
      },
    ];
  } else {
    return [
      {
        text: `Segment Count ${formatters.percent.format(
          value
        )}, ${formatters.count.format(
          (benchmark - value) * 100
        )} points below benchmark`,
        negative: true,
      },
    ];
  }
}

export function optimizationsRevenueAsPercentage(
  benchmark: number,
  value: number,
  score: number
): string | any {
  if (value > benchmark) {
    return [
      {
        text: `Segment Revenue ${formatters.percent.format(
          value
        )}, ${formatters.count.format(
          (value - benchmark) * 100
        )} points above benchmark`,
        negative: false,
      },
    ];
  } else {
    return [
      {
        text: `Segment Revenue ${formatters.percent.format(
          value
        )}, ${formatters.count.format(
          (benchmark - value) * 100
        )} points below benchmark`,
        negative: true,
      },
    ];
  }
}
